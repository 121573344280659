import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Stack,
    TextField
} from "@mui/material";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setName, setValue } from "../../../Store/EditableVariableSlice";
import { hasText, isExists } from "../../../utils";

const EnvironmentVariableDialog = ({ open, onSave, onClose }) => {
    const dispatch = useDispatch();

    const editableVariable = useSelector(state => state?.editableVariable ?? null);

    const handleChangeName = useCallback(
        ({ currentTarget }) => dispatch(setName(currentTarget?.value ?? "")),
        [dispatch]
    );

    const handleChangeValue = useCallback(
        ({ currentTarget }) => dispatch(setValue(currentTarget?.value ?? "")),
        [dispatch]
    );

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>{`${isExists(editableVariable?.id) ? "Редактирование" : "Создание"} ${
                editableVariable?.isSecret ? "секрета" : "переменной"
            }`}</DialogTitle>
            <DialogContent dividers>
                <DialogContentText pb={2}>
                    {editableVariable?.isSecret
                        ? "Секреты доступны только в контейнерах этого проекта и недоступны для просмотра после создания"
                        : "Переменные окружения доступны только в контейнерах этого проекта"}
                </DialogContentText>
                <Stack direction="column" spacing={2}>
                    <TextField
                        required
                        fullWidth
                        type="text"
                        label="Название"
                        value={editableVariable?.name ?? ""}
                        onChange={handleChangeName}
                    />
                    <TextField
                        required
                        fullWidth
                        type={editableVariable?.isSecret ? "password" : "text"}
                        label="Значение"
                        value={editableVariable?.value ?? ""}
                        onChange={handleChangeValue}
                    />
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" onClick={onClose}>
                    Отмена
                </Button>
                <Button
                    variant="contained"
                    disabled={!hasText(editableVariable?.name) || !hasText(editableVariable?.value)}
                    onClick={onSave}
                >
                    Сохранить
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default EnvironmentVariableDialog;
