import { TableBody, TableCell, TableRow, Skeleton } from "@mui/material";

function SkeletonTable() {
    return (
        <TableBody>
            {Array.from({ length: 5 }, () => (
                <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                    {Array.from({ length: 4 }, () => (
                        <TableCell>
                            <Skeleton variant="text" sx={{ fontSize: "24px" }} />
                        </TableCell>
                    ))}
                </TableRow>
            ))}
        </TableBody>
    );
}

export default SkeletonTable;
