import { Box, FormControl, FormHelperText, FormLabel, MenuItem, Select, Stack, TextField } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";

export function MySQLForm({ customDbForm, setCustomDbForm }) {
    const {
        control,
        register,
        formState: { errors }
    } = useFormContext();

    return (
        <Box width="100%">
            <Stack spacing={2}>
                <FormControl fullWidth>
                    <FormLabel>Версия MySQL:</FormLabel>
                    <Controller
                        name="version"
                        control={control}
                        render={({ field }) => (
                            <Select {...field}>
                                <MenuItem value="5.0">version 5.0</MenuItem>
                                <MenuItem value="8.0">version 8.0</MenuItem>
                            </Select>
                        )}
                    />
                </FormControl>
                <FormControl fullWidth>
                    <FormLabel>Root пароль MySQL:</FormLabel>
                    <TextField {...register("rootPassword", { required: "Укажите пароль" })} placeholder="*******" />
                    <FormHelperText error>{errors.rootPassword && errors.rootPassword.message}</FormHelperText>
                </FormControl>
                <FormControl fullWidth>
                    <FormLabel>Размер кластера СУБД:</FormLabel>
                    <Controller
                        name="instances"
                        control={control}
                        render={({ field }) => (
                            <TextField type="number" InputProps={{ inputProps: { min: 1, max: 3 } }} {...field} />
                        )}
                    />
                </FormControl>
            </Stack>
        </Box>
    );
}
