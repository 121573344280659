import {
    AppBar,
    Badge,
    Box,
    Button,
    Drawer,
    IconButton,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    ListSubheader,
    Popover,
    Stack,
    Toolbar,
    Typography,
    Link as ExternalLink
} from "@mui/material";
import { useCallback, useState } from "react";
import { Link, Outlet, useLocation } from "react-router-dom";
import AmveraLogo from "../../../Assets/amvera_logo.svg";
import { useConfig } from "../../../Configuration/hooks";
import { useServices } from "../../../DI/hooks";
import BalanceDisplay from "../../Elements/BalanceDisplay";
import Nothing from "../../Elements/Nothing";
import "./style.css";
import Footer from "../../Elements/Footer";
import LogSnackbar from "../../Elements/LogSnackbar";
import LogSnackbarHandler from "../../Elements/LogSnackbarHandler";

const PageTemplate = () => {
    const { auth } = useServices();
    const config = useConfig();
    const { pathname } = useLocation();

    const [userPopupAnchor, setUserPopupAnchor] = useState(null);
    const [notificationPopupAnchor, setNotificationPopupAnchor] = useState(null);
    const [mobileDrawerOpened, setMobileDrawerOpened] = useState(false);

    const handleUserClick = useCallback(event => {
        setUserPopupAnchor(event.currentTarget);
    }, []);

    const handleNotificationsClick = useCallback(event => {
        setNotificationPopupAnchor(event.currentTarget);
    }, []);

    const handleMobileMenuClick = useCallback(() => {
        setMobileDrawerOpened(true);
    }, []);

    const handleUserPopupClose = useCallback(() => {
        setUserPopupAnchor(null);
    }, []);

    const handleNotificationsPopupClose = useCallback(() => {
        setNotificationPopupAnchor(null);
    }, []);

    const handleMobileDrawerClose = useCallback(() => {
        setMobileDrawerOpened(false);
    }, []);

    const userPopupOpened = Boolean(userPopupAnchor);
    const userPopupId = userPopupOpened ? "amvera-user-popup" : undefined;

    const notificationsPopupOpened = Boolean(notificationPopupAnchor);
    const notificationsPopupId = notificationsPopupOpened ? "amvera-notifications-popup" : undefined;

    return (
        <div className="amvera-page-wrapper">
            <AppBar position="sticky" sx={{ zIndex: theme => theme.zIndex.drawer + 1 }}>
                <Toolbar>
                    <Typography
                        className="amvera-logo"
                        variant="h5"
                        component={Link}
                        to="/"
                        sx={{ display: "flex", alignItems: "center" }}
                    >
                        <img src={AmveraLogo} width={200} alt="logo" className="amvera-logo-img" />
                    </Typography>
                    <Box sx={{ flexGrow: 1 }} />
                    <Box sx={{ display: { xs: "none", md: "flex" } }}>
                        <Button
                            size="large"
                            startIcon={<i className="fa-solid fa-coins" />}
                            component={Link}
                            to="/billing/balance"
                            color="inherit"
                            sx={{ fontWeight: "bold" }}
                        >
                            <BalanceDisplay />
                            {/* <Typography variant="h6" sx={{ fontWeight: "bold" }}> */}
                            {/*    &beta; = &infin; */}
                            {/* </Typography> */}
                        </Button>
                        <IconButton size="medium" color="inherit" onClick={handleNotificationsClick}>
                            <Badge badgeContent={0} color="error">
                                <i className="fa-solid fa-bell" />
                            </Badge>
                        </IconButton>
                        <IconButton
                            size="medium"
                            color="inherit"
                            href="https://docs.amvera.ru/"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <i className="fa-solid fa-circle-question" />
                        </IconButton>
                        <Button
                            size="large"
                            startIcon={<i className="fa-solid fa-user" />}
                            // href={config.profileLink}
                            color="inherit"
                            onClick={handleUserClick}
                        >
                            {auth.user?.profile.preferred_username}{" "}
                        </Button>
                    </Box>
                    <Box sx={{ display: { xs: "flex", md: "none" } }}>
                        <IconButton size="medium" color="inherit" onClick={handleNotificationsClick}>
                            <Badge badgeContent={0} color="error">
                                <i className="fa-solid fa-bell" />
                            </Badge>
                        </IconButton>
                        <IconButton size="medium" color="inherit" onClick={handleMobileMenuClick}>
                            <i className="fa-solid fa-bars" />
                        </IconButton>
                    </Box>
                </Toolbar>
            </AppBar>
            <main className={pathname.includes("billing") ? "main--billing" : ""}>
                <Outlet />
            </main>
            <Footer>
                <Stack
                    direction={{ xs: "column", sm: "row" }}
                    spacing={4}
                    justifyContent="center"
                    alignItems={{ xs: "center", sm: "start" }}
                >
                    <div>
                        <Typography sx={{ color: "#878787" }}>
                            <strong>Техническая поддержка</strong>
                        </Typography>
                        <Box>
                            <i style={{ color: "#878787" }} className="fa-solid fa-envelope" />
                            <ExternalLink
                                sx={{
                                    display: "inline-block",
                                    padding: "8px",
                                    color: "#a8470f",
                                    fontWeight: "bold",
                                    textDecoration: "none"
                                }}
                                href="mailto:support@amvera.ru"
                            >
                                support@amvera.ru
                            </ExternalLink>
                        </Box>
                        <Box>
                            <i style={{ color: "#878787" }} className="fa-brands fa-telegram" />
                            <ExternalLink
                                sx={{
                                    display: "inline-block",
                                    padding: "8px",
                                    color: "#a8470f",
                                    fontWeight: "bold",
                                    textDecoration: "none"
                                }}
                                href="https://t.me/amverachat"
                                target="_blank"
                            >
                                Чат Телеграм
                            </ExternalLink>
                        </Box>
                        <Typography sx={{ color: "#878787" }}>Отвечаем: 9:00 &ndash; 20:00 МСК</Typography>
                    </div>

                    <div>
                        <Typography sx={{ color: "#878787" }}>
                            <strong>Ссылки в соцсетях</strong>
                        </Typography>
                        <Box>
                            <i style={{ color: "#878787" }} className="fa-brands fa-vk" />
                            <ExternalLink
                                sx={{
                                    display: "inline-block",
                                    padding: "8px",
                                    color: "#a8470f",
                                    fontWeight: "bold",
                                    textDecoration: "none"
                                }}
                                href="https://vk.com/data4"
                                target="_blank"
                            >
                                Группа ВК
                            </ExternalLink>
                        </Box>
                        <Box>
                            <i style={{ color: "#878787" }} className="fa-brands fa-telegram" />
                            <ExternalLink
                                sx={{
                                    display: "inline-block",
                                    padding: "8px",
                                    color: "#a8470f",
                                    fontWeight: "bold",
                                    textDecoration: "none"
                                }}
                                href="https://t.me/amvera_cloud"
                                target="_blank"
                            >
                                Канал в Телеграме
                            </ExternalLink>
                        </Box>
                        {/* <Box>
                            <i style={{ color: "#878787" }} className="fa-brands fa-discord" />
                            <ExternalLink
                                sx={{
                                    display: "inline-block",
                                    padding: "8px",
                                    color: "#a8470f",
                                    fontWeight: "bold",
                                    textDecoration: "none"
                                }}
                                href="https://discord.gg/sZCEeHXV"
                                target="_blank"
                            >
                                Канал в Discord
                            </ExternalLink>
                        </Box> */}
                        <Box>
                            <i style={{ color: "#878787" }} className="fa-solid fa-pencil" />
                            <ExternalLink
                                sx={{
                                    display: "inline-block",
                                    padding: "8px",
                                    color: "#a8470f",
                                    fontWeight: "bold",
                                    textDecoration: "none"
                                }}
                                href="https://habr.com/ru/company/amvera/blog/"
                                target="_blank"
                            >
                                Блог на Хабре
                            </ExternalLink>
                        </Box>
                    </div>
                </Stack>
            </Footer>

            <Popover
                id={userPopupId}
                open={userPopupOpened}
                anchorEl={userPopupAnchor}
                onClose={handleUserPopupClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right"
                }}
            >
                <List>
                    <ListItem disablePadding>
                        <ListItemButton component="a" href={config.profileLink} target="_blank" rel="noreferrer">
                            <ListItemIcon>
                                <i className="fa-solid fa-user" />
                            </ListItemIcon>
                            <ListItemText>Профиль</ListItemText>
                        </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding>
                        <ListItemButton component={Link} to="/auth/logout">
                            <ListItemIcon>
                                <i className="fa-solid fa-arrow-right-from-bracket" />
                            </ListItemIcon>
                            <ListItemText>Выход</ListItemText>
                        </ListItemButton>
                    </ListItem>
                </List>
            </Popover>

            <Popover
                id={notificationsPopupId}
                open={notificationsPopupOpened}
                anchorEl={notificationPopupAnchor}
                onClose={handleNotificationsPopupClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left"
                }}
            >
                <div className="amvera-notifications-box">
                    <Nothing>
                        <Nothing.Item>
                            <Typography variant="h2" color="darkgrey" sx={{ textAlign: "center" }}>
                                <i className="fa-solid fa-paper-plane" />
                            </Typography>
                            <Typography variant="h6">Уведомлений пока нет</Typography>
                        </Nothing.Item>
                    </Nothing>
                </div>
            </Popover>

            <Drawer anchor="right" open={mobileDrawerOpened} onClose={handleMobileDrawerClose}>
                <Toolbar />
                <List subheader={<ListSubheader>{auth.user?.profile.preferred_username} </ListSubheader>}>
                    <ListItem disablePadding>
                        <ListItemButton component={Link} to="/billing/balance">
                            <ListItemIcon>
                                <i className="fa-solid fa-coins" />
                            </ListItemIcon>
                            <ListItemText>
                                Баланс: <BalanceDisplay />
                                {/* Баланс: &beta; = &infin; */}
                            </ListItemText>
                        </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding>
                        <ListItemButton component="a" href={config.profileLink} target="_blank" rel="noreferrer">
                            <ListItemIcon>
                                <i className="fa-solid fa-user" />
                            </ListItemIcon>
                            <ListItemText>Профиль</ListItemText>
                        </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding>
                        <ListItemButton component="a" href="https://docs.amvera.ru/" target="_blank" rel="noreferrer">
                            <ListItemIcon>
                                <i className="fa-solid fa-circle-question" />
                            </ListItemIcon>
                            <ListItemText>Документация</ListItemText>
                        </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding>
                        <ListItemButton component={Link} to="/auth/logout">
                            <ListItemIcon>
                                <i className="fa-solid fa-arrow-right-from-bracket" />
                            </ListItemIcon>
                            <ListItemText>Выход</ListItemText>
                        </ListItemButton>
                    </ListItem>
                </List>
            </Drawer>
            <LogSnackbarHandler />
        </div>
    );
};

export default PageTemplate;
