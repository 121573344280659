import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    id: null,
    name: "",
    value: "",
    isSecret: false
};

export const editableVariableSlice = createSlice({
    name: "editableVariable",
    initialState,
    reducers: {
        setVariable: (state, action) => action.payload,
        resetVariable: () => initialState,
        setId: (state, action) => {
            state.id = action.payload;
        },
        setName: (state, action) => {
            state.name = action.payload;
        },
        setValue: (state, action) => {
            state.value = action.payload;
        },
        setIsSecret: (state, action) => {
            state.isSecret = action.payload;
        }
    }
});

export const { setVariable, resetVariable, setId, setName, setValue, setIsSecret } = editableVariableSlice.actions;

export default editableVariableSlice.reducer;
