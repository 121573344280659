import {
    Card,
    CardContent,
    Checkbox,
    Divider,
    FormControlLabel,
    Icon,
    IconButton,
    MenuItem,
    Paper,
    Select,
    Stack,
    Tooltip,
    Typography,
    CircularProgress
} from "@mui/material";
import { useCallback, useEffect, useRef, useState } from "react";
import parse from "html-react-parser";
import LoadingButton from "@mui/lab/LoadingButton";
import { hasText } from "../../../utils";
import "./style.css";

const Terminal = ({ content, historyLoading, onClickLoadHistory, onClickClearContent }) => {
    const [isOnTop, setIsOnTop] = useState(false);
    const [isOnBottom, setIsOnBottom] = useState(false);
    const [tailMode, setTailMode] = useState(true);
    const [showDate, setShowDate] = useState(false);
    const [linesToLoad, setLinesToLoad] = useState(50);

    const cardRef = useRef();

    const determineScrollPosition = useCallback(() => {
        setIsOnTop(cardRef.current.scrollTop === 0);
        const scrollBottom = parseInt(cardRef.current.scrollHeight - cardRef.current.scrollTop);
        setIsOnBottom(scrollBottom === cardRef.current.clientHeight);
    }, []);

    const scrollToTop = useCallback(() => {
        cardRef.current.scrollTo({ top: 0, behavior: "smooth" });
    }, []);

    const scrollToBottom = useCallback(() => {
        cardRef.current.scrollTo({ top: cardRef.current.scrollHeight, behavior: "smooth" });
    }, []);

    const handleClickCopyToClipboard = useCallback(() => {
        navigator.clipboard.writeText(content);
    }, [content]);

    const handleClickClearContent = useCallback(() => {
        onClickClearContent();
        determineScrollPosition();
    }, [onClickClearContent, determineScrollPosition]);

    const handleClickLoadHistory = useCallback(() => {
        // setTailMode(false);
        onClickLoadHistory(linesToLoad);
        scrollToBottom();
        // scrollToTop();
    }, [scrollToBottom, linesToLoad, onClickLoadHistory]);

    const handleChangeLinesToLoad = useCallback(event => {
        setLinesToLoad(event.target.value);
    }, []);

    const handleCheckTailMode = useCallback(event => {
        setTailMode(event.target.checked);
    }, []);

    const handleCheckShowDate = useCallback(event => {
        setShowDate(event.target.checked);
    }, []);

    useEffect(() => {
        determineScrollPosition();
    }, [determineScrollPosition]);

    useEffect(() => {
        if (tailMode) scrollToBottom();
        else determineScrollPosition();
        // onClickLoadHistory(linesToLoad);
    }, [tailMode, content, determineScrollPosition, scrollToBottom]);

    return (
        <>
            <Card ref={cardRef} className="terminal" variant="outlined" onScroll={determineScrollPosition}>
                <CardContent sx={{ position: "relative", height: "100%" }}>
                    <Typography
                        component="pre"
                        variant="code"
                        className={`terminal-content ${showDate ? "terminal__show-log-time" : ""}`}
                    >
                        {hasText(content)
                            ? parse(content)
                            : !historyLoading
                            ? "Чтобы загрузить историю, воспользуйтесь кнопкой в нижней части окна"
                            : "Идет загрузка логов..."}
                    </Typography>
                    {historyLoading && !hasText(content) && (
                        <div className="circular-progress-container">
                            <CircularProgress />
                        </div>
                    )}
                </CardContent>
            </Card>
            <Paper className="terminal-toolbar">
                <Stack direction="row" justifyContent="space-between" alignItems="center" flexWrap="wrap">
                    <LoadingButton
                        loading={historyLoading}
                        onClick={handleClickLoadHistory}
                        size="small"
                        variant="contained"
                        sx={{
                            maxHeight: "31px",
                            padding: "7px 12px",
                            flex: { xs: 1, md: 0 },
                            minWidth: "fit-content"
                        }}
                    >
                        Загрузить лог
                    </LoadingButton>
                    <Stack
                        spacing={0.6}
                        direction="row-reverse"
                        alignItems="center"
                        flexWrap="wrap"
                        justifyContent={{ xs: "center", sm: "end" }}
                    >
                        <Tooltip arrow disableInteractive title="Вниз">
                            <span>
                                <IconButton
                                    className="terminal-toolbar-button"
                                    size="small"
                                    disabled={isOnBottom}
                                    onClick={scrollToBottom}
                                >
                                    <Icon className="fa-solid fa-arrow-down" fontSize="small" />
                                </IconButton>
                            </span>
                        </Tooltip>
                        <Tooltip arrow disableInteractive title="Вверх">
                            <span>
                                <IconButton
                                    className="terminal-toolbar-button"
                                    disabled={isOnTop}
                                    onClick={scrollToTop}
                                >
                                    <Icon className="fa-solid fa-arrow-up" fontSize="small" />
                                </IconButton>
                            </span>
                        </Tooltip>
                        <Divider flexItem orientation="vertical" />
                        <Tooltip arrow disableInteractive title="Очистить">
                            <span>
                                <IconButton
                                    className="terminal-toolbar-button"
                                    disabled={!hasText(content)}
                                    onClick={handleClickClearContent}
                                >
                                    <Icon className="fa-solid fa-trash" fontSize="small" />
                                </IconButton>
                            </span>
                        </Tooltip>
                        <Tooltip arrow disableInteractive title="Скопировать">
                            <span>
                                <IconButton
                                    className="terminal-toolbar-button"
                                    disabled={!hasText(content)}
                                    onClick={handleClickCopyToClipboard}
                                >
                                    <Icon className="fa-solid fa-copy" fontSize="small" />
                                </IconButton>
                            </span>
                        </Tooltip>
                        <Divider flexItem orientation="vertical" />
                        <Tooltip arrow disableInteractive title="Загрузить историю">
                            <IconButton
                                className="terminal-toolbar-button"
                                disabled={historyLoading}
                                onClick={handleClickLoadHistory}
                            >
                                <Icon className="fa-solid fa-clock-rotate-left" fontSize="small" />
                            </IconButton>
                        </Tooltip>
                        <Tooltip arrow disableInteractive title="Кол-во строк">
                            <Select
                                className="select-lines-to-load"
                                variant="standard"
                                value={linesToLoad}
                                onChange={handleChangeLinesToLoad}
                            >
                                <MenuItem value={25}>25</MenuItem>
                                <MenuItem value={50}>50</MenuItem>
                                <MenuItem value={100}>100</MenuItem>
                                <MenuItem value={250}>250</MenuItem>
                                <MenuItem value={500}>500</MenuItem>
                                <MenuItem value={1000}>1000</MenuItem>
                            </Select>
                        </Tooltip>
                        <Divider flexItem orientation="vertical" sx={{ display: { xs: "none", sm: "inline" } }} />
                        <FormControlLabel
                            label={<Typography variant="body2">Tail mode</Typography>}
                            control={<Checkbox size="small" checked={tailMode} onChange={handleCheckTailMode} />}
                            sx={{ marginRight: "12px !important" }}
                        />
                        <Divider flexItem orientation="vertical" />
                        <FormControlLabel
                            label={<Typography variant="body2">Show date</Typography>}
                            control={<Checkbox size="small" checked={showDate} onChange={handleCheckShowDate} />}
                            sx={{ marginRight: "12px !important" }}
                        />
                    </Stack>
                </Stack>
            </Paper>
        </>
    );
};

export default Terminal;
