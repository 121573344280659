import { Button, FormControl, FormControlLabel, IconButton, Stack, Switch, TextField, Typography } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import { useServices } from "../../../../DI/hooks";
import useHandleError from "../../../../Hooks/useHandleError";
import { SNACKBAR_TYPE } from "../../../../constants";
import SimpleDialog from "../../SimpleDialog";
import AlertsDialog from "./AlertsDialog";

const MIN_ALERTS = 1;
const MIN_WORKS = 10;
const MAX_ALERTS = 10;
const MAX_WORKS = 180;

const AlertComponent = ({ slug }) => {
    const [alertAmount, setAlertAmount] = useState();
    const [workingMinutes, setWorkingMunutes] = useState();
    const [errorInLogAlerts, setErrorInLogAlerts] = useState();
    const [openSimple, setOpenSimple] = useState(false);
    const [openAlerts, setOpenAlerts] = useState(false);
    const [showInputs, setShowInputs] = useState();
    const [shouldDisable, setShouldDisable] = useState(false);

    const { api } = useServices();
    const { handleApiError } = useHandleError();
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        api.projects
            .getAlerts(slug)
            .then(response => (response.ok ? response.json() : Promise.reject(response)))
            .then(data => {
                if (data.maxCountNotification > 0) setShowInputs(true);
                setAlertAmount(data.maxCountNotification);
                setWorkingMunutes(data.withoutErrorMinutes);
                setErrorInLogAlerts(data.errorInLogAlerts);
            });
    }, [slug, api]);

    useEffect(() => {
        if (alertAmount > 0) setShowInputs(true);
        if (alertAmount === 0) setShowInputs(false);
    }, [alertAmount, workingMinutes]);

    const callbacks = {
        handleWorkingMinutes: useCallback(e => {
            const value = parseInt(e.target.value);
            if (value <= 0) {
                setWorkingMunutes(1);
                return;
            }
            if (e.target.value > MAX_WORKS) {
                setWorkingMunutes(MAX_WORKS);
                return;
            }
            setWorkingMunutes(value);
        }, []),
        handleAlertsAmount: useCallback(e => {
            const value = parseInt(e.target.value);
            if (value <= 0) {
                setAlertAmount(1);
                return;
            }
            if (e.target.value > MAX_ALERTS) {
                setAlertAmount(MAX_ALERTS);
                return;
            }
            setAlertAmount(value);
        }, []),
        focusOutHandleAlerts: useCallback(e => {
            const { value } = e.target;
            if (value === "" || value < MIN_ALERTS) setAlertAmount(MIN_ALERTS);
        }, []),
        focusOutHandleWorkingMinutes: useCallback(e => {
            const { value } = e.target;
            if (value === "" || value < MIN_WORKS) setWorkingMunutes(MIN_WORKS);
        }, []),
        handleSetAlerts: useCallback(
            errorAlerts => {
                const body = {
                    maxCountNotification: alertAmount,
                    withoutErrorMinutes: workingMinutes,
                    errorInLogAlerts: errorAlerts
                };
                console.log(body);
                api.projects
                    .setAlerts(slug, body)
                    .then(response => (response.ok ? response.json() : Promise.reject(response)))
                    .then(data => {
                        setAlertAmount(data.maxCountNotification);
                        setWorkingMunutes(data.withoutErrorMinutes);
                        setOpenSimple(false);
                    })
                    .then(() => enqueueSnackbar("Успешно изменено", SNACKBAR_TYPE.SUCCESS))
                    .catch(handleApiError);
            },
            [alertAmount, errorInLogAlerts, workingMinutes, api, slug, handleApiError, enqueueSnackbar]
        ),
        handleOpenSimpleDialog: useCallback(() => {
            setOpenSimple(true);
        }, [setOpenSimple]),
        handleCloseSimpleDialog: useCallback(() => {
            setOpenSimple(false);
        }, [setOpenSimple]),
        handleOpenAlertsDialog: useCallback(() => {
            setOpenAlerts(true);
        }, [setOpenAlerts]),
        handleCloseAlertsDialog: useCallback(() => {
            setOpenAlerts(false);
        }, [setOpenAlerts]),
        disableAlerts: useCallback(() => {
            const body = {
                maxCountNotification: 0,
                withoutErrorMinutes: 0,
                errorInLogAlerts: false
            };
            api.projects
                .setAlerts(slug, body)
                .then(response => (response.ok ? response.json() : Promise.reject(response)))
                .then(data => {
                    setAlertAmount(data.maxCountNotification);
                    setWorkingMunutes(data.withoutErrorMinutes);
                    setOpenSimple(false);
                })
                .then(() => enqueueSnackbar("Успешно отключено", SNACKBAR_TYPE.SUCCESS))
                .catch(handleApiError)
                .finally(callbacks.handleCloseSimpleDialog);
        }, []),
        handleSwitch: useCallback(() => {
            if (alertAmount > 0) {
                setShouldDisable(true);
                callbacks.handleOpenSimpleDialog();
            }
            if (alertAmount === 0) callbacks.handleOpenAlertsDialog();
        }, [alertAmount]),
        handleButton: useCallback(() => {
            setShouldDisable(false);
            callbacks.handleOpenSimpleDialog();
        }, [])
    };

    const handleErrorInLogAlerts = useCallback(
        e => {
            setErrorInLogAlerts(e.target.checked);
            callbacks.handleSetAlerts(e.target.checked);
        },
        [setErrorInLogAlerts, callbacks.handleSetAlerts]
    );

    return (
        <>
            <FormControl>
                <Typography variant="h6" mb={2}>
                    Оповещения при сбоях работы проекта
                    <IconButton
                        size="small"
                        color="inherit"
                        href="https://docs.amvera.ru/general/notifications.html"
                        target="_blank"
                        rel="noreferrer"
                    >
                        <i className="fa-regular fa-circle-question" />
                    </IconButton>
                    <Switch checked={alertAmount !== 0} onChange={callbacks.handleSwitch} />
                </Typography>
                {showInputs && (
                    <Stack spacing={2} direction={{ xs: "column", lg: "row" }}>
                        <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                            <TextField
                                sx={{ width: 330 }}
                                size="small"
                                label="Максимальное количество оповещений в день"
                                name="alerts"
                                type="number"
                                value={alertAmount}
                                onChange={callbacks.handleAlertsAmount}
                                onBlur={callbacks.focusOutHandleAlerts}
                                InputProps={{ inputProps: { min: MIN_ALERTS, max: MAX_ALERTS } }}
                            />
                            <TextField
                                sx={{ width: 330 }}
                                size="small"
                                label="Минимальное время работы приложения"
                                name="workingMinutes"
                                type="number"
                                value={workingMinutes}
                                onChange={callbacks.handleWorkingMinutes}
                                onBlur={callbacks.focusOutHandleWorkingMinutes}
                                InputProps={{ inputProps: { min: MIN_WORKS, max: MAX_WORKS } }}
                            />
                        </Stack>
                        <Button
                            variant="contained"
                            color="primary"
                            sx={{ margin: 3, flex: 1, maxWidth: "250px" }}
                            onClick={callbacks.handleButton}
                        >
                            Применить
                        </Button>
                    </Stack>
                )}
            </FormControl>
            {showInputs && (
                <Typography variant="body2" color="darkgray">
                    Задайте максимальное количество оповещений в день и минимальное время работы приложения
                </Typography>
            )}

            <FormControl>
                <Typography variant="h6" mb={2}>
                    Всплывающие уведомления ошибок приложения
                </Typography>

                <FormControlLabel
                    checked={errorInLogAlerts ?? true}
                    control={<Switch onChange={handleErrorInLogAlerts} />}
                    label={`Уведомления ${errorInLogAlerts ? "выводятся" : "отключены"}`}
                />
            </FormControl>

            <SimpleDialog
                open={openSimple}
                onSave={shouldDisable ? callbacks.disableAlerts : () => callbacks.handleSetAlerts(errorInLogAlerts)}
                onClose={callbacks.handleCloseSimpleDialog}
                title="Настройки оповещений"
                content={shouldDisable ? "Отключить оповещения?" : "Применить новые настройки оповещений?"}
            />
            {openAlerts && (
                <AlertsDialog
                    open={openAlerts}
                    handleParentAlerts={setAlertAmount}
                    handleParentWorkingMinutes={setWorkingMunutes}
                    slug={slug}
                    onSave={() => callbacks.handleSetAlerts(errorInLogAlerts)}
                    onClose={callbacks.handleCloseAlertsDialog}
                    title="Настройки оповещений"
                />
            )}
        </>
    );
};

export default AlertComponent;
