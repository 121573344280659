import { useEffect } from "react";
import { useServices } from "../../DI/hooks";

const LogoutPage = () => {
    const { auth } = useServices();

    useEffect(() => {
        void auth.signoutRedirect();
    }, [auth]);

    return <h1>Logout</h1>;
};

export default LogoutPage;
