import { Backdrop, CircularProgress } from "@mui/material";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useServices } from "../../DI/hooks";

const UnauthenticatedRedirectPage = () => {
    const { auth } = useServices();
    const location = useLocation();

    useEffect(() => {
        const target = location.pathname + location.search + location.hash;
        window.sessionStorage.setItem("next-url", target);
        void auth.signinRedirect();
    }, [auth, location]);

    return (
        <Backdrop open>
            <CircularProgress color="inherit" />
        </Backdrop>
    );
};

export default UnauthenticatedRedirectPage;
