import { Navigate, Route, Routes } from "react-router-dom";
import AuthenticatedRedirectPage from "./Pages/AuthenticatedRedirectPage";
import NotFoundPage from "./Pages/NotFoundPage";
import LogoutPage from "./Pages/LogoutPage";
import ProjectsPage from "./Pages/ProjectsPage";
import PageTemplate from "./Pages/PageTemplate";
import SingleProjectPage from "./Pages/SingleProjectPage";
import BalancePage from "./Pages/BalancePage";
import SingleCnpgPage from "./Pages/SingleCnpgPage";
import SingleDbPage from "./Pages/SingleDbPage";
import SingleDbRedirect from "./Pages/SingleDbRedirect";
import DocumentsPage from "./Pages/DocumentsPage";

const AuthenticatedRouter = () => (
    <Routes>
        <Route path="/" element={<PageTemplate />}>
            <Route index element={<Navigate to="/projects" />} />
            <Route path="/auth/callback" element={<AuthenticatedRedirectPage />} />
            <Route path="/auth/logout" element={<LogoutPage />} />
            <Route path="/projects" element={<ProjectsPage />} />
            <Route path="/projects/compute/:slug" element={<SingleProjectPage />} />
            <Route path="/projects/cnpg/:slug" element={<SingleCnpgPage />} />
            <Route path="/projects/db/:serviceType/:slug" element={<SingleDbRedirect />} />

            {/* <Route path="/balance" element={<BalanceStubPage />} /> */}
            <Route path="/billing/balance" element={<BalancePage />} />
            <Route path="/billing/documents" element={<DocumentsPage />} />
            <Route path="*" element={<NotFoundPage />} />
        </Route>
    </Routes>
);

export default AuthenticatedRouter;
