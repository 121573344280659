import React, { useState } from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, FormControlLabel, Checkbox, Button } from "@mui/material";

const DownloadConfirmationDialog = ({ isOpen, onClose, onDownload }) => {
    const [selectedItems, setSelectedItems] = useState({
        data: false,
        artifacts: false,
        code: true,
        repos: false
    });

    const handleCheckboxChange = event => {
        const { name, checked } = event.target;
        setSelectedItems(prevSelectedItems => ({
            ...prevSelectedItems,
            [name]: checked
        }));
    };

    const handleDownload = () => {
        onDownload(selectedItems);
        setSelectedItems({
            data: false,
            artifacts: false,
            code: false,
            repository: false
        });
    };

    return (
        <Dialog open={isOpen} onClose={onClose}>
            <DialogTitle>Выберите, что требуется скачать:</DialogTitle>
            <DialogContent>
                <FormControlLabel
                    control={<Checkbox checked={selectedItems.data} onChange={handleCheckboxChange} name="data" />}
                    label="Постоянное хранилище"
                />
                <FormControlLabel
                    control={
                        <Checkbox checked={selectedItems.artifacts} onChange={handleCheckboxChange} name="artifacts" />
                    }
                    label="Артефакты"
                />
                <FormControlLabel
                    control={<Checkbox checked={selectedItems.code} onChange={handleCheckboxChange} name="code" />}
                    label="Код"
                />
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={selectedItems.repository}
                            onChange={handleCheckboxChange}
                            name="repository"
                        />
                    }
                    label="Репозиторий"
                />
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" onClick={onClose}>
                    Отмена
                </Button>

                <Button variant="contained" onClick={handleDownload}>
                    Скачать
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default DownloadConfirmationDialog;
