import { Button } from "@mui/material";
import { useCallback, useState } from "react";
import AddDialog from "./AddDialog";
import useCreateDb from "../api/useCreateDb";
import useIsDisabled from "../../SingleMySQLPage/hooks/useIsDisabled";

export default function AddDbButton({ setDetails, slug }) {
    const [isDialog, setIsDialog] = useState(false);
    const [dbLoading, dbCreate] = useCreateDb(setDetails, slug);
    const { isDisabled } = useIsDisabled();

    const handleOpenDialog = useCallback(() => {
        setIsDialog(true);
    }, []);
    const handleCloseDialog = useCallback(() => {
        setIsDialog(false);
    }, []);

    const handleAddDb = data => {
        const { database } = data;
        dbCreate(database, handleCloseDialog);
    };

    return (
        <>
            <Button
                sx={{ maxWidth: "fit-content" }}
                variant="contained"
                onClick={handleOpenDialog}
                disabled={isDisabled}
            >
                Добавить Базу Данных
            </Button>
            <AddDialog
                open={isDialog}
                onClose={handleCloseDialog}
                type="db"
                onClick={handleAddDb}
                loading={dbLoading}
            />
        </>
    );
}
