import LoadingButton from "@mui/lab/LoadingButton";
import { Alert, AlertTitle, Button, IconButton, Stack, Typography, Icon, Box } from "@mui/material";
import { useCallback, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import SelectTariff from "../SelectTariff";
import DomainDialog from "../DomainDialog";
import DomainTable from "../DomainTable";
import useHandleError from "../../../Hooks/useHandleError";
import ConfirmationDialog from "../ConfirmationDialog";
import { useServices } from "../../../DI/hooks";
import { resetDomain, setDomain } from "../../../Store/DomainSlice";
import AlertComponent from "./AlertComponent";
import { AutoScaling } from "../../../features/ProjectSettings";
import CodeBlock from "../CodeBlock";

const ProjectSettings = props => {
    const dispatch = useDispatch();
    const [tariffId, setTariffId] = useState(props?.tariffId ?? -1);

    const { api } = useServices();

    const domain = useSelector(state => state?.domain ?? null);

    const [editDialogOpen, setEditDialogOpen] = useState(false);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

    const [domains, setDomains] = useState([]);

    const handleOpenEditDialog = useCallback(() => setEditDialogOpen(true), []);

    const handleCloseEditDialog = useCallback(() => setEditDialogOpen(false), []);

    const handleOpenDeleteDialog = useCallback(() => setDeleteDialogOpen(true), []);

    const handleCloseDeleteDialog = useCallback(() => setDeleteDialogOpen(false), []);

    const { handleApiError } = useHandleError();

    const handleChangeTariffId = useCallback(event => {
        setTariffId(event.target.value);
    }, []);

    const handleClickApplyTariff = useCallback(() => {
        props.handleUpdateTariffId(tariffId);
    }, [props, tariffId]);

    const internalDomainsGenerator = useCallback(project => {
        if (project?.serviceType === "compute") {
            return [{ id: "int", domainName: `amvera-${project?.ownerName}-run-${project?.slug}`, type: "internal" }];
        }
        if (project?.serviceType === "cnpg") {
            const rw = {
                id: "rw",
                domainName: `amvera-${project?.ownerName}-cnpg-${project?.slug}-rw`,
                type: "internal"
            };
            const ro = {
                id: "ro",
                domainName: `amvera-${project?.ownerName}-cnpg-${project?.slug}-ro`,
                type: "internal"
            };
            const r = {
                id: "r",
                domainName: `amvera-${project?.ownerName}-cnpg-${project?.slug}-r`,
                type: "internal"
            };
            return [rw, ro, r];
        }
    }, []);

    const fetchDomains = useCallback(() => {
        api.domain
            .getAll(props?.project.slug)
            .then(response => (response.ok ? response.json() : Promise.reject(response)))
            .then(data => {
                const modifiedDomains = data.map(d => {
                    d.type = "external";
                    return d;
                });

                modifiedDomains.push(...internalDomainsGenerator(props?.project));

                setDomains(modifiedDomains);
                console.log(modifiedDomains);
            })
            .catch(handleApiError);
    }, [api, props?.project, handleApiError]);

    const handleClickCreateDomain = useCallback(() => {
        dispatch(resetDomain());
        handleOpenEditDialog();
    }, [dispatch, handleOpenEditDialog]);

    const handleClickSave = useCallback(
        data => {
            const responsePromise = api.domain.add(props?.project.slug, data);
            responsePromise
                .then(response => (response.ok ? fetchDomains() : Promise.reject(response)))
                .then(() => handleCloseEditDialog())
                .catch(handleApiError);
        },
        [api, props?.project, fetchDomains, handleCloseEditDialog, handleApiError]
    );

    const handleClickDelete = useCallback(
        selected_domain => {
            dispatch(resetDomain());
            dispatch(setDomain(selected_domain));
            handleOpenDeleteDialog();
        },
        [dispatch, handleOpenDeleteDialog]
    );

    const handleClickConfirmDelete = useCallback(() => {
        api.domain
            .delete(props?.project.slug, domain.id)
            .then(response => (response.ok ? fetchDomains() : Promise.reject(response)))
            .then(() => handleCloseDeleteDialog())
            .catch(handleApiError);
    }, [api, domain, props?.project, fetchDomains, handleCloseDeleteDialog, handleApiError]);

    useEffect(() => {
        fetchDomains();
    }, [fetchDomains]);

    return (
        <Stack spacing={2}>
            <Typography variant="h6">
                Биллинг
                <IconButton
                    size="small"
                    color="inherit"
                    href="https://docs.amvera.ru/general/tarifs.html"
                    target="_blank"
                    rel="noreferrer"
                >
                    <i className="fa-regular fa-circle-question" />
                </IconButton>
            </Typography>
            <Alert severity="warning">После смены тарифного плана все контейнеры проекта будут перезапущены</Alert>

            <SelectTariff tariffId={tariffId} onChange={handleChangeTariffId}>
                <LoadingButton
                    size="medium"
                    variant="outlined"
                    loading={props.tariffUpdating}
                    disabled={tariffId === props.tariffId || tariffId === -1}
                    onClick={handleClickApplyTariff}
                >
                    Применить
                </LoadingButton>
            </SelectTariff>

            {(props?.project?.serviceType === "compute" || props?.project?.serviceType === "cnpg") && (
                <>
                    <Stack spacing={1.5} direction="column">
                        <Stack
                            direction={{ xs: "column", sm: "row" }}
                            alignItems="center"
                            justifyContent="space-between"
                        >
                            <Typography
                                variant="h6"
                                textAlign="start"
                                sx={{ width: "100%", flex: "1 1" }}
                                mb={{ xs: 2, sm: 0 }}
                            >
                                Доменные имена
                                <IconButton
                                    size="small"
                                    color="inherit"
                                    href="https://docs.amvera.ru/applications/configuration/network.html#id3"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <i className="fa-regular fa-circle-question" />
                                </IconButton>
                            </Typography>
                            <Button
                                variant="contained"
                                color="primary"
                                size="small"
                                startIcon={<Icon className="fa-solid fa-plus" />}
                                onClick={handleClickCreateDomain}
                            >
                                Добавить доменное имя
                            </Button>
                        </Stack>
                        <div style={{ margin: "20px 0 20px 0", textAlign: "center" }}>
                            <DomainTable
                                domains={domains}
                                onDelete={handleClickDelete}
                                fetchDomains={fetchDomains}
                                handleApiError={handleApiError}
                                project={props.project}
                            />
                        </div>
                    </Stack>
                    <DomainDialog open={editDialogOpen} onSave={handleClickSave} onClose={handleCloseEditDialog} />
                    <ConfirmationDialog
                        title="Удаление"
                        description={`Удалить домен '${domain?.domainName ?? "-"}'?`}
                        open={deleteDialogOpen}
                        onClickConfirm={handleClickConfirmDelete}
                        onClose={handleCloseDeleteDialog}
                    />
                </>
            )}

            <AlertComponent slug={props.project?.slug} />
            <Typography variant="h6">
                Управление
                <IconButton
                    size="small"
                    color="inherit"
                    href="https://docs.amvera.ru/general/disposion.html"
                    target="_blank"
                    rel="noreferrer"
                >
                    <i className="fa-regular fa-circle-question" />
                </IconButton>
            </Typography>

            <Box sx={{ py: "16px" }}>
                <Stack direction="row" alignItems="center" justifyContent="space-between">
                    <Button variant="contained" color="primary" onClick={props.handleFreezeProject}>
                        Заморозить проект
                    </Button>
                    <AutoScaling slug={props.project?.slug} />
                </Stack>
            </Box>

            <Alert severity="warning" variant="outlined">
                <AlertTitle>Действия в этой секции необратимы!</AlertTitle>
                <Button
                    variant="contained"
                    color="error"
                    onClick={props.handleOpenDeleteProjectDialogClick}
                    sx={{ margin: 3 }}
                >
                    Удалить проект
                </Button>
            </Alert>
        </Stack>
    );
};

export default ProjectSettings;
