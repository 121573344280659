import { IconButton } from "@mui/material";
import { Delete } from "@mui/icons-material";
import { useCallback, useState } from "react";
import ConfirmationDialog from "../../ConfirmationDialog";

export function DeletePortButton({ removePort }) {
    const [open, setOpen] = useState(false);

    const handleCloseDialog = useCallback(() => {
        setOpen(false);
    }, []);

    const handleOpenDialog = useCallback(() => {
        setOpen(true);
    }, []);

    return (
        <>
            <IconButton onClick={handleOpenDialog}>
                <Delete fontSize="small" color="primary" />
            </IconButton>
            <ConfirmationDialog
                title="Вы действительно хотите удалить данный порт?"
                open={open}
                onClose={handleCloseDialog}
                onClickConfirm={removePort}
            />
        </>
    );
}
