import { Box, Icon, IconButton, Tooltip, Typography, Zoom } from "@mui/material";
import { useCallback, useRef, useState } from "react";
import "./style.css";

const CodeBlock = ({ children = "amogus" }) => {
    const [copyClicked, setCopyClicked] = useState(false);
    const [copyClicks, setCopyClicks] = useState(0);

    const textRef = useRef();

    const parseCommandLine = (value = "") => {
        const trimmedValue = value.trim();
        return trimmedValue.startsWith("$ ") ? trimmedValue.replace("$ ", "") : trimmedValue;
    };

    const handleClickCopy = useCallback(() => {
        setCopyClicked(true);
        if (copyClicked) setCopyClicks(prevState => prevState + 1);
        navigator.clipboard.writeText(
            textRef?.current?.innerText?.split("\n")?.map(parseCommandLine)?.join("\n") ?? "amogus"
        );
    }, [copyClicked]);

    const handleMouseLeave = useCallback(() => {
        setTimeout(() => {
            setCopyClicked(false);
            setCopyClicks(0);
        }, 250);
    }, []);

    return (
        <div className="code-block__root">
            <Box
                sx={{
                    width: "100%",
                    maxWidth: "600px",
                    overflowX: "auto",
                    whiteSpace: "nowrap"
                }}
            >
                <Typography className="code-block__text" ref={textRef}>
                    {children}
                </Typography>
            </Box>
            <Tooltip arrow disableInteractive title={!copyClicked ? "Скопировать" : "Скопировано"}>
                <IconButton
                    className="code-block__button"
                    size="small"
                    onClick={handleClickCopy}
                    onMouseLeave={handleMouseLeave}
                >
                    <Icon fontSize="small" />
                    <Zoom in={!copyClicked}>
                        <Icon className="fa-regular fa-copy code-block__icon" fontSize="small" />
                    </Zoom>
                    <Zoom in={copyClicked && copyClicks < 5}>
                        <Icon className="fa-solid fa-check code-block__icon" color="success" fontSize="small" />
                    </Zoom>
                    <Zoom in={copyClicked && copyClicks >= 5}>
                        <Icon className="fa-solid fa-frog code-block__icon" color="success" fontSize="small" />
                    </Zoom>
                </IconButton>
            </Tooltip>
        </div>
    );
};

export default CodeBlock;
