import { Button, FormControl, FormHelperText, TextField } from "@mui/material";
import { useCallback, useState } from "react";
import { useFormContext } from "react-hook-form";
import useAddNewBackup from "../../api/useAddNewBackup";
import FormDialog from "../../../../Elements/FormDialog";

const DescriptionField = () => {
    const {
        register,
        watch,
        formState: { errors }
    } = useFormContext();

    const description = watch("description", "");

    return (
        <FormControl fullWidth>
            <TextField
                fullWidth
                rows={6}
                multiline
                {...register("description", { maxLength: { value: 300, message: "Максимум 300 символов." } })}
            />

            <FormHelperText sx={{ textAlign: "end" }} error={errors?.description}>
                {description.length}/300
            </FormHelperText>
            <FormHelperText sx={{ textAlign: "end" }} error>
                {errors?.description ? errors?.description?.message : " "}
            </FormHelperText>
        </FormControl>
    );
};

export function AddBackupButton({ setBackups, slug, disabled, addBackup }) {
    const [dialogOpen, setDialogOpen] = useState(false);
    const { addLoading, handleAddBackup } = useAddNewBackup(setBackups, addBackup);

    const handleDialogClose = useCallback(() => {
        setDialogOpen(false);
    }, [setDialogOpen]);

    const handleDialogOpen = useCallback(() => {
        setDialogOpen(true);
    }, [setDialogOpen]);

    const onConfirm = useCallback(
        data => {
            data.slug = slug;
            handleAddBackup(data).then(handleDialogClose);
        },
        [handleAddBackup, handleDialogClose, slug]
    );

    return (
        <>
            <Button variant="contained" sx={{ maxWidth: "fit-content" }} onClick={handleDialogOpen} disabled={disabled}>
                Создать бэкап
            </Button>
            <FormDialog
                loading={addLoading}
                open={dialogOpen}
                onClose={handleDialogClose}
                onClickConfirm={onConfirm}
                title="Укажите описание бэкапа:"
            >
                <DescriptionField />
            </FormDialog>
        </>
    );
}
