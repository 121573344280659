import { useSnackbar } from "notistack";
import { useCallback } from "react";
import { SNACKBAR_TYPE } from "../constants";

const useStatusApi = () => {
    const { enqueueSnackbar } = useSnackbar();

    const handleSuccess = useCallback(
        text => {
            enqueueSnackbar(text, SNACKBAR_TYPE.SUCCESS);
        },
        [enqueueSnackbar]
    );

    const handleError = useCallback(
        (status, text) => {
            enqueueSnackbar(`${status}: ${text}`, SNACKBAR_TYPE.ERROR);
        },
        [enqueueSnackbar]
    );

    return [handleSuccess, handleError];
};

export default useStatusApi;
