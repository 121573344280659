import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    type: null,
    payload: null
};

export const dbEventStreamSlice = createSlice({
    name: "dbEventStream",
    initialState,
    reducers: {
        setMessage: (state, action) => {
            state.type = action.payload.type;
            state.payload = action.payload.payload;
        }
    }
});

export const { setMessage } = dbEventStreamSlice.actions;
export default dbEventStreamSlice.reducer;
