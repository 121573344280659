import { useCallback, useState } from "react";
import useStatusApi from "../../../../Hooks/useStatusApi";
import { useServices } from "../../../../DI/hooks";

const useCreateUser = (slug, setDetails, database) => {
    const [success, error] = useStatusApi();
    const { api } = useServices();
    const [loading, setLoading] = useState(false);

    const handleAddNewUser = useCallback(
        user => {
            setDetails(prev => {
                return {
                    ...prev,
                    databases: prev.databases.map(db => {
                        if (db.database === database)
                            return {
                                ...db,
                                users: [...db.users, user]
                            };
                        return db;
                    })
                };
            });
        },
        [database, setDetails]
    );

    const handleCreateUser = useCallback(
        async (username, password) => {
            setLoading(true);
            try {
                const res = await api.mysql.createUser({
                    username,
                    password,
                    slug,
                    database
                });
                if (!res.ok) {
                    throw Error(res.status);
                }
                const data = await res.json();
                handleAddNewUser(data);
            } catch (e) {
                error(e.message, "Произошла ошибка создания пользователя.");
            } finally {
                setLoading(false);
            }
        },
        [database, error, slug, handleAddNewUser, api.mysql]
    );

    return [loading, handleCreateUser];
};
export default useCreateUser;
