import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    projects: [],
    error: null,
    loading: true
};

export const projectsSlice = createSlice({
    name: "projects",
    initialState,
    reducers: {
        setProjects: (state, action) => {
            state.projects = action.payload;
            state.error = null;
            state.loading = false;
        },
        setError: (state, action) => {
            state.error = action.payload;
            state.projects = [];
            state.loading = false;
        },
        setLoading: state => {
            state.loading = true;
        },
        applyRepositoryEvent: (state, action) => {
            const event = action.payload;
            if (event.action !== "CREATE" || !event.success) return;

            for (const project of state.projects) {
                if (project.slug === event.repository) {
                    project.status = "EMPTY";
                }
            }
        },
        applyBuildStartedEvent: (state, action) => {
            const event = action.payload;

            for (const project of state.projects) {
                if (project.slug === event.project) {
                    project.status = "BUILD_STARTED";
                    project.statusMessage = "";
                }
            }
        },
        applyTaskStatusEvent: (state, action) => {
            const event = action.payload;

            for (const project of state.projects) {
                if (project.slug === event.project) {
                    if (event.stage === "BUILD") {
                        if (event.success) {
                            project.status = "DEPLOYING";
                        } else {
                            project.status = "BUILD_FAILED";
                        }
                    } else if (event.stage === "RUN") {
                        if (event.success) {
                            project.status = "STARTING";
                        } else {
                            project.status = "DEPLOY_FAILED";
                        }
                    }
                    project.statusMessage = event.message;
                }
            }
        },
        applyRunStatusEvent: (state, action) => {
            const event = action.payload;
            for (const project of state.projects) {
                if (project.slug === event.service) {
                    project.status = event.message;
                    project.statusMessage = event.message;
                }
            }
        }
    }
});

export const {
    setProjects,
    setError,
    setLoading,
    applyRepositoryEvent,
    applyBuildStartedEvent,
    applyTaskStatusEvent,
    applyRunStatusEvent
} = projectsSlice.actions;

export default projectsSlice.reducer;
