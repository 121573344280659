import CachedIcon from "@mui/icons-material/Cached";
import { IconButton } from "@mui/material";
import { generateSecret } from "./lib/generateSecret";

const GenerateSecret = ({ setValue, field }) => {
    const handleClick = () => {
        setValue(field, generateSecret());
    };

    return (
        <IconButton onClick={handleClick}>
            <CachedIcon />
        </IconButton>
    );
};

export default GenerateSecret;
