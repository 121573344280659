import { Divider, Paper, Stack, Typography } from "@mui/material";
import BackupController from "../../SingleMySQLPage/components/BackupController/BackupController";

export function BackupsContainer({ backups, setBackups, deleteBackup, restoreBackup, isDisabled }) {
    return (
        <Paper sx={{ padding: 2 }}>
            {backups.length ? (
                <Stack divider={<Divider orientation="horizontal" flexItem />} spacing={1}>
                    {backups.map(backup => (
                        <BackupController
                            key={backup.id}
                            backupInfo={backup}
                            setBackups={setBackups}
                            deleteBackup={deleteBackup}
                            restoreBackup={restoreBackup}
                            isDisabled={isDisabled}
                        />
                    ))}
                </Stack>
            ) : (
                <Typography textAlign="center">На данный момент нет бэкапов.</Typography>
            )}
        </Paper>
    );
}
