import { useCallback, useState } from "react";
import useStatusApi from "./useStatusApi";

const usePut = (endpoint, doAlerts = false) => {
    const [success, error] = useStatusApi();
    const [loading, setLoading] = useState(false);

    const handlePut = useCallback(
        async (params, successText = "Успешно создано.", errorText = "Произошла ошибка.") => {
            setLoading(true);
            try {
                const res = await endpoint(...params);
                if (!res.ok) throw Error(res.status);

                if (doAlerts) success(successText);
                return res.json();
            } catch (e) {
                if (doAlerts) error(e.message, errorText);
                return null;
            } finally {
                setLoading(false);
            }
        },
        [doAlerts, success, error, endpoint]
    );
    return [loading, handlePut];
};

export default usePut;
