import { useEffect, useRef, useState } from "react";

const useCreated = status => {
    const [showSuccess, setShowSuccess] = useState(false);
    const hasStatusTransitioned = useRef(false);
    const timeoutId = useRef(null);

    useEffect(() => {
        if (status === "CREATING") {
            hasStatusTransitioned.current = true;
        } else if (status === "CREATED" && hasStatusTransitioned.current) {
            setShowSuccess(true);

            hasStatusTransitioned.current = false;

            timeoutId.current = setTimeout(() => {
                setShowSuccess(false);
            }, 3000);
        }

        return () => {
            if (timeoutId.current) {
                clearTimeout(timeoutId.current);
                timeoutId.current = null;
            }
        };
    }, [status]);

    return { showSuccess };
};

export default useCreated;
