import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, TextField } from "@mui/material";
import { useState, useCallback, useEffect } from "react";
import { useSnackbar } from "notistack";
import { useServices } from "../../../../DI/hooks";
import useHandleError from "../../../../Hooks/useHandleError";
import { SNACKBAR_TYPE } from "../../../../constants";

const MIN_ALERTS = 1;
const MAX_ALERTS = 10;
const DEFAULT_ALERTS = 3;
const MIN_WORKS = 10;
const DEFAULT_WORKS = 30;
const MAX_WORKS = 180;

const AlertsDialog = ({ open, handleParentAlerts, handleParentWorkingMinutes, onClose, title, slug }) => {
    const [alertAmount, setAlertAmount] = useState(DEFAULT_ALERTS);
    const [workingMinutes, setWorkingMinutes] = useState(DEFAULT_WORKS);
    const { api } = useServices();
    const { handleApiError } = useHandleError();
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        api.projects
            .getAlerts(slug)
            .then(response => (response.ok ? response.json() : Promise.reject(response)))
            .then(data => {
                if (data.maxCountNotification === 0) setAlertAmount(DEFAULT_ALERTS);
                if (data.maxCountNotification > 0) setAlertAmount(data.maxCountNotification);
                if (data.withoutErrorMinutes === 0) setWorkingMinutes(DEFAULT_WORKS);
                if (data.withoutErrorMinutes > 0) setWorkingMinutes(data.withoutErrorMinutes);
            });
    }, [slug, api]);

    const callbacks = {
        handleWorkingMinutes: useCallback(e => {
            const value = parseInt(e.target.value);
            if (value <= 0) {
                setWorkingMinutes(1);
                return;
            }
            if (e.target.value > MAX_WORKS) {
                setWorkingMinutes(MAX_WORKS);
                return;
            }
            setWorkingMinutes(value);
        }, []),
        handleAlertsAmount: useCallback(e => {
            const value = parseInt(e.target.value);
            if (value <= 0) {
                setAlertAmount(1);
                return;
            }
            if (e.target.value > MAX_ALERTS) {
                setAlertAmount(MAX_ALERTS);
                return;
            }
            setAlertAmount(value);
        }, []),
        focusOutHandleAlerts: useCallback(e => {
            const { value } = e.target;
            if (value === "" || value < MIN_ALERTS) setAlertAmount(MIN_ALERTS);
        }, []),
        focusOutHandleWorkingMinutes: useCallback(e => {
            const { value } = e.target;
            if (value === "" || value < MIN_WORKS) setWorkingMinutes(MIN_WORKS);
        }, []),
        handleSetAlerts: useCallback(() => {
            const body = {
                maxCountNotification: alertAmount,
                withoutErrorMinutes: workingMinutes
            };
            api.projects
                .setAlerts(slug, body)
                .then(response => (response.ok ? response.json() : Promise.reject(response)))
                .then(data => {
                    setAlertAmount(data.maxCountNotification);
                    setWorkingMinutes(data.withoutErrorMinutes);
                    handleParentAlerts(data.maxCountNotification);
                    handleParentWorkingMinutes(data.withoutErrorMinutes);
                })
                .then(() => enqueueSnackbar("Успешно изменено", SNACKBAR_TYPE.SUCCESS))
                .catch(handleApiError)
                .finally(onClose);
        }, [alertAmount, workingMinutes, api, slug, handleApiError, enqueueSnackbar])
    };
    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent dividers sx={{ width: 400 }}>
                <Stack direction="column" spacing={2}>
                    <TextField
                        fullWidth
                        size="small"
                        label="Максимальное количество оповещений в день"
                        name="alerts"
                        type="number"
                        value={alertAmount}
                        onChange={callbacks.handleAlertsAmount}
                        onBlur={callbacks.focusOutHandleAlerts}
                        InputProps={{ inputProps: { min: MIN_ALERTS, max: MAX_ALERTS } }}
                    />
                    <TextField
                        fullWidth
                        size="small"
                        label="Минимальное время работы приложения"
                        name="workingMinutes"
                        type="number"
                        value={workingMinutes}
                        onChange={callbacks.handleWorkingMinutes}
                        onBlur={callbacks.focusOutHandleWorkingMinutes}
                        InputProps={{ inputProps: { min: MIN_WORKS, max: MAX_WORKS } }}
                    />
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" onClick={onClose}>
                    Отмена
                </Button>
                <Button variant="contained" onClick={callbacks.handleSetAlerts}>
                    Подтвердить
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default AlertsDialog;
