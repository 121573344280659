import { Chip, Tooltip } from "@mui/material";
import { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";

const buildStatusDescriptions = {
    "": "Проект не развертывался",
    "CREATING": "Проект создается",
    "EMPTY": "Проект не развертывался",
    "BUILD_STARTED": "Выполняется сборка",
    "BUILD_STOPPED": "Сборка остановлена",
    "BUILD_FAILED": "Ошибка сборки",
    "DEPLOYING": "Проект развертывается",
    "DEPLOY_FAILED": "Ошибка развертывания",
    "STARTING": "Выполняется запуск",
    "RUN_ERROR": "Приложение запускается или работает с ошибкой",
    "RUNNING": "Приложение запущено",
    "STOPPED": "Приложение остановлено",
    "STOPPING": "Сервис останавливается",
    "FROZEN": "Приложение заморожено",
    "STOPPED_WITH_ERROR": "Остановленно из-за повторяющейся ошибки",
    "SCALING": "Меняем количество реплик",
    "Cluster in healthy state": "PostgreSQL запущен",
    "Setting up primary": "PostgreSQL запускается",
    "Waiting for the instances to become active": "PostgreSQL запускается",
    "Creating a new replica": "Идет создание новой реплики"
};

const buildStatusColor = {
    "": "info",
    "CREATING": "info",
    "EMPTY": "info",
    "BUILD_STARTED": "info",
    "BUILD_STOPPED": "warning",
    "BUILD_FAILED": "error",
    "DEPLOYING": "tertiary",
    "DEPLOY_FAILED": "error",
    "STARTING": "success",
    "RUN_ERROR": "error",
    "RUNNING": "success",
    "STOPPED": "warning",
    "STOPPING": "tertiary",
    "FROZEN": "warning",
    "STOPPED_WITH_ERROR": "error",
    "SCALING": "tertiary",
    "Cluster in healthy state": "success",
    "Setting up primary": "info",
    "Waiting for the instances to become active": "info",
    "Creating a new replica": "tertiary"
};

const buildStatusVariants = {
    "": "outlined",
    "CREATING": "outlined",
    "EMPTY": "outlined",
    "BUILD_STARTED": "filled",
    "BUILD_STOPPED": "filled",
    "BUILD_FAILED": "filled",
    "DEPLOYING": "filled",
    "DEPLOY_FAILED": "filled",
    "STARTING": "filled",
    "RUN_ERROR": "filled",
    "RUNNING": "filled",
    "STOPPED": "filled",
    "STOPPING": "outlined",
    "FROZEN": "outlined",
    "STOPPED_WITH_ERROR": "filled",
    "SCALING": "filled",
    "Cluster in healthy state": "filled",
    "Setting up primary": "filled",
    "Waiting for the instances to become active": "filled",
    "Creating a new replica": "filled"
};

const buildStatusHints = {
    "UNKNOWN": " Обратитесь в техподдержку для решения проблемы",
    "": "Для запуска приложения загрузите данные и соберите проект",
    "CREATING": "Если проект находится в статусе создания более минуты, произошла ошибка создания, пересоздайте проект",
    "EMPTY": "Для запуска приложения загрузите данные и соберите проект",
    "BUILD_STARTED": "Ознакомиться с процессом сборки можно во вкладке «Лог сборки»",
    "BUILD_FAILED":
        "Ознакомиться с ошибками сборки можно во вкладке «Лог сборки». Проверьте правильность конфигурации и/или Dockerfile",
    "DEPLOYING": "Сборка выполнена успешно, выполняется развертывание",
    "DEPLOY_FAILED":
        "Развертывание завершилось с ошибкой. Проверьте правильность amvera.yaml/Dockerfile. При повторении ошибки обратитесь в службу поддержки",
    "STARTING": "Успешно развернуто, выполняется запуск",
    "RUN_ERROR":
        "Приложение ещё не успело запуститься или произошла ошибка в его работе. Ознакомьтесь с логом приложения и проверьте файл конфигурации",
    "RUNNING": "Приложение работает без ошибок",
    "STOPPED":
        "Для возобновления работы проекта воспользуйтесь кнопкой запуска или задайте нужное количество инстансов",
    "FROZEN": "Проект был остановлен более 30 дней назад. Для запуска пересоберите и запустите проект",
    "STOPPED_WITH_ERROR":
        "Остановленно из-за повторяющейся ошибки. Ознакомьтесь с логом приложения для определения причин ошибки",
    "SCALING": "Меняем количество реплик"
};
const CustomWidthTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)({
    [`& .${tooltipClasses.tooltip}`]: {
        maxWidth: 700
    }
});

const decodeBuildStatus = status =>
    buildStatusDescriptions[status] === undefined ? (
        <CustomWidthTooltip title={buildStatusHints.UNKNOWN} placement="bottom-start">
            <Chip size="small" color="warning" variant="outlined" label="Неизвестный статус" />
        </CustomWidthTooltip>
    ) : (
        <CustomWidthTooltip title={buildStatusHints[status]} placement="bottom-start">
            <Chip
                size="small"
                color={buildStatusColor[status]}
                variant={buildStatusVariants[status]}
                label={buildStatusDescriptions[status]}
            />
        </CustomWidthTooltip>
    );

export default decodeBuildStatus;
