import { useCallback, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";

import { setRequiredInstances } from "../../../../Store/CurrentProjectSlice";
import { useServices } from "../../../../DI/hooks";
import useGetMySQLDetails from "../api/useGetMySQLDetails";
import { MySQLDetailsContext } from "../context/MySQLDetailsContext";
import usePost from "../../../../Hooks/usePost";
import { MySQLServiceStatusProvider } from "../context/MySQLServiceStatus";

export const withMySQL = (Component, additionalTabs) => {
    return props => {
        const { api } = useServices();
        const { slug } = useParams();
        const [scaling, setScaling] = useState(false);
        const dispatch = useDispatch();
        const [details, detailsError, detailsLoading, setDetails] = useGetMySQLDetails(slug);

        const handlePause = useCallback(params => api.mysql.pauseDb(params), [api.mysql]);
        const handleResume = useCallback(params => api.mysql.resumeDb(params), [api.mysql]);

        const [pauseLoading, executePause] = usePost(handlePause);
        const [resumeLoading, executeResume] = usePost(handleResume);

        const contextValue = useMemo(
            () => ({
                details,
                detailsError,
                detailsLoading,
                setDetails
            }),
            [details, detailsError, detailsLoading, setDetails]
        );

        const handleStartPause = useCallback(
            action => {
                if (action === 0) {
                    executePause({ serviceSlug: slug });
                } else {
                    executeResume({ serviceSlug: slug });
                }
            },
            [slug, executePause, executeResume]
        );

        const handleScaleChanged = useCallback(
            value => {
                dispatch(setRequiredInstances(value));
            },
            [dispatch]
        );

        const doScale = useCallback(
            value => {
                handleScaleChanged(value);
                setScaling(true);
                api.mysql
                    .scaleBySlug(slug, value)
                    .then(() => {
                        setScaling(false);
                    })
                    .catch(err => {
                        console.error(err);
                        setScaling(false);
                    });
            },
            [api.mysql, slug, handleScaleChanged]
        );

        const deleteDb = useCallback(dbSlug => api.mysql.deleteById(dbSlug), [api.mysql]);

        return (
            <MySQLDetailsContext.Provider value={contextValue}>
                <MySQLServiceStatusProvider>
                    <Component
                        {...props}
                        scaling={scaling}
                        doScale={doScale}
                        deleteDb={deleteDb}
                        additionalTabs={additionalTabs}
                        db="mysql"
                        handleStartPause={handleStartPause}
                    />
                </MySQLServiceStatusProvider>
            </MySQLDetailsContext.Provider>
        );
    };
};
