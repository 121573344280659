import React, { useState } from "react";
import { TextField, Button, Switch, FormGroup, FormControlLabel } from "@mui/material";

const PostgresConfig = ({ details, setDetails }) => {
    console.log(details);
    const handleChange = e => {
        console.log(details);
        const { name, value, type, checked } = e.target;
        setDetails({
            ...details,
            [name]: type === "checkbox" ? checked : value
        });
    };

    const handleSubmit = e => {
        e.preventDefault();
        // console.log(details);
        // Here you would usually send the formData to the server or handle it as needed
    };

    return (
        <form onSubmit={handleSubmit}>
            <TextField
                label="Postgres Version"
                name="postgresVersion"
                // value={details.postgresVersion}
                value="cloudnative-pg:1.22.1"
                disabled
                onChange={handleChange}
                margin="normal"
                fullWidth
            />
            <TextField
                label="Имя создаваемой БД"
                name="database"
                value={details.database}
                disabled
                onChange={handleChange}
                margin="normal"
                fullWidth
            />
            <TextField
                label="Имя пользователя"
                name="dbUsername"
                value={details.dbUsername}
                disabled
                onChange={handleChange}
                margin="normal"
                fullWidth
            />
            <FormGroup>
                <FormControlLabel
                    control={
                        <Switch
                            checked={details.enableSuperuserAccess}
                            disabled
                            onChange={handleChange}
                            name="enableSuperuserAccess"
                        />
                    }
                    label="Активировать Superuser Access"
                />
                {/* <FormControlLabel
                    control={<Switch checked={formData.enableBackup} onChange={handleChange} name="enableBackup" />}
                    label="Enable Backup"
                /> */}
            </FormGroup>
            {details.enableSuperuserAccess && (
                <TextField
                    label="Имя пользователя Superuser"
                    name="dbUSuperuser"
                    value="postgres"
                    disabled
                    onChange={handleChange}
                    margin="normal"
                    fullWidth
                />
            )}
            {/* <TextField
                label="Backup Retention Policy"
                name="backupRetentionPolicy"
                type="number"
                value={formData.backupRetentionPolicy}
                onChange={handleChange}
                margin="normal"
                fullWidth
            /> */}
            {/* <Button type="submit" variant="contained" color="primary" style={{ marginTop: "20px" }}>
                Submit
            </Button> */}
        </form>
    );
};

export default PostgresConfig;
