import { DialogContentText, IconButton, Button, Stack } from "@mui/material";
import CodeBlock from "../CodeBlock";
import "./style.css";

const PostgresInfoTab = ({ project, config }) => (
    <div className="postgres-details">
        <Stack spacing={2} direction="row">
            <Button
                variant="outlined"
                color="info"
                startIcon={<i className="fa-solid fa-circle-info" />}
                href="https://docs.amvera.ru/databases/postgreSQL.html"
                target="_blank"
                rel="noreferrer"
            >
                Подключение к БД
            </Button>
            <Button
                variant="outlined"
                color="info"
                startIcon={<i className="fa-solid fa-circle-info" />}
                href="https://docs.amvera.ru/databases/postgreSQL.html#pgadmin"
                target="_blank"
                rel="noreferrer"
            >
                Деплой pgAdmin
            </Button>
        </Stack>

        <div className="info-tab__block">
            <DialogContentText>
                Для доступа к базе данных из других проектов Амвера достаточно указать одно из трех доменных имен:
            </DialogContentText>
            <ol className="info-tab__block_list">
                <li>
                    <p>Для чтения/записи:</p>
                    <CodeBlock>
                        amvera-{project.ownerName}-cnpg-{project.slug}-rw
                    </CodeBlock>
                </li>
                <li>
                    <p>Только для чтения в режиме hot standby:</p>
                    <CodeBlock>
                        amvera-{project.ownerName}-cnpg-{project.slug}-ro
                    </CodeBlock>
                </li>
                <li>
                    <p>Для чтения с произвольной из развернутых нод:</p>
                    <CodeBlock>
                        amvera-{project.ownerName}-cnpg-{project.slug}-r
                    </CodeBlock>
                </li>
            </ol>
        </div>
    </div>
);

export default PostgresInfoTab;
