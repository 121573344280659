import { paramsToQueryString } from "../../utils";
import Endpoint from "./Endpoint";

class BillingUlEndpoint extends Endpoint {
    getUlAccount() {
        return this.http.get(`${this.address}/account`);
    }

    getUlDocuments({ from = null, to = null, page = 0, size = 10, sort = [] }) {
        const body = {
            pages: {
                filter: { from, to, page, size },
                sort
            }
        };

        return this.http.post(`${this.address}/documents`, body);
    }

    downloadUlDocument({ id }) {
        return this.http.get(`${this.address}/document/${id}`);
    }

    printDocuments({ ids = [] }) {
        return this.http.post(`${this.address}/print_documents`, {
            ids
        });
    }

    orderReconciliationReport({ from, to }) {
        return this.http.post(`${this.address}/reconciliation_report`, {
            from,
            to
        });
    }

    sendByEmail({ documentId, email }) {
        return this.http.post(`${this.address}/email`, {
            documentId,
            email
        });
    }

    bill({ amount }) {
        return this.http.post(`${this.address}/bill`, { amount });
    }

    updateAccount({
        id,
        fullName,
        shortName,
        inn,
        kpp,
        address,
        ogrn,
        bic,
        beneficiaryAccount,
        correspondentAccount,
        additionalMail,
        additionalPhoneNumber
    }) {
        return this.http.post(`${this.address}/account`, {
            id,
            fullName,
            shortName,
            inn,
            kpp,
            address,
            ogrn,
            bic,
            beneficiaryAccount,
            correspondentAccount,
            additionalMail,
            additionalPhoneNumber
        });
    }
}

export default BillingUlEndpoint;
