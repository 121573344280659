// eslint-disable-next-line no-unused-vars
import React from "react";
import ReactDOM from "react-dom/client";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./index.css";

Sentry.init({
    dsn: "https://9cdab8f0c5ec456db217c4fed75afde1@sentry.amvera.ru/9",
    integrations: [new BrowserTracing()],
    environment: process.env.REACT_APP_ENV,
    tracesSampleRate: 1.0
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
