import { CircularProgress } from "@mui/material";
import { useServices } from "../../../../DI/hooks";

export function DbStatus({ status }) {
    const { decodeBuildStatus } = useServices();
    return (
        <>
            {(status === "CREATING" || status === "BUILD_STARTED") && <CircularProgress size="1em" color="inherit" />}
            {decodeBuildStatus(status)}
        </>
    );
}
