import { createContext, useContext } from "react";

export const MySQLDetailsContext = createContext(null);

export const useMySQLDetailsContext = () => {
    const context = useContext(MySQLDetailsContext);
    if (!context) {
        throw new Error("useMySQLDetailsContext must be used within the MySQLDetailsContext");
    }
    return context;
};
