import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    account: {
        id: "",
        fullName: "",
        shortName: "",
        inn: "",
        kpp: "",
        address: "",
        ogrn: "",
        bic: "",
        correspondentAccount: "",
        additionalMail: "",
        additionalPhoneNumber: "",
        beneficiaryAccount: ""
    },
    documents: [],
    error: null,
    loading: true
};

export const billingUlSlice = createSlice({
    name: "billing",
    initialState,
    reducers: {
        setAccount: (state, action) => {
            state.account = action.payload;
            state.error = null;
            state.loading = false;
        },
        setDocuments: (state, action) => {
            state.documents = action.payload;
            state.error = null;
            state.loading = false;
        },
        setError: (state, action) => {
            state.error = action.payload;
            state.loading = false;
        },
        setLoading: state => {
            state.loading = true;
        }
    }
});

export const { setAccount, setDocuments, setError, setLoading } = billingUlSlice.actions;

export default billingUlSlice.reducer;
