import { useCallback, useState } from "react";
import { TextField, FormControlLabel, Checkbox, Grid } from "@mui/material";

const PostgresForm = ({ postgresFormData, change }) => {
    const ENGLISH_LOWER_CASE_ONLY = /^[a-z]+$/;
    const [instances, setInstances] = useState(1);
    const [dbName, setDbName] = useState("");
    const [dbUserame, setDbUsername] = useState("");
    const [enableSuperuser, setEnableSuperuser] = useState(false);
    const [superuserPassword, setSuperuserPassword] = useState("");

    const handleChange = useCallback(
        e => {
            const { name, value, type, checked } = e.target;
            change(prevFormData => ({
                ...prevFormData,
                [name]: type === "checkbox" ? checked : value
            }));
        },
        [change]
    );

    const resetSuperuserPassword = useCallback(() => {
        change(prevFormData => ({
            ...prevFormData,
            superuserPassword: ""
        }));
    }, [change]);

    const handleInstancesChange = useCallback(
        e => {
            const value = parseInt(e.target.value);
            if (value <= 0) {
                setInstances(1);
                return;
            }
            if (e.target.value > 3) {
                setInstances(3);
                return;
            }
            setInstances(value);
            handleChange(e);
        },
        [handleChange]
    );

    const focusOutHandleInstances = useCallback(
        e => {
            if (e.target.value === "") {
                setInstances(1);
                handleChange(e);
            }
        },
        [handleChange]
    );

    const handledDbNameChange = useCallback(
        e => {
            const { value } = e.target;
            if (value !== "" && !ENGLISH_LOWER_CASE_ONLY.test(value)) {
                return;
            }
            setDbName(value);
            handleChange(e);
        },
        [handleChange]
    );

    const handledDbUsernameChange = useCallback(
        e => {
            const { value } = e.target;
            if (value !== "" && !ENGLISH_LOWER_CASE_ONLY.test(value)) {
                return;
            }
            setDbUsername(value);
            handleChange(e);
        },
        [handleChange]
    );

    const handleEnableSuperuser = useCallback(
        e => {
            setEnableSuperuser(e.target.checked);
            if (!e.target.checked) {
                setSuperuserPassword("");
                resetSuperuserPassword();
            }
            handleChange(e);
        },
        [handleChange]
    );

    const handleSuperuserPassword = useCallback(
        e => {
            setSuperuserPassword(e.target.value);
            handleChange(e);
        },
        [handleChange]
    );

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        label="Postgres Version"
                        name="postgresVersion"
                        // value={postgresFormData.postgresVersion}
                        value="cloudnative-pg:1.22.1"
                        disabled
                        onChange={handleChange}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        label="Имя создаваемой БД"
                        name="database"
                        value={dbName}
                        onChange={handledDbNameChange}
                        required
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        label="Имя пользователя"
                        name="dbUsername"
                        value={dbUserame}
                        onChange={handledDbUsernameChange}
                        required
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        label="Пароль пользователя"
                        name="dbPassword"
                        type="password"
                        value={postgresFormData.dbPassword}
                        onChange={handleChange}
                        required
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        label="Размер кластера СУБД"
                        name="instances"
                        type="number"
                        value={instances}
                        onChange={handleInstancesChange}
                        onBlur={focusOutHandleInstances}
                        InputProps={{ inputProps: { min: 1, max: 3 } }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                name="enableSuperuserAccess"
                                checked={enableSuperuser}
                                onChange={handleEnableSuperuser}
                            />
                        }
                        label="Активировать Superuser Access"
                    />
                </Grid>
                {enableSuperuser && (
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label="Пароль для Superuser"
                            name="superuserPassword"
                            type="password"
                            value={superuserPassword}
                            onChange={handleSuperuserPassword}
                        />
                    </Grid>
                )}
            </Grid>
        </>
    );
};

export default PostgresForm;
