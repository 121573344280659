import { Box, Breadcrumbs, Button, Stack, Typography } from "@mui/material";
import { useCallback, useState } from "react";
import { useServices } from "../../../DI/hooks";
import InternalLink from "../../Elements/InternalLink";
import ProjectList from "../../Elements/ProjectList";
import ProjectCreateDialog from "../../Elements/ProjectCreateDialog";

const ProjectsPage = () => {
    const [createDialogOpened, setCreateDialogOpened] = useState(false);

    const [createName, setCreateName] = useState("");
    const [createTariffId, setCreateTariffId] = useState(-1);
    const [createLoading, setCreateLoading] = useState(false);

    const [updateSeq, setUpdateSeq] = useState(0);

    const handleOpenCreateDialog = useCallback(() => {
        setCreateDialogOpened(true);
    }, []);

    const handleCloseCreateDialog = useCallback(() => {
        if (!createLoading) setCreateDialogOpened(false);
        setCreateName("");
        setCreateTariffId(-1);
    }, [createLoading]);

    return (
        <>
            <Stack spacing={2}>
                <Breadcrumbs>
                    <InternalLink underline="hover" color="inherit" to="/">
                        Главная
                    </InternalLink>
                    <Typography color="text.primary">Проекты</Typography>
                </Breadcrumbs>
                <Box sx={{ display: "flex" }}>
                    <Typography variant="h5" sx={{ flexGrow: 1 }}>
                        Мои проекты
                    </Typography>
                    <Button
                        variant="contained"
                        startIcon={<i className="fa-solid fa-plus-circle" />}
                        onClick={handleOpenCreateDialog}
                    >
                        Создать
                    </Button>
                </Box>
                <ProjectList key={updateSeq} onCreateClick={handleOpenCreateDialog} />
            </Stack>
            <ProjectCreateDialog
                open={createDialogOpened}
                onClose={handleCloseCreateDialog}
                setUpdateSeq={setUpdateSeq}
            />
        </>
    );
};

export default ProjectsPage;
