import { IconButton } from "@mui/material";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { useCallback, useState } from "react";
import ConfirmationDialog from "../../../../Elements/ConfirmationDialog";
import useDeleteBackup from "../../api/useDeleteBackup";

export function DeleteBackupButton({ backupName, removeBackup, deleteBackup, isDisabled }) {
    const [dialogOpen, setDialogOpen] = useState(false);
    const { deleteLoading, handleDeleteBackup } = useDeleteBackup(removeBackup, deleteBackup);

    const handleDialogClose = useCallback(() => {
        setDialogOpen(false);
    }, [setDialogOpen]);

    const handleDialogOpen = useCallback(() => {
        setDialogOpen(true);
    }, [setDialogOpen]);

    const onConfirm = useCallback(() => {
        handleDeleteBackup(backupName).then(handleDialogClose);
    }, [handleDeleteBackup, handleDialogClose, backupName]);

    return (
        <>
            <IconButton disabled={isDisabled} onClick={handleDialogOpen}>
                <DeleteOutlineOutlinedIcon color="primary" />
            </IconButton>
            <ConfirmationDialog
                loading={deleteLoading}
                open={dialogOpen}
                onClose={handleDialogClose}
                title="Вы действительно хотите удалить бэкап?"
                onClickConfirm={onConfirm}
            />
        </>
    );
}
