import React, { useState } from "react";
import { useDropzone } from "react-dropzone";
import { isMobile } from "react-device-detect";
import { Button, LinearProgress, TextField, Paper, Typography, CircularProgress, Stack } from "@mui/material";
import { CloudUpload } from "@mui/icons-material";
import { useServices } from "../../../DI/hooks";

const UploadFiles = ({ projectSlug, directory, path, setIsUploaded }) => {
    const [progress, setProgress] = useState(0);
    const [uploadedFiles, setUploadedFiles] = useState("");
    const [commitMessage, setCommitMessage] = useState("Initial commit");
    const [loading, setLoading] = useState(false);
    const { fileService } = useServices();

    const handleUploadResult = data => {
        if (data && data.length > 0) {
            setUploadedFiles(data.map(file => file).join(", "));
        } else {
            setUploadedFiles("Произошла ошибка загрузки");
        }
        setIsUploaded(true);
        setLoading(false);
    };

    const onDrop = acceptedFiles => {
        setLoading(true);
        const formData = new FormData();
        formData.append("path", path);
        acceptedFiles.forEach(file => {
            formData.append("files", file, file.path);
        });
        setUploadedFiles("");
        switch (directory) {
            case "Code":
                if (commitMessage) {
                    formData.append("commitMessage", commitMessage);
                    fileService
                        .upload(formData, `/file-storage/userdata/${projectSlug}/uploadCode`, setProgress)
                        .then(response => handleUploadResult(response?.data));
                }
                break;
            case "Data":
                fileService
                    .upload(formData, `/file-storage/userdata/${projectSlug}/uploadData`, setProgress)
                    .then(response => handleUploadResult(response?.data));
                break;
        }
    };

    const handleCommitChange = event => {
        setCommitMessage(event.target.value);
    };

    const { getRootProps, getInputProps, open, acceptedFiles } = useDropzone({
        onDrop,
        noClick: true,
        noKeyboard: true
    });

    const handleUpload = e => {
        const file = e.target.files;
        onDrop([...file]);
    };

    return (
        <div id="upload_id">
            {directory === "Code" && (
                <TextField
                    required
                    fullWidth
                    type="text"
                    label="Commit message"
                    value={commitMessage}
                    onChange={handleCommitChange}
                    style={{ marginBottom: "10px", marginTop: "10px" }}
                />
            )}

            <Paper elevation={3} style={{ padding: "16px", minHeight: "250px" }}>
                <Stack spacing={2}>
                    <div
                        {...getRootProps()}
                        className="dropzone"
                        style={{
                            padding: "20px",
                            border: "2px dashed #ccc",
                            minHeight: "200px",
                            textAlign: "center",
                            cursor: "pointer"
                        }}
                    >
                        <input {...getInputProps()} />
                        <Typography variant="h6" gutterBottom>
                            Для загрузки папки/нескольких файлов перетащите их в эту зону
                        </Typography>
                        {loading && (
                            <div className="circular-progress-container">
                                <CircularProgress />
                            </div>
                        )}
                    </div>

                    {isMobile ? (
                        <Stack
                            justifyContent="start"
                            alignItems="center"
                            direction={{ xs: "column", sm: "row" }}
                            spacing={1}
                        >
                            <Button variant="contained" color="primary" startIcon={<CloudUpload />} onClick={open}>
                                Загрузка только файла
                            </Button>

                            {/*
                        <Button
                            component="label"
                            variant="contained"
                            color="primary"
                            startIcon={<DriveFolderUpload />}
                            sx={{ flex: "auto" }}
                            fullWidth
                        >
                            Загрузка только папки
                            <input
                                type="file"
                                hidden
                                onChange={handleUpload}
                                multiple
                                webkitdirectory=""
                                // mozdirectory=""
                                // directory=""
                            />
                        </Button>
                        */}
                        </Stack>
                    ) : null}

                    {progress > 0 && (
                        <div style={{ marginTop: "20px" }}>
                            <LinearProgress
                                variant="determinate"
                                value={progress}
                                color="primary"
                                sx={{ height: "10px" }} // Make the progress line more bold
                            />
                            <Typography variant="body1" color="textSecondary">
                                {`Загружено ${progress}%`}
                            </Typography>
                            <Typography variant="body1" color="textPrimary">
                                {uploadedFiles}
                            </Typography>
                        </div>
                    )}
                </Stack>
            </Paper>
        </div>
    );
};

export default UploadFiles;
