import { Box, Button, Stack } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useServices } from "../../../../../DI/hooks";
import { setAccount, setError, setLoading } from "../../../../../Store/BillingUlSlice";
import { FormInputText } from "../../../FormInputText/FormInputText";

const requiredErrorText = "Это обязательное поле.";

const AccountDetails = () => {
    const { api } = useServices();
    const billing = useSelector(state => state.billingUl);
    const dispatch = useDispatch();

    const { handleSubmit, reset, control } = useForm({
        defaultValues: {
            fullName: "",
            shortName: "",
            inn: "",
            kpp: "",
            ogrn: "",
            beneficiaryAccount: "",
            bic: "",
            additionalMail: "",
            additionalPhoneNumber: ""
        }
    });

    useEffect(() => {
        dispatch(setLoading());
        api.billingUl
            .getUlAccount()
            .then(data => data.json())
            .then(data => dispatch(setAccount(data)))
            .catch(err => dispatch(setError(err)));
    }, [api, dispatch]);

    useEffect(() => {
        const account = billing?.account;

        reset({
            fullName: account?.fullName ?? "",
            shortName: account?.shortName ?? "",
            inn: account?.inn ?? "",
            kpp: account?.kpp ?? "",
            ogrn: account?.ogrn ?? "",
            beneficiaryAccount: account?.beneficiaryAccount ?? "",
            bic: account?.bic ?? "",
            additionalMail: account?.additionalMail ?? "",
            additionalPhoneNumber: account?.additionalPhoneNumber ?? ""
        });
    }, [reset, billing]);

    if (billing.error != null) {
        console.error(billing.error);
    }

    const updateAccount = data => {
        api.billingUl.updateAccount({
            ...billing.account,
            ...data
        });
    };

    return (
        <Box component="form" onSubmit={handleSubmit(updateAccount)}>
            <Stack spacing={2} width={{ xs: "auto", sm: "60%" }}>
                <Stack spacing={2}>
                    <FormInputText
                        name="shortName"
                        control={control}
                        required={requiredErrorText}
                        label="Краткое название юридического лица"
                    />
                    <FormInputText name="fullName" label="Полное название юридического лица" control={control} />
                    <FormInputText name="inn" control={control} required={requiredErrorText} label="ИНН" />
                    <FormInputText name="kpp" label="КПП" control={control} />
                    <FormInputText name="ogrn" label="ОГРН" control={control} />
                    <FormInputText name="beneficiaryAccount" label="Счет" control={control} />
                    <FormInputText name="bic" label="БИК банка" control={control} />
                    <FormInputText
                        name="additionalMail"
                        label="Дополнительная почта для связи"
                        control={control}
                        type="email"
                        pattern="[^@\s]+@[^@\s]+"
                        validationErrorText="Введите корректный e-mail."
                    />
                    <FormInputText
                        name="additionalPhoneNumber"
                        label="Дополнительный телефон для связи"
                        control={control}
                        type="tel"
                        pattern={/^((8|\+7)[- ]?)?(\(?\d{3}\)?[- ]?)?[\d\- ]{7,10}$/}
                        validationErrorText="Введите корректный номер телефона."
                    />
                </Stack>
                <Button
                    size="large"
                    variant="contained"
                    type="submit"
                    sx={{ alignSelf: { xs: "stretch", sm: "flex-end" } }}
                >
                    Сохранить
                </Button>
            </Stack>
        </Box>
    );
};

export default AccountDetails;
