import { SvgIcon } from "@mui/material";

const Circle = ({ size, sx }) => {
    return (
        <SvgIcon sx={{ fontSize: size, ...sx }}>
            <circle cx="12" cy="12" r="12" />
        </SvgIcon>
    );
};

export default Circle;
