import { paramsToQueryString } from "../../utils";
import Endpoint from "./Endpoint";

class PaymentEndpoint extends Endpoint {
    getRecurrentPayment() {
        return this.http.get(`${this.address}/recurrent_payment`);
    }

    deleteRecurrentPayment() {
        return this.http.delete(`${this.address}/recurrent_payment`);
    }

    listPayments(fromDateTime = null, toDateTime = null, page = 0, pageSize = 50) {
        const params = {
            from: fromDateTime?.format(),
            to: toDateTime?.format(),
            page: page,
            size: pageSize
        };
        return this.http.get(`${this.address}/payments${paramsToQueryString(params)}`);
    }
}

export default PaymentEndpoint;
