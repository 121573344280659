import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from "@mui/material";

const DefaultDomainDialog = ({ open, onSave, onClose, active, activeButton }) => (
    <Dialog open={open} onClose={onClose}>
        <DialogTitle>Дефолтное доменное имя </DialogTitle>
        <DialogContent dividers>
            <DialogContentText pb={2}>
                {active ? "Деактивировать дефолтное доменное имя?" : "Активировать дефолтное доменное имя?"}
            </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ marginTop: "10px", marginBottom: "10px", justifyContent: "space-around" }}>
            <Button variant="outlined" onClick={onClose}>
                Отмена
            </Button>
            <Button variant="contained" onClick={onSave} disabled={!activeButton}>
                Подтвердить
            </Button>
        </DialogActions>
    </Dialog>
);

export default DefaultDomainDialog;
