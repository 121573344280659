import { useCallback, useEffect, useMemo, useState } from "react";
import { useAuth } from "react-oidc-context";
import { useDispatch } from "react-redux";
import { useConfig } from "../Configuration/hooks";
import HttpService from "../Services/HttpService";
import { setBalance } from "../Store/BalanceSlice";
import {
    applyCurrentBuildStartedEvent,
    applyCurrentRepositoryEvent,
    applyCurrentTaskStatusEvent,
    applyCurrentRunStatusEvent,
    setInstances
} from "../Store/CurrentProjectSlice";
import {
    applyBuildStartedEvent,
    applyRepositoryEvent,
    applyTaskStatusEvent,
    applyRunStatusEvent
} from "../Store/ProjectsSlice";
import { EventContext } from "../Context/EventContext";
import { setMessage } from "../Store/DbEventStreamSlice";
import { setDataBackupMessage } from "../Store/DataBackupStreamSlice";

const EventProcessor = ({ children }) => {
    const config = useConfig();
    const auth = useAuth();
    const dispatch = useDispatch();

    const [eventSource, setEventSource] = useState(null);
    const [userLogsErrors, setUserLogsErrors] = useState(null);
    const [activeMessage, setActiveMessage] = useState(null);

    const http = useMemo(() => new HttpService(auth, config.notificationsApi), [auth, config.notificationsApi]);

    const contextValue = useMemo(
        () => ({
            userLogsErrors,
            activeMessage
        }),
        [userLogsErrors, activeMessage]
    );

    const handleEvent = useCallback(
        event => {
            const data = JSON.parse(event.data);
            switch (data.type) {
                case "repository":
                    dispatch(applyRepositoryEvent(data.payload));
                    dispatch(applyCurrentRepositoryEvent(data.payload));
                    break;
                case "build-start":
                    dispatch(applyBuildStartedEvent(data.payload));
                    dispatch(applyCurrentBuildStartedEvent(data.payload));
                    break;
                case "task":
                    dispatch(applyTaskStatusEvent(data.payload));
                    dispatch(applyCurrentTaskStatusEvent(data.payload));
                    break;
                case "balance-change":
                    dispatch(setBalance(data.payload.balance));
                    break;
                case "instances-change":
                    dispatch(setInstances(data.payload));
                    break;
                case "service-run-status":
                    dispatch(applyRunStatusEvent(data.payload));
                    dispatch(applyCurrentRunStatusEvent(data.payload));
                    break;
                case "user-logs-errors":
                    setUserLogsErrors(data.payload);
                    break;
                case "postgres-backup-status":
                    dispatch(setMessage(data));
                    break;
                case "data-backup-status":
                    dispatch(setDataBackupMessage(data));
                    break;
                case "broadcast-message":
                    setActiveMessage(data.payload);
                    break;
                default:
            }
        },
        [dispatch]
    );

    useEffect(() => {
        let newEventSource;
        http.post("/events", {})
            .then(response => response.json())
            .then(data => {
                const token = data.sessionId;
                newEventSource = new EventSource(`${config.notificationsApi}/events?session=${token}`);
                newEventSource.onmessage = handleEvent;

                setEventSource(newEventSource);

                console.log("Connected to event source");
            })
            .catch(err => console.error(err));

        return () => {
            if (newEventSource) {
                newEventSource.close();
                console.log("Disconnected from the event source");
            }
        };
    }, [http, config, handleEvent]);

    useEffect(() => {
        http.get("/v1/message/active")
            .then(response => (response.ok ? response.json() : Promise.reject(response)))
            .then(data => {
                setActiveMessage(data);
            })
            .catch(err => {
                console.error(err);
                if (err.status === "404") {
                    setActiveMessage({});
                }
            });
    }, [http, config]);

    // useEffect(
    //     () => () => {
    //         eventSource?.close();
    //     },
    //     [eventSource]
    // );

    return <EventContext.Provider value={contextValue}>{children}</EventContext.Provider>;
};

export default EventProcessor;
