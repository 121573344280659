import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    data: [],
    loading: true,
    error: undefined
};

export const tariffsSlice = createSlice({
    name: "tariffs",
    initialState,
    reducers: {
        setTariffs: (state, action) => {
            state.data = action.payload;
            state.loading = false;
            state.error = undefined;
        },
        setLoading: state => {
            state.loading = true;
            state.error = undefined;
        },
        setError: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        }
    }
});

export const { setTariffs, setLoading, setError } = tariffsSlice.actions;

export default tariffsSlice.reducer;
