import HttpService from "./HttpService";
import API from "./API";
import decodeBuildStatus from "./BuildStatusDecoder";
import FileService from "./FileService";

export const registerServices = (config, auth) => {
    const http = new HttpService(auth, config.api);
    const api = new API(http);
    const fileService = new FileService(auth, config.api);

    return {
        config,
        auth,
        http,
        api,
        fileService,
        decodeBuildStatus
    };
};
