import { useCallback, useState } from "react";
import useStatusApi from "../../../../Hooks/useStatusApi";

const useDeleteBackup = (removeBackup, endpoint) => {
    const [success, error] = useStatusApi();
    const [deleteLoading, setDeleteLoading] = useState(false);

    const handleDeleteBackup = useCallback(
        async backupName => {
            setDeleteLoading(true);
            try {
                const res = await endpoint(backupName);
                if (!res.ok) throw Error(res.status);

                removeBackup(backupName);
                success("Бэкап успешно удален.");
            } catch (e) {
                error(e.message, "Произошла ошибка в удалении бэкапа.");
            } finally {
                setDeleteLoading(false);
            }
        },
        [endpoint, error, success, removeBackup]
    );

    return { deleteLoading, handleDeleteBackup };
};

export default useDeleteBackup;
