import { Stack, Typography } from "@mui/material";
import { useMemo } from "react";
import DeleteUserButton from "./DeleteUserButton";
import StatusDisplay from "../../SingleMySQLPage/components/StatusDisplay";
import useIsDisabled from "../../SingleMySQLPage/hooks/useIsDisabled";

export default function UserController({ username, status, slug, dbName, setDetails }) {
    const { isDisabled } = useIsDisabled();
    const isDeleteUserDisabled = useMemo(() => status === "CREATING" || isDisabled, [status, isDisabled]);

    return (
        <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Stack direction="row" spacing={1} alignItems="center">
                <Typography>{username}</Typography>
                <StatusDisplay status={status} />
            </Stack>
            <DeleteUserButton
                disabled={isDeleteUserDisabled}
                slug={slug}
                dbName={dbName}
                setDetails={setDetails}
                username={username}
            />
        </Stack>
    );
}
