import { useCallback, useState } from "react";
import useStatusApi from "./useStatusApi";

const usePost = (endpoint, doAlerts = false) => {
    const [success, error] = useStatusApi();
    const [loading, setLoading] = useState(false);

    const handlePost = useCallback(
        async (params, successText = "Успешно создано.", errorText = "Произошла ошибка.") => {
            setLoading(true);

            try {
                const res = await endpoint(params);
                if (!res.ok) throw Error(res.status);

                if (doAlerts) success(successText);
            } catch (e) {
                if (doAlerts) error(e.message, errorText);
            } finally {
                setLoading(false);
            }
        },
        [doAlerts, success, error, endpoint]
    );

    return [loading, handlePost];
};

export default usePost;
