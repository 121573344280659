import { Icon, IconButton, Tooltip } from "@mui/material";

const ProjectRebuildButton = ({ removed = false, disabled = false, onClick }) => {
    if (removed) return null;

    return (
        <Tooltip arrow disableInteractive title="Пересобрать проект">
            <IconButton size="medium" disabled={disabled} onClick={onClick}>
                <Icon className="fa-solid fa-screwdriver-wrench" />
            </IconButton>
        </Tooltip>
    );
};

export default ProjectRebuildButton;
