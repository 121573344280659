import { Divider, Drawer, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Toolbar } from "@mui/material";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import { useLocation } from "react-router-dom";
import { useAuth } from "react-oidc-context";

const items = [
    { label: "Пополнение баланса", icon: <AccountBalanceWalletIcon />, href: "/billing/balance" },
    { label: "Юридическим лицам (beta)", icon: <AccountBoxIcon />, href: "/billing/documents" }
];

const drawerWidth = 320;

const BillingDrawer = () => {
    const { pathname } = useLocation();
    const { user } = useAuth();

    const username = user.profile.preferred_username;

    const hideInMobile = {
        display: { xs: "none", sm: "block" }
    };

    const list = (
        <List>
            <Toolbar />
            <ListItem sx={hideInMobile}>Биллинг</ListItem>
            <Divider sx={hideInMobile} />
            {username ? (
                <>
                    <ListItem sx={hideInMobile}>{username}</ListItem>
                    <Divider sx={hideInMobile} />
                </>
            ) : null}
            {items.map(item => (
                <ListItem key={item.label} disablePadding>
                    <ListItemButton selected={pathname === item.href} href={item.href}>
                        <ListItemIcon>{item.icon}</ListItemIcon>
                        <ListItemText
                            primary={item.label}
                            sx={{
                                opacity: { xs: 0, sm: 1 }
                            }}
                        />
                    </ListItemButton>
                </ListItem>
            ))}
        </List>
    );

    return (
        <Drawer variant="permanent" anchor="left" PaperProps={{ sx: { width: { xs: 56, sm: drawerWidth } } }} open>
            {list}
        </Drawer>
    );
};

export default BillingDrawer;
