import { Typography } from "@mui/material";
import InternalLink from "../../Elements/InternalLink";
import Nothing, { NothingContainer } from "../../Elements/Nothing";

const NotFoundPage = () => (
    <NothingContainer>
        <Nothing>
            <Nothing.Item>
                <Typography variant="h4" color="darkgrey" sx={{ textAlign: "center", fontWeight: "bold" }}>
                    404 Not Found
                </Typography>
                <Typography variant="h1" color="darkgrey" sx={{ textAlign: "center", py: 2 }}>
                    <i className="fa-solid fa-magnifying-glass" />
                </Typography>
                <Typography variant="body1" color="darkgrey">
                    Здесь ничего нет. Вы можете открыть свой <InternalLink to="/projects">список проектов</InternalLink>
                </Typography>
            </Nothing.Item>
        </Nothing>
    </NothingContainer>
);

export default NotFoundPage;
