import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { FormProvider, useForm } from "react-hook-form";
import LoadingButton from "@mui/lab/LoadingButton";
import { useCallback } from "react";

const FormDialog = ({
    loading,
    title,
    description,
    open,
    onClickConfirm,
    onClose,
    children,
    cancelButtonText = "Отмена",
    submitButtonText = "Создать"
}) => {
    const methods = useForm();

    const handleSubmit = data => {
        onClickConfirm(data);
        methods.reset();
    };

    const handleClose = useCallback(() => {
        methods.reset();
        onClose();
    }, []);

    return (
        <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
            <DialogTitle sx={{ padding: "16px 24px 0px" }}>{title}</DialogTitle>
            <FormProvider {...methods}>
                <form onSubmit={methods.handleSubmit(handleSubmit)}>
                    <DialogContent>
                        <DialogContentText>{description}</DialogContentText>
                        {children}
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose}>{cancelButtonText}</Button>
                        <LoadingButton loading={loading} type="submit">
                            {submitButtonText}
                        </LoadingButton>
                    </DialogActions>
                </form>
            </FormProvider>
        </Dialog>
    );
};

export default FormDialog;
