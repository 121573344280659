import { useCallback, useState } from "react";
import { useSnackbar } from "notistack";
import { SNACKBAR_TYPE } from "../../../../constants";
import { useServices } from "../../../../DI/hooks";

const useCreateDb = (setDetails, slug) => {
    const { enqueueSnackbar } = useSnackbar();
    const { api } = useServices();
    const [loading, setLoading] = useState(false);

    const handleAddNewDb = useCallback(
        newDb => {
            setDetails(prev => {
                return { ...prev, databases: [...prev.databases, newDb] };
            });
        },
        [setDetails]
    );

    const handleCreateDb = useCallback(
        (database, handleClose) => {
            setLoading(true);

            api.mysql
                .createDb({ slug, database })
                .then(res => (res.ok ? res.json() : Promise.reject(res.status)))
                .then(data => {
                    // setRes(data);
                    handleAddNewDb(data);

                    // enqueueSnackbar(`База данных успешно создана`, SNACKBAR_TYPE.SUCCESS);
                })
                .catch(e => enqueueSnackbar(`${e.message}: Ошибка создания базы данных`, SNACKBAR_TYPE.ERROR))
                .finally(() => {
                    setLoading(false);
                    handleClose();
                });
        },
        [slug, handleAddNewDb, enqueueSnackbar, api.mysql]
    );

    return [loading, handleCreateDb];
};

export default useCreateDb;
