export const dbCreate = (api, dbType, name, tariffId, dbFormData) => {
    const db = dbType === "mysql beta" ? "mysql" : dbType;

    if (!(db in api)) {
        throw new Error(`Нет такого типа: ${db}`);
    }
    return api[db].create({
        name,
        tariffId,
        ...dbFormData
    });
};
