import {
    Box,
    Checkbox,
    Container,
    FormControl,
    FormControlLabel,
    InputLabel,
    MenuItem,
    Select,
    Stack,
    TextField,
    Typography
} from "@mui/material";
import { useEffect, useState } from "react";
import { useServices } from "../../../DI/hooks";
import PostgresForm from "../PostgresForm";
import tooltips from "../../../constants/ConfigurationTab/configTooltipText.json";
import InfoToolTip from "../Tooltips/InfoTooltip";
import TooltipTextField from "../TooltipTextField";
import { MySQLForm } from "../../Pages/SingleDbPage/components";

const DbForm = ({ postresFormData, setPostgresFormData, formData, setFormData }) => {
    const { api } = useServices();
    const [dbs, setDbs] = useState({});
    const [selectedDb, setSelectedDb] = useState("");
    const [envVariables, setEnvVariables] = useState({});

    useEffect(() => {
        api.configurations
            .listAll()
            .then(response => (response.ok ? response.json() : Promise.reject()))
            .then(data => {
                const dbData = data?.availableParameters?.db;
                // TODO: percona temporary connemted
                // dbData["mysql beta"] = "mysql beta";
                const sortedDbs = Object.keys(dbData)
                    .sort()
                    .reduce((acc, key) => {
                        acc[key] = dbData[key];
                        return acc;
                    }, {});

                console.debug(sortedDbs);

                setDbs(sortedDbs);
            })
            .catch(error => console.log(error));
    }, []);

    const getEnvvars = selectedToolchain => {
        return new Promise((resolve, reject) => {
            const hasEnvVar = envVariables
                ? Object.keys(envVariables).some(envvar => envvar === selectedToolchain)
                : "";

            if (selectedDb !== null && !hasEnvVar && selectedToolchain !== "postgresql") {
                api.configurations
                    .getEnvvars("db", selectedToolchain)
                    .then(response => {
                        if (response.headers.get("content-type")) {
                            return response.json();
                        }
                        return null;
                    })
                    .then(data => {
                        if (data) setEnvVariables(prev => ({ ...prev, [selectedToolchain]: { ...data } }));
                        else setEnvVariables(prev => ({ ...prev, [selectedToolchain]: null }));

                        resolve(data);
                    })
                    .catch(error => {
                        console.log(error);
                        reject();
                    });
            } else resolve(envVariables[selectedToolchain]);
        });
    };

    const handleSelectChange = e => {
        const db = e.target.value;
        if (db === "mysql beta") {
            setSelectedDb(db);

            setFormData({
                meta: {
                    toolchain: "mysql beta"
                }
            });
            return;
        }

        const defaultFormData = {};
        const dbData = dbs[db];
        if (dbData) {
            for (const sectionName in dbData) {
                if (sectionName) {
                    const section = dbData[sectionName];
                    defaultFormData[sectionName] = {};
                    for (const fieldName in section) {
                        if (fieldName) {
                            const fieldData = section[fieldName];
                            if ("default" in fieldData && fieldData.default) {
                                defaultFormData[sectionName][fieldName] =
                                    fieldData.type === "Bool"
                                        ? JSON.parse(fieldData.default)
                                        : fieldData.type === "Map"
                                        ? {
                                              [fieldData.default.split(": ")[0]]: fieldData.default.split(": ")[1]
                                          }
                                        : fieldData.default;
                            }
                        }
                    }
                }
            }
        }
        defaultFormData.meta.environment = "db";
        defaultFormData.meta.toolchain = db;

        setFormData(defaultFormData);

        getEnvvars(db)
            .then(data => {
                if (data) {
                    defaultFormData.envvar = Object.entries(data).reduce((acc, [key, value]) => {
                        if (value?.default) acc[key] = { ...value, text: value?.default };
                        return acc;
                    }, {});
                }
                setSelectedDb(db);
                setFormData(defaultFormData);
            })
            .catch(error => console.log(error));
    };

    const handleFieldChange = (section, fieldName, value) => {
        if (value) {
            if (section === "envvar")
                setFormData(prevData => ({
                    ...prevData,
                    [section]: {
                        ...prevData[section],
                        [fieldName]: { text: value, ...envVariables[selectedDb][fieldName] }
                    }
                }));
            else
                setFormData(prevData => ({
                    ...prevData,
                    [section]: {
                        ...prevData[section],
                        [fieldName]: value
                    }
                }));
        } else {
            const copyOfSection = formData[section];

            console.log(copyOfSection);
            delete copyOfSection[fieldName];
            setFormData(prevData => ({
                ...prevData,
                [section]: copyOfSection
            }));
        }
    };

    const generateFormFields = () => {
        const dbData = envVariables[selectedDb]
            ? { ...dbs[selectedDb], envvar: { ...envVariables[selectedDb] } }
            : dbs[selectedDb];

        return (
            <>
                {Object.keys(dbData).map(section => (
                    <Box key={section} width="100%">
                        {dbData[section] !== null && (
                            <>
                                <Typography variant="h6" gutterBottom>
                                    {section}
                                </Typography>
                                {Object.entries(dbData[section]).map(([fieldName, fieldData]) => (
                                    <Box key={fieldName} mb={2}>
                                        {fieldData.type === "Bool" ? (
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        id={`${section}.${fieldName}`}
                                                        checked={dbData[section]?.[fieldName] || false}
                                                    />
                                                }
                                                label={fieldName}
                                            />
                                        ) : fieldData.type === "Map" ? (
                                            <Box>
                                                {Object.entries(dbData[section]?.[fieldName] || { "": "" }).map(
                                                    ([key, value], index) => (
                                                        <Box key={index} mb={2}>
                                                            <TextField
                                                                sx={{ width: "80%", mb: "16px" }}
                                                                type="text"
                                                                value={key}
                                                                label={`${fieldName} key`}
                                                            />
                                                            <TextField
                                                                sx={{ width: "80%" }}
                                                                type="text"
                                                                value={value}
                                                                label={`${fieldName} value`}
                                                                disabled={!key}
                                                            />
                                                        </Box>
                                                    )
                                                )}
                                            </Box>
                                        ) : (
                                            <TooltipTextField
                                                fullWidth
                                                type={fieldData.type === "Integer" ? "number" : "text"}
                                                id={`${section}.${fieldName}`}
                                                value={
                                                    formData[section]?.[fieldName]?.text ||
                                                    formData[section]?.[fieldName] ||
                                                    ""
                                                }
                                                onChange={e => handleFieldChange(section, fieldName, e.target.value)}
                                                disabled={
                                                    fieldData.blockedBy &&
                                                    formData[section]?.[fieldData.blockedBy || false]
                                                }
                                                label={fieldName}
                                                variant="outlined"
                                            >
                                                <InfoToolTip
                                                    title={
                                                        tooltips?.db?.[selectedDb]?.[section]?.[fieldName] ??
                                                        tooltips?.db?.[section]?.[fieldName]
                                                    }
                                                />
                                            </TooltipTextField>
                                        )}
                                    </Box>
                                ))}
                            </>
                        )}
                    </Box>
                ))}
            </>
        );
    };

    return (
        <>
            <Container maxWidth="xs">
                <Typography variant="h6" sx={{ mb: 4 }}>
                    Задайте параметры СУБД
                </Typography>
                <form>
                    <Stack justifyContent="center" alignItems="center">
                        <FormControl fullWidth sx={{ mb: 2 }}>
                            <InputLabel id="db-select-label">Выберите БД</InputLabel>
                            <Select
                                labelId="db-select-label"
                                id="db-select"
                                label="Выберите БД"
                                onChange={handleSelectChange}
                            >
                                {Object.keys(dbs).map(db => (
                                    <MenuItem key={db} value={db}>
                                        {db}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        {selectedDb === "postgresql" ? (
                            <PostgresForm postgresFormData={postresFormData} change={setPostgresFormData} />
                        ) : selectedDb === "mysql beta" ? (
                            <MySQLForm customDbForm setCustomDbForm />
                        ) : (
                            selectedDb && generateFormFields()
                        )}
                    </Stack>
                </form>
            </Container>
        </>
    );
};

export default DbForm;
