import { useCallback, useEffect, useMemo, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { useAuth } from "react-oidc-context";
import { useConfig } from "../../../../Configuration/hooks";
import HttpService from "../../../../Services/HttpService";
import { selectEventByType } from "../../../../Store/selectors/dbEventStreamSelecors";

const usePostgresEventStream = () => {
    const {
        project: { status }
    } = useSelector(state => state.currentProject, shallowEqual);

    const { payload: backupStatus } = useSelector(selectEventByType("postgres-backup-status"));

    const isPostgresDisabled = useMemo(() => status !== "RUNNING", [status]);

    return { backupStatus, isPostgresDisabled };
};

export default usePostgresEventStream;
