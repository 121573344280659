import { Chip } from "@mui/material";
import { DOCUMENT_STATUS } from "../../../../../../constants";

function StatusChip({ status }) {
    const getColor = () => {
        switch (status) {
            case "PAID":
                return "success";
            case "UNPAID":
                return "error";
            case "COMPLETE":
                return "success";
            default:
                return "default";
        }
    };

    return <Chip label={DOCUMENT_STATUS[status]} color={getColor()} size="small" />;
}

export default StatusChip;
