import { useMemo } from "react";
import ProjectRebuildButton from "../ProjectRebuildButton";
import ProjectRestartButton from "../ProjectRestartButton";
import ProjectStartStopButton from "../ProjectStartStopButton";

const ProjectControlButtons = ({
    disabled = false,
    requiredInstances,
    onClickRebuild,
    onClickRestart,
    onClickStartStop,
    status,
    serviceType
}) => {
    const isDisabled = useMemo(() => disabled || status === "BUILD_STARTED", [disabled, status]);
    const isRemoved = useMemo(() => serviceType === "pxc", [serviceType]);

    return (
        <>
            <ProjectStartStopButton
                disabled={disabled}
                status={status}
                requiredInstances={requiredInstances}
                onClick={onClickStartStop}
            />
            <ProjectRebuildButton removed={isRemoved} disabled={isDisabled} onClick={onClickRebuild} />
            <ProjectRestartButton removed={isRemoved} disabled={isDisabled} onClick={onClickRestart} />
        </>
    );
};

export default ProjectControlButtons;
