import { useEffect, useState } from "react";

import { useServices } from "../../../../DI/hooks";

import useStatusApi from "../../../../Hooks/useStatusApi";

const useGetMySQLDetails = slug => {
    const [_, error] = useStatusApi();
    const { api } = useServices();
    const [data, setData] = useState({});
    const [statusError, setStatusError] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        async function getDetails() {
            setLoading(true);
            try {
                const response = await api.mysql.getDetails(slug);
                if (!response.ok) {
                    throw Error(response.status);
                }

                const resData = await response.json();
                setData(resData);
            } catch (e) {
                setStatusError(e.message);
                error(e.message, "Произошла ошибка");
            } finally {
                setLoading(false);
            }
        }
        getDetails();

        return () => {};
    }, [slug, error]);

    return [data, statusError, loading, setData];
};

export default useGetMySQLDetails;
