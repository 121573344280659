import { useDispatch, useSelector } from "react-redux";
import { memo, useEffect, useMemo, useState } from "react";
import { Alert, Box, Breadcrumbs, Button, Stack, Tab, Tabs, Typography } from "@mui/material";
import { useParams } from "react-router-dom";
import { TabContext, TabPanel } from "@mui/lab";

import { useServices } from "../../../DI/hooks";

import SingleProjectPageSkeleton from "../SingleProjectPageSkeleton";
import { setCurrentProject, setNotFound, setLoading, setError } from "../../../Store/CurrentProjectSlice";
import InternalLink from "../../Elements/InternalLink";
import { DbInfoTab, DbProjectSettingsTab, DbStatus, InstanceController } from "./components";

import RunOutputTab from "../../Elements/RunOutputTab";

const SingleDbPage = memo(({ additionalTabs, db, scaling, doScale, deleteDb, handleStartPause }) => {
    const { api } = useServices();
    const { slug } = useParams();
    const dispatch = useDispatch();
    const { project, loading } = useSelector(state => state.currentProject);

    const [activeTab, setActiveTab] = useState("1");

    useEffect(() => {
        dispatch(setLoading());
        api.projects
            .getById(slug)
            .then(response => {
                if (response.status === 404) {
                    return Promise.resolve(null);
                }
                return response.json();
            })
            .then(data => {
                if (data != null) {
                    dispatch(setCurrentProject(data));
                    setCurrentProject(data);
                } else {
                    dispatch(setNotFound());
                    console.log("not found");
                }
            })
            .catch(err => dispatch(setError(err)));
    }, [api.projects, dispatch, slug]);

    const handleTabChange = (event, newValue) => {
        setActiveTab(newValue);
    };

    const lastTab = useMemo(() => additionalTabs.length + 3, [additionalTabs.length]);

    if (loading) return <SingleProjectPageSkeleton />;

    return (
        <Stack useFlexGap gap={2}>
            <Breadcrumbs aria-label="breadcrumb" sx={{ mx: { xs: 3, sm: 0 } }}>
                <InternalLink underline="hover" color="inherit" to="/">
                    Главная
                </InternalLink>
                <InternalLink underline="hover" color="inherit" to="/projects">
                    Проекты
                </InternalLink>
                <Typography color="text.primary">{project.name}</Typography>
            </Breadcrumbs>
            <Stack
                justifyContent={{ xs: "center", md: "space-between" }}
                alignItems="center"
                direction="row"
                flexWrap="wrap"
                useFlexGap
                gap={2}
            >
                <Stack spacing={2} alignItems="center" direction="row">
                    <Typography variant="h5">{project.name}</Typography>
                    <DbStatus status={project.status} />
                </Stack>
                <InstanceController
                    project={project}
                    scaling={scaling}
                    doScale={doScale}
                    onStartPause={handleStartPause}
                />
            </Stack>
            <Box width="100%">
                <TabContext value={activeTab}>
                    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                        <Tabs
                            textColor="inherit"
                            indicatorColor="secondary"
                            variant="scrollable"
                            scrollButtons
                            allowScrollButtonsMobile
                            value={activeTab}
                            onChange={handleTabChange}
                        >
                            <Tab label="Инфо" value="1" />
                            {additionalTabs.map(({ tabName, index }) => (
                                <Tab label={tabName} key={index} value={index} />
                            ))}
                            <Tab label="Лог БД" value={`${lastTab - 1}`} />
                            <Tab label="Настройки" value={`${lastTab}`} />
                        </Tabs>
                    </Box>

                    <TabPanel value="1">
                        <DbInfoTab
                            ownerName={project.ownerName}
                            slug={project.slug}
                            serviceType={project.serviceType}
                            db={db}
                        />
                    </TabPanel>
                    {additionalTabs.map(({ tabPanel, index }) => (
                        <TabPanel key={index} value={index}>
                            {tabPanel}
                        </TabPanel>
                    ))}
                    <TabPanel value={`${lastTab - 1}`}>
                        <Stack direction={{ xs: "column", md: "row" }} spacing={2} sx={{ marginBottom: "10px" }}>
                            <Alert severity="warning">Загрузка лога занимает до 3 минут</Alert>
                            <Button
                                variant="outlined"
                                color="info"
                                startIcon={<i className="fa-solid fa-circle-info" />}
                                href="https://docs.amvera.ru/general/faq.html"
                                target="_blank"
                                rel="noreferrer"
                            >
                                Наиболее частые ошибки
                            </Button>
                        </Stack>
                        <RunOutputTab project={project} />
                    </TabPanel>
                    <TabPanel value={`${lastTab}`}>
                        <DbProjectSettingsTab project={project} slug={slug} doScale={doScale} deleteDb={deleteDb} />
                    </TabPanel>
                </TabContext>
            </Box>
        </Stack>
    );
});

export default SingleDbPage;
