import { WebStorageStateStore } from "oidc-client-ts";

const commonConfig = {
    oidc: {
        automaticSilentRenew: true, // setting to false removes unnecessary rerenders
        userStore: new WebStorageStateStore({ store: window.localStorage })
    }
};

export default commonConfig;
