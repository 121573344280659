import { useCallback, useState } from "react";
import { IconButton } from "@mui/material";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import DeleteDialog from "./DeleteDialog";
import useDeleteUser from "../api/useDeleteUser";

export default function DeleteUserButton({ slug, dbName, setDetails, username, disabled }) {
    const [isDialog, setIsDialog] = useState(false);
    const [userLoading, userDelete] = useDeleteUser(slug, dbName, setDetails);

    const handleOpenDialog = useCallback(() => {
        setIsDialog(true);
    }, []);
    const handleCloseDialog = useCallback(() => {
        setIsDialog(false);
    }, []);

    const handleDeleteUser = () => {
        userDelete(username).then(handleCloseDialog);
    };

    return (
        <>
            <IconButton disabled={disabled} onClick={handleOpenDialog}>
                <DeleteOutlineOutlinedIcon color="primary" />
            </IconButton>
            <DeleteDialog
                open={isDialog}
                onClose={handleCloseDialog}
                type="user"
                onClick={handleDeleteUser}
                loading={userLoading}
            />
        </>
    );
}
