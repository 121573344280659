import { keyframes, Stack } from "@mui/material";
import Circle from "./Circle";

const growShrink = fillColor => keyframes`
  0%, 100% {
    transform: scale(1);
    fill: #E0E2DB; 
  }
  50% {
    transform: scale(1.5);
    fill: ${fillColor}; 
  }
`;

export const StatusCircles = ({ status }) => {
    const cards = [
        { id: 1, status: "GREEN", color: "#25DC59" },
        { id: 2, status: "YELLOW", color: "#FDCC63" },
        { id: 3, status: "RED", color: "#EF2C00" },
        { id: 4, status: "NONE", color: "#E0E2DB" }
    ];

    const selected = "12px";
    const deselected = "8px";

    return (
        <Stack>
            <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={0.5}
                sx={{
                    "&>*:nth-child(1)": { animationDelay: "0s" },
                    "&>*:nth-child(2)": { animationDelay: "0.3s" },
                    "&>*:nth-child(3)": { animationDelay: "0.6s" },
                    "&>*:nth-child(4)": { animationDelay: "0.9s" }
                }}
            >
                {cards.map(card => (
                    <Circle
                        key={card.id}
                        size={status === card.status && status !== "NO_DATA" ? selected : deselected}
                        sx={{
                            "animation":
                                status === "NO_DATA" ? `${growShrink(card.color)} 1.5s infinite linear alternate` : "",
                            "&": {
                                fill: status === card.status ? card.color : ""
                            }
                        }}
                    />
                ))}
            </Stack>
        </Stack>
    );
};
