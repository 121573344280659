import { forwardRef, useCallback, useMemo, useState } from "react";
import { Card, CardActions, Collapse, IconButton, Paper, Typography, Link, Stack } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";

import { SnackbarContent, useSnackbar } from "notistack";
import CloseIcon from "@mui/icons-material/Close";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { styled } from "@mui/material/styles";
import { useSelector } from "react-redux";
import _ from "lodash";
import { selectProjectByName } from "../../../Store/selectors/projectSelectors";

const StyledSnackbarContent = styled(SnackbarContent)(({ theme }) => ({
    [theme.breakpoints.up("sm")]: {
        minWidth: "325px !important"
    }
}));

const stageType = {
    NONE: "",
    BUILD: "сборки",
    RUN: "приложения",
    RELEASE: "релиза"
};

const LogSnackbar = forwardRef((props, ref) => {
    const { id, contentInfo, serviceName, stage, date, ...other } = props;

    const projectSelector = useMemo(() => state => selectProjectByName(state, serviceName), [serviceName]);
    const project = useSelector(projectSelector, (prevValue, newValue) => _.isEqual(prevValue, newValue));

    const { closeSnackbar } = useSnackbar();
    const [expanded, setExpanded] = useState(false);

    const stageString = useMemo(() => stageType[stage], [stage]);

    const link = useMemo(() => {
        // need to change with new sql db redirect
        return `/projects/${project?.serviceType}/${project?.slug}`;
    }, [project]);

    const handleExpandClick = useCallback(() => {
        setExpanded(prevState => !prevState);
    }, []);

    const handleDismiss = useCallback(() => {
        closeSnackbar(id);
    }, [id, closeSnackbar]);

    return (
        <StyledSnackbarContent ref={ref}>
            <Card sx={{ width: "100%", bgcolor: "primary.main" }}>
                <CardActions sx={{ justifyContent: "space-between" }}>
                    <Typography variant="body2" fontSize={12} fontWeight={600}>
                        Зафиксирована ошибка {stageString}.
                    </Typography>
                    <Stack direction="row" justifyContent="center" alignItems="center">
                        <IconButton aria-label="Show more" size="small" onClick={handleExpandClick}>
                            {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                        </IconButton>
                        <IconButton size="small" onClick={handleDismiss}>
                            <CloseIcon fontSize="small" />
                        </IconButton>
                    </Stack>
                </CardActions>
                <Collapse in={expanded} unmountOnExit>
                    <Paper sx={{ px: 2, py: 1, borderRadius: 0 }}>
                        <Stack sx={{ maxWidth: { sm: "290px" } }} spacing={1}>
                            <Typography fontSize={12} sx={{ overflowX: "scroll" }}>
                                <b>[{date}]:</b> {contentInfo}
                            </Typography>
                            <Typography fontSize={12}>
                                Смотри подробнее в логе {stageString} проекта:{" "}
                                {project ? (
                                    <Link component={RouterLink} to={link}>
                                        {serviceName}
                                    </Link>
                                ) : (
                                    serviceName
                                )}
                            </Typography>
                        </Stack>
                    </Paper>
                </Collapse>
            </Card>
        </StyledSnackbarContent>
    );
});

export default LogSnackbar;
