import { useCallback, useState } from "react";
import useStatusApi from "../../../../Hooks/useStatusApi";
import { useServices } from "../../../../DI/hooks";

const useDeleteUser = (slug, database, setDetails) => {
    const [success, error] = useStatusApi();
    const { api } = useServices();
    const [loading, setLoading] = useState(false);

    const handleDelete = useCallback(
        user => {
            setDetails(prev => {
                return {
                    ...prev,
                    databases: prev.databases.map(db => {
                        if (db.database === database)
                            return {
                                ...db,
                                users: db.users.filter(({ username }) => username !== user)
                            };
                        return db;
                    })
                };
            });
        },
        [database, setDetails]
    );

    const handleDeleteUser = useCallback(
        async user => {
            setLoading(true);
            console.log(user);

            try {
                const res = await api.mysql.deleteUser(slug, database, user);
                if (!res.ok) throw Error(res.status);

                success("Пользователь успешно удален");
                handleDelete(user);
            } catch (e) {
                error(e.message, "Ошибка удаления пользователя");
            } finally {
                setLoading(false);
            }
        },
        [database, setDetails, error, slug, success]
    );

    return [loading, handleDeleteUser];
};

export default useDeleteUser;
