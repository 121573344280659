import LoadingButton from "@mui/lab/LoadingButton";
import {
    Alert,
    Box,
    Breadcrumbs,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Divider,
    Paper,
    Stack,
    Tab,
    Tabs,
    TextField,
    Typography
} from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useServices } from "../../../DI/hooks";
import useHandleError from "../../../Hooks/useHandleError";
import {
    setCurrentProject,
    setError,
    setLoading,
    setNotFound,
    setRequiredInstances
} from "../../../Store/CurrentProjectSlice";
import InstancesSelector from "../../Elements/InstancesSelector";
import InternalLink from "../../Elements/InternalLink";
import Nothing, { NothingContainer } from "../../Elements/Nothing";
import ProjectControlButtons from "../../Elements/ProjectControlButtons";
import ProjectSettings from "../../Elements/ProjectSettings";
import RunOutputTab from "../../Elements/RunOutputTab";
import NotFoundPage from "../NotFoundPage";
// import "./style.css";
import PostgresInfoTab from "../../Elements/PostgresInfoTab/PostgresInfoTab";
import SingleProjectPageSkeleton from "../SingleProjectPageSkeleton";
import PostgresConfig from "../../Elements/PostgresConfig";
import { PostgresBackupTab } from "./components/PostgresBackupTab";

const TabPanel = ({ value, index, children }) => (
    <div role="tabpanel" hidden={value !== index} id={`project-tab-${index}`}>
        {value === index && <Box sx={{ padding: 3 }}>{children}</Box>}
    </div>
);

const SingleCnpgPage = () => {
    const navigate = useNavigate();
    const { api, config, decodeBuildStatus } = useServices();
    const { slug } = useParams();
    const currentProject = useSelector(state => state.currentProject);
    const dispatch = useDispatch();
    const [needsScaling, setNeedsScaling] = useState(false);
    const [scaling, setScaling] = useState(false);
    const [activeTab, setActiveTab] = useState(0);
    const [currentTariff, setCurrentTariff] = useState(undefined);
    const [deleteProjectDialogOpened, setDeleteProjectDialogOpened] = useState(false);
    const [projectDeleting, setProjectDeleting] = useState(false);
    const [deleteProjectCheckString, setDeleteProjectCheckString] = useState("");
    const [deleteProjectError, setDeleteProjectError] = useState(null);
    const [tariffUpdating, setTariffUpdating] = useState(false);
    const [projectControlButtonsDisabled, setProjectControlButtonsDisabled] = useState(false);
    const [details, setDetails] = useState([]);
    const { handleApiError } = useHandleError();

    const { project } = currentProject;

    const getCurrentTariff = useCallback(() => {
        api.projects
            .getTariff(slug)
            .then(response => (response.ok ? response.json() : Promise.reject()))
            .then(data => setCurrentTariff(data))
            .catch(e => {
                console.error(e);
                setError("Сервер не отвечает, повторите, пожалуйста, попытку позже");
            });
    }, [api, slug]);

    const getPostgresDetails = useCallback(() => {
        api.postgres
            .getDetails(slug)
            .then(response => (response.ok ? response.json() : Promise.reject()))
            .then(data => setDetails(data))
            .catch(e => {
                console.error(e);
                setError("Сервер не отвечает, повторите, пожалуйста, попытку позже");
            });
    }, [api, slug]);

    useEffect(() => {
        dispatch(setLoading());
        api.projects
            .getById(slug)
            .then(response => {
                if (response.status === 404) {
                    return Promise.resolve(null);
                }
                return response.json();
            })
            .then(data => {
                if (data != null) {
                    dispatch(setCurrentProject(data));
                } else {
                    dispatch(setNotFound());
                }
            })
            .catch(err => dispatch(setError(err)));
    }, [api, dispatch, slug]);

    useEffect(() => {
        // getCurrentService();
        getCurrentTariff();
        getPostgresDetails();
    }, [getCurrentTariff]);

    const handleUpdateTariffId = useCallback(
        tariffId => {
            setTariffUpdating(true);
            api.projects
                .updateTariffById(slug, tariffId)
                .then(response => {
                    if (!response.ok) return Promise.reject(response);
                    // getCurrentService();
                    getCurrentTariff();
                    setTariffUpdating(false);
                    return Promise.resolve();
                })
                .catch(e => {
                    console.error(e);
                    setError("Сервер не отвечает, повторите, пожалуйста, попытку позже");
                    setTariffUpdating(false);
                });
        },
        [api, getCurrentTariff, slug]
    );

    const handleScaleChanged = useCallback(
        value => {
            setNeedsScaling(true);
            dispatch(setRequiredInstances(value));
        },
        [dispatch]
    );

    const doScale = useCallback(
        value => {
            handleScaleChanged(value);
            setScaling(true);
            api.postgres
                .scaleBySlug(slug, value)
                .then(() => {
                    setScaling(false);
                    setNeedsScaling(false);
                })
                .catch(err => {
                    console.error(err);
                    setScaling(false);
                    setNeedsScaling(false);
                });
        },
        [api, slug, handleScaleChanged]
    );

    const handleTabChange = useCallback((event, newValue) => {
        setActiveTab(newValue);
    }, []);

    const handleOpenDeleteProjectDialogClick = useCallback(() => {
        setDeleteProjectDialogOpened(true);
    }, []);

    const handleDeleteProjectDialogClose = useCallback(() => {
        if (!projectDeleting) setDeleteProjectDialogOpened(false);
    }, [projectDeleting]);

    const handleDeleteProjectCheckStringChange = useCallback(event => {
        setDeleteProjectCheckString(event.target.value);
        setDeleteProjectError(null);
    }, []);

    const handleCloseDeleteProjectDialog = useCallback(() => {
        setDeleteProjectDialogOpened(false);
    }, []);

    const handleDeleteProjectClick = useCallback(() => {
        if (deleteProjectCheckString.toLowerCase() !== `удалить навсегда ${project.name}`.toLowerCase()) {
            setDeleteProjectError("Проверочная строка не совпадает");
            return;
        }

        setProjectDeleting(true);

        api.postgres
            .deleteById(currentProject.project.slug)
            .then(() => {
                setProjectDeleting(false);
                navigate("/projects");
            })
            .catch(error => {
                console.error(error);
                setDeleteProjectError("Сервер не отвечает, попробуйте, пожалуйста, повторить попытку позже");
            });
    }, [currentProject, api, navigate, project, deleteProjectCheckString]);

    const handleRebuildClick = () => {};
    const handleRestartClick = () => {};

    const handleFreezeProject = () => {
        doScale(0);
    };

    if (currentProject.loading) return <SingleProjectPageSkeleton />;

    if (currentProject.error !== null)
        return (
            <NothingContainer>
                <Nothing>
                    <Nothing.Item>
                        <Typography variant="h1" color="darkgrey" sx={{ textAlign: "center", pb: 2 }}>
                            <i className="fa-solid fa-plug-circle-xmark" />
                        </Typography>
                        Ошибка загрузки. Повторите, пожалуйста, попытку позже
                    </Nothing.Item>
                </Nothing>
            </NothingContainer>
        );

    if (currentProject.notFound) return <NotFoundPage />;

    return (
        <>
            <Stack useFlexGap gap={2}>
                <Breadcrumbs aria-label="breadcrumb" sx={{ mx: { xs: 3, sm: 0 } }}>
                    <InternalLink underline="hover" color="inherit" to="/">
                        Главная
                    </InternalLink>
                    <InternalLink underline="hover" color="inherit" to="/projects">
                        Проекты
                    </InternalLink>
                    <Typography color="text.primary">{project.name}</Typography>
                </Breadcrumbs>
                <Stack
                    sx={{
                        alignItems: "center",
                        justifyContent: "center"
                    }}
                    direction={{ xs: "column", md: "row" }}
                    spacing={2}
                >
                    <Typography variant="h5">{project.name}</Typography>
                    {(project.status === "CREATING" || project.status === "BUILD_STARTED") && (
                        <CircularProgress size="1em" color="inherit" />
                    )}
                    {decodeBuildStatus(project.status)}
                    <Box sx={{ flexGrow: 1 }} />
                    <Paper>
                        <Stack
                            px={1}
                            py={0.5}
                            sx={{
                                alignItems: "center",
                                justifyContent: "center"
                            }}
                            direction="row"
                            spacing={1}
                            divider={<Divider orientation="vertical" flexItem />}
                        >
                            <InstancesSelector
                                disabled={scaling}
                                instances={currentProject?.project?.instances ?? 0}
                                requiredInstances={currentProject?.project?.requiredInstances ?? 1}
                                onClickApply={doScale}
                            />
                            <ProjectControlButtons
                                disabled={
                                    projectControlButtonsDisabled ||
                                    scaling ||
                                    project.status === "" ||
                                    project.status === "CREATING" ||
                                    project.status === "EMPTY" ||
                                    project.status === "BUILD_STARTED" ||
                                    project.status === "DEPLOYING"
                                }
                                requiredInstances={currentProject?.project?.requiredInstances ?? 1}
                                onClickRebuild={handleRebuildClick}
                                onClickRestart={handleRestartClick}
                                onClickStartStop={doScale}
                            />
                        </Stack>
                    </Paper>
                </Stack>
                <Box sx={{ width: "100%" }}>
                    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                        <Tabs
                            textColor="inherit"
                            indicatorColor="secondary"
                            variant="scrollable"
                            scrollButtons
                            allowScrollButtonsMobile
                            value={activeTab}
                            onChange={handleTabChange}
                        >
                            <Tab label="Инфо" id="project-tab-0" />
                            <Tab label="Конфигурация" id="project-tab-1" />
                            <Tab label="Бэкапы" id="project-tab-backup" />
                            <Tab label="Лог БД" id="project-tab-2" />
                            <Tab label="Настройки" id="project-tab-3" />
                        </Tabs>
                    </Box>
                    <TabPanel value={activeTab} index={0}>
                        <PostgresInfoTab project={project} config={config} />
                    </TabPanel>
                    <TabPanel value={activeTab} index={1}>
                        <PostgresConfig details={details} setDetails={setDetails} />
                    </TabPanel>
                    <TabPanel value={activeTab} index={2}>
                        <PostgresBackupTab
                            scheduledBackupEnabled={details?.isScheduledBackupEnabled}
                            setDetails={setDetails}
                        />
                    </TabPanel>
                    <TabPanel value={activeTab} index={3}>
                        <Stack direction={{ xs: "column", md: "row" }} spacing={2} sx={{ marginBottom: "10px" }}>
                            <Alert severity="warning">Загрузка лога занимает до 3 минут</Alert>
                            <Button
                                variant="outlined"
                                color="info"
                                startIcon={<i className="fa-solid fa-circle-info" />}
                                href="https://docs.amvera.ru/general/faq.html"
                                target="_blank"
                                rel="noreferrer"
                            >
                                Наиболее частые ошибки
                            </Button>
                        </Stack>
                        <RunOutputTab project={project} />
                    </TabPanel>
                    <TabPanel value={activeTab} index={4}>
                        <ProjectSettings
                            tariffUpdating={tariffUpdating}
                            // tariffId={service?.tariffId}
                            tariffId={currentTariff?.id}
                            project={project}
                            handleUpdateTariffId={handleUpdateTariffId}
                            handleOpenDeleteProjectDialogClick={handleOpenDeleteProjectDialogClick}
                            handleFreezeProject={handleFreezeProject}
                        />
                    </TabPanel>
                </Box>
            </Stack>

            <Dialog open={deleteProjectDialogOpened} onClose={handleDeleteProjectDialogClose}>
                <DialogTitle>Удалить проект {project.name}?</DialogTitle>
                <DialogContent dividers>
                    <Stack spacing={2}>
                        <DialogContentText>
                            Удаление проекта остановит все работающие инстансы и{" "}
                            <strong>удалит репозиторий с кодом</strong>. После того, как вы нажмете на кнопку
                            &laquo;Удалить&raquo;, никто &mdash; даже мы &mdash;
                            <strong> не сможет восстановить данные</strong>. Если вы все еще хотите удалить проект,
                            введите следующую строку в поле для ввода:
                        </DialogContentText>
                        <DialogContentText>
                            <strong>удалить навсегда {project.name}</strong>
                        </DialogContentText>
                        <TextField
                            required
                            fullWidth
                            autoFocus
                            id="amvera-delete-project-dialog"
                            value={deleteProjectCheckString}
                            onChange={handleDeleteProjectCheckStringChange}
                            error={deleteProjectError !== null}
                            helperText={deleteProjectError}
                            disabled={projectDeleting}
                            autoComplete="off"
                            onPaste={event => event.preventDefault()}
                        />
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" color="secondary" onClick={handleCloseDeleteProjectDialog}>
                        Отмена
                    </Button>
                    <LoadingButton
                        variant="contained"
                        color="error"
                        loading={projectDeleting}
                        onClick={handleDeleteProjectClick}
                    >
                        Удалить
                    </LoadingButton>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default SingleCnpgPage;
