import { createContext, useContext } from "react";

export const EventContext = createContext(undefined);

export const useEventContext = () => {
    const context = useContext(EventContext);
    // const prevValue = useRef();

    // useEffect(() => {
    //     prevValue.current = context;
    // }, [context]);

    // if (!context) {
    //     throw new Error("useEventContext must be used within the EventContext");
    // }

    // const getValue = useCallback(() => {
    //     if (prevValue.current?.content === context?.content) {
    //         return null;
    //     }
    //     return context;
    // }, [context]);

    return context;
};
