import { Box, Divider, Paper, Stack } from "@mui/material";
import { useState } from "react";
import InstancesSelector from "../../../Elements/InstancesSelector";
import ProjectControlButtons from "../../../Elements/ProjectControlButtons";

export function InstanceController({ project, scaling, doScale, onStartPause }) {
    const [projectControlButtonsDisabled, setProjectControlButtonsDisabled] = useState(false);
    const { status, instances, requiredInstances, serviceType } = project;

    const handleRebuildClick = () => {};
    const handleRestartClick = () => {};

    return (
        <Box>
            <Paper>
                <Stack
                    px={1}
                    py={0.5}
                    sx={{
                        alignItems: "center",
                        justifyContent: "center"
                    }}
                    direction="row"
                    spacing={1}
                    divider={<Divider orientation="vertical" flexItem />}
                >
                    <InstancesSelector
                        disabled={scaling}
                        instances={instances ?? 0}
                        requiredInstances={requiredInstances ?? 1}
                        onClickApply={doScale}
                    />
                    <ProjectControlButtons
                        disabled={
                            projectControlButtonsDisabled ||
                            scaling ||
                            status === "" ||
                            status === "CREATING" ||
                            status === "EMPTY" ||
                            status === "BUILD_STARTED" ||
                            status === "DEPLOYING" ||
                            status === "SCALING" ||
                            status === "STOPPING"
                        }
                        requiredInstances={requiredInstances ?? 1}
                        onClickRebuild={handleRebuildClick}
                        onClickRestart={handleRestartClick}
                        onClickStartStop={onStartPause}
                        status={status}
                        serviceType={serviceType}
                    />
                </Stack>
            </Paper>
        </Box>
    );
}
