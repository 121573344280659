import { useState, useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import YAML from "yaml";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Stepper,
    Step,
    StepLabel,
    TextField,
    DialogContentText,
    Stack,
    ButtonGroup,
    Link,
    Typography,
    IconButton,
    CircularProgress,
    Alert,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    Zoom
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { GitHub as GitIcon, Folder as FolderIcon } from "@mui/icons-material";
import { FormProvider, useForm } from "react-hook-form";
import { setCurrentProject, setLoading, setNotFound } from "../../../Store/CurrentProjectSlice";
import { useServices } from "../../../DI/hooks";
import SelectTariff from "../SelectTariff";
import ConfigurationTab from "../ConfigurationTab";
import useHandleError from "../../../Hooks/useHandleError";
import UploadFiles from "../UploadFiles";
import { useConfig } from "../../../Configuration/hooks";
import CodeBlock from "../CodeBlock";
import SimpleDialog from "../SimpleDialog";
import DbForm from "../DbForm";
import { dbCreate } from "./api/dbCreate";

const MAX_SUMMARY_LENGTH = 27;

const ProjectCreateDialog = ({ open, onClose, setUpdateSeq }) => {
    const validServiceName = useMemo(() => /^[\sa-z0-9а-яА-я-]+$/i, []);
    const { api, fileService, auth } = useServices();
    const dispatch = useDispatch();

    const [activeStep, setActiveStep] = useState(0);
    const [serviceName, setServiceName] = useState("");
    const [serviceNameError, setServiceNameError] = useState(false);
    // using \u00a0 to add additional space below input
    const [serviceNameErrorText, setServiceNameErrorText] = useState("\u00a0");
    const [tariff, setTariff] = useState(null);
    const [formData, setFormData] = useState({});
    const currentProject = useSelector(state => state.currentProject);
    const [useGit, setUseGit] = useState(true);
    const [createLoading, setCreateLoading] = useState(false);
    const [serviceType, setServiceType] = useState(1);
    const { handleApiError } = useHandleError();
    const [isUploaded, setIsUploaded] = useState(false);
    const [isDbConfigured, setIdDbConfigured] = useState(false);
    const [openFinalDialog, setOpenFinalDialog] = useState(false);
    const [finalDialogTitle, setFinalDialogTitle] = useState("");
    const [finalDialogContent, setFinalDialogContent] = useState("");
    const [finalDialogBtn, setFinalDialogBtn] = useState("");
    const [finalDialogOnSave, setFinalDialogOnSave] = useState();

    const methods = useForm({
        defaultValues: {
            version: "8.0",
            instances: 1
        }
    });

    const config = useConfig();

    const { project } = currentProject;

    const steps = ["Общая информация", "Загрузка данных", "Конфигурация"];

    const [postgresFormData, setPostgresFormData] = useState({
        postgresVersion: "",
        database: "",
        dbUsername: "",
        dbPassword: "",
        instances: 1,
        enableSuperuserAccess: false,
        superuserPassword: ""
    });

    const resetDialogContent = useCallback(() => {
        setActiveStep(0);
        setServiceName("");
        setServiceNameError(false);
        setTariff(null);
        setFormData({});
        setUseGit(true);
        setCreateLoading(false);
        setServiceType(1);
        setIsUploaded(false);
        setIdDbConfigured(true);
        setOpenFinalDialog(false);
        setFinalDialogTitle("");
        setFinalDialogContent("");
        setFinalDialogBtn("");
        setFinalDialogOnSave();
    }, []);

    // check all nessesary postgres form fields
    useEffect(() => {
        for (let i = 0; i < Object.entries(postgresFormData).length; i++) {
            const [key, value] = Object.entries(postgresFormData)[i];
            if (key === "enableSuperuserAccess") {
                if (value) {
                    if (postgresFormData.superuserPassword) {
                        setIdDbConfigured(true);
                    } else {
                        setIdDbConfigured(false);
                        break;
                    }
                } else setIdDbConfigured(true);
            }

            if (key !== "postgresVersion" && key !== "superuserPassword" && key !== "enableSuperuserAccess") {
                if (!value) {
                    setIdDbConfigured(false);
                    break;
                } else setIdDbConfigured(true);
            }
        }
    }, [postgresFormData]);

    const handleBack = useCallback(() => {
        methods.reset();
        setActiveStep(prevActiveStep => {
            // if (prevActiveStep === 2 && serviceType === 1) setFormData({});
            setFormData({});
            if (serviceType === 2) return 0;
            return prevActiveStep - 1;
        });
    }, [serviceType]);

    const validateServiceName = useCallback(
        (name, service = serviceType) => {
            const username = auth.user?.profile.preferred_username;
            if (name.trim() === "") {
                setServiceNameError(true);
                setServiceNameErrorText("Введите название проекта");
            } else if (name.trim().toLowerCase() === "new") {
                setServiceNameError(true);
                setServiceNameErrorText("Зарезервированное слово. Введите другое название");
            } else if (name.length > MAX_SUMMARY_LENGTH - username.length) {
                setServiceNameError(true);
                setServiceNameErrorText(`Максимальная длина имени проекта - ${MAX_SUMMARY_LENGTH - username.length}`);
            } else if (!validServiceName.test(name)) {
                setServiceNameError(true);
                setServiceNameErrorText(
                    "Имя проекта может содержать маленькие и заглавные буквы, цифры и знаки дефиса"
                );
            } else {
                setServiceNameError(false);
                setServiceNameErrorText("\u00a0");
            }
        },
        [auth, validServiceName, serviceType]
    );

    const handleProjectNameChange = useCallback(
        e => {
            const name = e.target.value;
            validateServiceName(name);
            setServiceName(e.target.value);
        },
        [validateServiceName]
    );

    const onBlurProjectName = useCallback(
        e => {
            const name = e.target.value.trim();
            validateServiceName(name);
            setServiceName(name);
        },
        [validateServiceName]
    );

    const handleTarifChange = e => {
        setTariff(e.target.value);
    };

    const handleUseGitChange = () => {
        setUseGit(!useGit);
    };

    const handleServiceTypeChange = e => {
        setServiceType(e.target.value);
        validateServiceName(serviceName, e.target.value);
    };

    const handleClose = useCallback(() => {
        methods.reset();
        setActiveStep(0);
        setTariff(null);
        setServiceName("");
        setServiceNameError(false);
        setServiceNameErrorText("\u00a0");
        onClose();
    }, [onClose]);

    const handleBuildClick = useCallback(
        slug => {
            api.projects
                .rebuildById(slug)
                .then(response => (response.ok ? console.log(response) : Promise.reject(response)))
                .catch(respError => handleApiError(respError));
        },
        [api.projects, handleApiError]
    );

    const getProject = useCallback(
        (slug, i = 1) => {
            if (!project.loading) {
                // setCreateLoading(false);
                api.projects
                    .getById(slug)
                    .then(response => {
                        if (response.status === 404) {
                            return Promise.resolve(null);
                        }
                        return response.json();
                    })
                    .then(async data => {
                        if (data != null && data.status === "EMPTY") {
                            dispatch(setCurrentProject(data));
                        } else if (i > 5) {
                            setServiceNameErrorText("Ошибка запроса");
                            setServiceNameError(true);
                            dispatch(setNotFound());
                        } else {
                            await new Promise(r => {
                                setTimeout(r, 1000 * i * i);
                            });
                            getProject(slug, i + 1);
                        }
                    })
                    .catch(err => setServiceNameErrorText(err));
            }
        },
        [api, project, dispatch]
    );

    const handleCreateService = useCallback(async () => {
        setCreateLoading(true);

        api.projects
            .create({
                name: serviceName.trim(),
                tariffId: tariff
            })
            .then(response => {
                if (response.status === 409) {
                    setServiceNameErrorText("Такое имя уже занято или зарезервировано");
                    setServiceNameError(true);
                    return Promise.resolve(null);
                }
                if (response.status !== 200) {
                    setServiceNameErrorText("Ошибка запроса");
                    return Promise.resolve(null);
                }
                return response.json();
            })
            .then(async data => {
                if (data != null) {
                    dispatch(setCurrentProject(data));
                    await new Promise(r => {
                        setTimeout(r, 3000);
                    });
                    getProject(data.slug);
                    setActiveStep(prevActiveStep => prevActiveStep + 1);
                    setUpdateSeq(prevSeq => prevSeq + 1);
                } else {
                    dispatch(setNotFound());
                    setCreateLoading(false);
                }
            })
            .catch(() => {
                setServiceNameErrorText("Сервер не отвечает, повторите попытку позднее");
                setCreateLoading(false);
            })
            .finally(() => setCreateLoading(false));
    }, [api.projects, dispatch, getProject, serviceName, setUpdateSeq, tariff]);

    const handleCreatePostgres = useCallback(() => {
        api.postgres
            .create({
                name: serviceName.trim(),
                tariffId: tariff,
                ...postgresFormData
            })
            .then(response => {
                if (response.status === 409) {
                    setServiceNameErrorText("Такое имя уже занято или зарезервировано");
                    setServiceNameError(true);
                    return Promise.resolve(null);
                }
                if (response.status !== 200) {
                    setServiceNameErrorText("Ошибка запроса");
                    return Promise.resolve(null);
                }
                return response.json();
            })
            .then(async data => {
                if (data != null) {
                    dispatch(setCurrentProject(data));
                    setUpdateSeq(prevSeq => prevSeq + 1);
                } else {
                    dispatch(setNotFound());
                    setCreateLoading(false);
                }
            })
            .catch(() => {
                setServiceNameErrorText("Сервер не отвечает, повторите попытку позднее");
                setCreateLoading(false);
            });
    }, [api.postgres, dispatch, postgresFormData, serviceName, setUpdateSeq, tariff]);

    const handleCreateCustomDB = useCallback(
        data => {
            setCreateLoading(true);
            dbCreate(api, formData?.meta?.toolchain, serviceName.trim(), tariff, data)
                .then(response => {
                    if (response.status === 409) {
                        setServiceNameErrorText("Такое имя уже занято или зарезервировано");
                        setServiceNameError(true);
                        return Promise.resolve(null);
                    }
                    if (response.status !== 200) {
                        setServiceNameErrorText("Ошибка запроса");
                        return Promise.resolve(null);
                    }
                    return response.json();
                })
                .then(async data => {
                    if (data != null) {
                        dispatch(setCurrentProject(data));
                        setUpdateSeq(prevSeq => prevSeq + 1);
                    } else {
                        dispatch(setNotFound());
                        setCreateLoading(false);
                    }
                })
                .catch(() => {
                    setServiceNameErrorText("Сервер не отвечает, повторите попытку позднее");
                    setCreateLoading(false);
                })
                .finally(() => {
                    handleClose();
                    resetDialogContent();
                });
        },
        [dispatch, formData?.meta?.toolchain, serviceName, setUpdateSeq, tariff, handleClose, resetDialogContent]
    );

    const createEnvVariables = useCallback(
        slug => {
            if (formData && formData?.envvar) {
                Object.keys(formData?.envvar).forEach(variable => {
                    api.env
                        .add(slug, {
                            name: variable,
                            value: formData?.envvar[variable].text,
                            isSecret: formData?.envvar[variable].type === "Secret"
                        })
                        .then(response => response.ok || Promise.reject())
                        .catch(error => console.error(error));
                });
            }
        },
        [api.env, formData]
    );

    const handleCreateDb = useCallback(async () => {
        try {
            const response = await api.projects.create({
                name: serviceName.trim(),
                tariffId: tariff
            });

            if (!response.ok) {
                if (response.status === 409) {
                    setServiceNameErrorText("Такое имя уже занято или зарезервировано");
                } else {
                    setServiceNameErrorText("Ошибка запроса");
                }
                throw Error(response.status);
            }

            const data = await response.json();

            if (data) {
                const projectSlug = {
                    slug: data?.slug
                };

                await new Promise(r => {
                    setTimeout(r, 3000);
                });

                await getProject(data.slug);

                await fileService
                    .applyConfig(formData, projectSlug)
                    .then(() => createEnvVariables(data.slug))
                    .then(() => handleBuildClick(data.slug));

                await new Promise(r => {
                    setTimeout(r, 7000);
                });

                setUpdateSeq(prevSeq => prevSeq + 1);

                return Promise.resolve();
            }
        } catch (e) {
            handleBack();
            setServiceNameError(true);
            dispatch(setNotFound());

            return Promise.reject();
        }
    }, [
        api.projects,
        dispatch,
        fileService,
        formData,
        getProject,
        serviceName,
        setUpdateSeq,
        tariff,
        createEnvVariables
    ]);

    const handleNext = useCallback(async () => {
        switch (activeStep) {
            case 0:
                dispatch(setLoading({}));
                if (serviceType === 1) handleCreateService();
                else setActiveStep(prevActiveStep => prevActiveStep + 2);
                break;
            case 1:
                setActiveStep(prevActiveStep => prevActiveStep + 1);
                break;
            case 2:
                setCreateLoading(true);
                if (serviceType === 1 || formData?.meta?.toolchain !== "postgresql") {
                    if (formData && formData?.meta?.toolchain) {
                        if (formData?.meta?.environment === "db")
                            try {
                                await handleCreateDb();
                            } catch (e) {
                                break;
                            }
                        else {
                            // removes servicePort
                            if (formData.run?.servicePort) delete formData.run.servicePort;
                            await fileService.applyConfig(formData, project);
                        }
                    }

                    if (!useGit) {
                        await handleBuildClick(project.slug);
                    }
                } else {
                    await handleCreatePostgres();
                    await new Promise(r => {
                        setTimeout(r, 7000);
                    });
                }

                handleClose();
                resetDialogContent();

                setCreateLoading(false);
                break;
            default:
                console.error("Unknown step");
        }
    }, [
        activeStep,
        dispatch,
        fileService,
        formData,
        handleBuildClick,
        handleClose,
        handleCreatePostgres,
        handleCreateService,
        project,
        resetDialogContent,
        serviceType,
        useGit,
        handleCreateDb
    ]);

    const getServiceConfig = () => (
        <div>
            {useGit && (
                <div>
                    <Alert severity="warning" sx={{ mb: 1 }}>
                        Задание конфигурации создает коммит в репозитории. Для привязки к имеющимся репозиториям
                        необходимо будет сделать merge.
                    </Alert>
                </div>
            )}

            {currentProject !== null ? (
                <ConfigurationTab project={project} formData={formData} setFormData={setFormData} isNew />
            ) : (
                <div className="circular-progress-container">
                    <CircularProgress />
                </div>
            )}
        </div>
    );

    const getStepContent = step => {
        switch (step) {
            case 0:
                return (
                    <div>
                        {createLoading ? (
                            <div className="circular-progress-container">
                                <CircularProgress />
                            </div>
                        ) : (
                            <div>
                                <Stack spacing={1}>
                                    <DialogContentText sx={{ mb: 2 }}>
                                        Проект это репозиторий Git и один или более экземпляров контейнеров. Создавайте
                                        отдельный проект для каждого вашего приложения или микросервиса.
                                    </DialogContentText>
                                    <TextField
                                        autoComplete="off"
                                        required
                                        fullWidth
                                        autoFocus
                                        id="amvera-create-project-dialog"
                                        label="Название проекта"
                                        value={serviceName}
                                        onChange={handleProjectNameChange}
                                        onBlur={onBlurProjectName}
                                        error={serviceNameError}
                                        helperText={serviceNameErrorText}
                                    />
                                    <Stack spacing={3}>
                                        <FormControl fullWidth required>
                                            <InputLabel id="project-tariff-select-label">Тип сервиса</InputLabel>
                                            <Select
                                                id="project-type-select"
                                                labelId="project-type-select-label"
                                                label="Тип сервиса"
                                                value={serviceType}
                                                onChange={handleServiceTypeChange}
                                            >
                                                <MenuItem value={1}> Приложение </MenuItem>
                                                <MenuItem value={2}> База данных </MenuItem>
                                            </Select>
                                        </FormControl>

                                        <Stack spacing={2}>
                                            <Zoom in={tariff === 1 || tariff === 4} unmountOnExit>
                                                <Alert severity="info">
                                                    Рекомендуем при первом запуске использовать один из старших тарифов.
                                                    После успешного запуска вы сможете изменить тариф.
                                                </Alert>
                                            </Zoom>

                                            <SelectTariff
                                                required
                                                // disabled={createLoading}
                                                tariffId={tariff}
                                                onChange={handleTarifChange}
                                            />
                                        </Stack>
                                    </Stack>
                                </Stack>
                            </div>
                        )}
                    </div>
                );
            case 2:
                return (
                    <div>
                        {serviceType === 1 ? (
                            getServiceConfig()
                        ) : (
                            <DbForm
                                postresFormData={postgresFormData}
                                setPostgresFormData={setPostgresFormData}
                                formData={formData}
                                setFormData={setFormData}
                            />
                            // <PostgresForm postgresFormData change={setPostgresFormData} />
                        )}
                    </div>
                );
            case 1:
                return (
                    <div>
                        <Typography variant="h6" gutterBottom>
                            Метод инициализации проекта
                        </Typography>
                        <ButtonGroup fullWidth variant="outlined" size="large">
                            <Button
                                startIcon={<GitIcon />}
                                onClick={() => handleUseGitChange(true)}
                                style={{
                                    backgroundColor: useGit ? "#BB4F11" : "transparent",
                                    color: useGit ? "white" : "inherit"
                                }}
                            >
                                Через Git
                            </Button>
                            <Button
                                startIcon={<FolderIcon />}
                                onClick={() => handleUseGitChange(false)}
                                style={{
                                    backgroundColor: !useGit ? "#BB4F11" : "transparent",
                                    color: !useGit ? "white" : "inherit"
                                }}
                            >
                                Через интерфейс
                            </Button>
                        </ButtonGroup>
                        {useGit ? (
                            <div style={{ marginTop: "20px" }}>
                                <Alert severity="info" style={{ marginBottom: "10px" }}>
                                    Вы можете пропустить шаги, создав пустой проект и загрузить файлы и конфигурацию
                                    позже
                                </Alert>
                                <p>
                                    Склонировать пустой репозиторий
                                    <IconButton
                                        size="small"
                                        color="inherit"
                                        href="https://docs.amvera.ru/applications/git/main-origin.html"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        <i className="fa-regular fa-circle-question" />
                                    </IconButton>
                                    :
                                </p>
                                <CodeBlock>
                                    $ git clone {config.gitBaseUri}/{project.ownerName}/{project.slug}
                                </CodeBlock>
                                <p>
                                    Или подключить к существующему репозиторию
                                    <IconButton
                                        size="small"
                                        color="inherit"
                                        href="https://docs.amvera.ru/applications/git/secondary-origin.html"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        <i className="fa-regular fa-circle-question" />
                                    </IconButton>
                                    :
                                </p>
                                <CodeBlock>
                                    $ git remote add amvera {config.gitBaseUri}/{project.ownerName}/{project.slug}
                                </CodeBlock>
                                <p>Отправить изменения (сборка начнется автоматически) :</p>
                                <CodeBlock>$ git push amvera master</CodeBlock>
                                <p>
                                    <Link
                                        href="https://docs.amvera.ru/applications/git/freq-errors.html"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        Способы разрешения возможных ошибок.
                                    </Link>
                                </p>
                            </div>
                        ) : (
                            <div style={{ marginTop: "20px" }}>
                                <Alert severity="info" style={{ marginBottom: "10px" }}>
                                    Если проект на python, не забудьте про файл с зависимостями
                                </Alert>
                                <UploadFiles
                                    projectSlug={project.slug}
                                    path="/"
                                    directory="Code"
                                    setIsUploaded={setIsUploaded}
                                />
                            </div>
                        )}
                        {serviceNameError && (
                            <Typography variant="body2" color="error">
                                {serviceNameError}
                            </Typography>
                        )}
                    </div>
                );
            default:
                return "Unknown step";
        }
    };

    const closeFinalDialog = useCallback(() => {
        setOpenFinalDialog(false);
    }, []);

    const downloadConfigFile = useCallback(() => {
        const { envvar, ...downloadableFormData } = formData;

        // removes servicePort
        if (downloadableFormData.run?.servicePort) delete downloadableFormData.run.servicePort;

        const configuration = {
            meta: {
                environment: downloadableFormData.meta.environment,
                toolchain: { name: downloadableFormData.meta.toolchain, version: downloadableFormData.meta.version }
            },
            build: {
                ...downloadableFormData.build
            },
            run: {
                ...downloadableFormData.run
            }
        };

        const configContent = YAML.stringify(configuration);
        const file = new Blob([configContent], { type: "text/plain" });

        const element = document.createElement("a");
        element.href = URL.createObjectURL(file);
        element.download = "amvera.yml";

        document.body.appendChild(element);
        element.click();
    }, [formData]);

    const handleCompleteWithGit = useCallback(() => {
        setOpenFinalDialog(true);
        setFinalDialogOnSave(() => handleNext);
        setFinalDialogBtn("Завершить");
        setFinalDialogTitle("GIT");
        setFinalDialogContent(
            <p>
                Прежде чем завершить создание вы можете задать и скачать конфигурационный файл. После этого положите его
                в корень проекта, выполните команду git push, и сборка начнется автоматически.
            </p>
        );
    }, [handleNext]);

    return (
        <Dialog open={open} maxWidth="md" fullWidth>
            <SimpleDialog
                open={openFinalDialog}
                content={finalDialogContent}
                title={finalDialogTitle}
                confirmBtnText={finalDialogBtn}
                onClose={closeFinalDialog}
                onSave={finalDialogOnSave}
            />
            <DialogTitle>Создание проекта</DialogTitle>
            <FormProvider {...methods}>
                <form onSubmit={methods.handleSubmit(handleCreateCustomDB)}>
                    <DialogContent>
                        <div style={{ margin: "16px 0" }}>
                            <Stepper activeStep={activeStep}>
                                {steps.map(label => (
                                    <Step key={label}>
                                        <StepLabel>{label}</StepLabel>
                                    </Step>
                                ))}
                            </Stepper>
                        </div>
                        {getStepContent(activeStep)}
                    </DialogContent>
                    <DialogActions sx={{ display: "flex", justifyContent: "space-between" }}>
                        {activeStep === 2 && serviceType === 1 ? (
                            <Button
                                onClick={downloadConfigFile}
                                color="primary"
                                disabled={Object.keys(formData).length === 0}
                            >
                                Скачать amvera.yml
                            </Button>
                        ) : (
                            <div />
                        )}
                        <div>
                            <Button onClick={handleClose} color="primary">
                                Отмена
                            </Button>
                            {activeStep !== 0 && (
                                <Button onClick={handleBack} color="primary" disabled={createLoading}>
                                    Назад
                                </Button>
                            )}
                            {formData?.meta?.toolchain === "mysql beta" && activeStep === steps.length - 1 ? (
                                <LoadingButton
                                    type="submit"
                                    loading={createLoading}
                                    disabled={!methods.formState.isDirty}
                                >
                                    Завершить
                                </LoadingButton>
                            ) : (
                                <LoadingButton
                                    loading={createLoading}
                                    onClick={
                                        activeStep === 2 && serviceType === 1 && useGit
                                            ? handleCompleteWithGit
                                            : handleNext
                                    }
                                    color="primary"
                                    disabled={
                                        (activeStep === 0 && (serviceNameError || !tariff)) ||
                                        createLoading ||
                                        (activeStep === 1 && !useGit && !isUploaded) ||
                                        (serviceType === 2 &&
                                            activeStep !== 0 &&
                                            (!formData?.meta?.toolchain ||
                                                (formData?.meta?.toolchain === "postgresql" && !isDbConfigured)))
                                    }
                                >
                                    {activeStep === steps.length - 1 ? "Завершить" : "Далее"}
                                </LoadingButton>
                            )}
                        </div>
                    </DialogActions>
                </form>
            </FormProvider>
        </Dialog>
    );
};

export default ProjectCreateDialog;
