import { Button, Fade, Icon, IconButton, Popper, TextField, Tooltip, Typography } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import { useCallback, useEffect, useRef, useState } from "react";
import { hasText, isNotExists, isNumber } from "../../../utils";

const InstancesSelector = ({ disabled, instances, requiredInstances, onClickApply }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [value, setValue] = useState(requiredInstances);

    const ref = useRef();

    useEffect(() => {
        setAnchorEl(ref.current);
    }, []);

    useEffect(() => {
        setValue(requiredInstances);
    }, [requiredInstances]);

    const handleClickApply = useCallback(() => {
        if (isNotExists(onClickApply)) return;
        onClickApply(value);
    }, [value, onClickApply]);

    const handleClickMinus = useCallback(() => {
        const newValue = value - 1;
        if (newValue < 0) return;
        setValue(newValue);
    }, [value]);

    const handleClickPlus = useCallback(() => {
        const newValue = value + 1;
        if (newValue > 20) return;
        setValue(newValue);
    }, [value]);

    const handleChangeInput = useCallback(event => {
        const rawValue = hasText(event?.target?.value) ? parseInt(event?.target?.value) : 0;
        const newValue = isNumber(rawValue) ? rawValue : 0;
        if (newValue < 0 || newValue > 99) return;
        setValue(newValue);
    }, []);

    return (
        <>
            <Grid2 ref={ref} container direction="column" alignItems="center" justifyContent="flex-start">
                <Grid2>
                    <Typography noWrap variant="caption" color="darkgrey">
                        Количество инстансов
                    </Typography>
                </Grid2>
                <Grid2>
                    <Grid2 container direction="row" spacing={0.5} alignItems="center">
                        <Grid2>
                            <IconButton size="small" disabled={disabled} onClick={handleClickMinus}>
                                <Icon className="fa-solid fa-minus" fontSize="small" />
                            </IconButton>
                        </Grid2>
                        <Tooltip arrow disableInteractive title="Текущее кол-во инстансов">
                            <Grid2>
                                <Typography align="center">{instances ?? 0}</Typography>
                            </Grid2>
                        </Tooltip>
                        <Grid2>
                            <Typography>/</Typography>
                        </Grid2>
                        <Tooltip arrow disableInteractive title="Требуемое кол-во инстансов">
                            <Grid2>
                                <TextField
                                    inputProps={{
                                        inputMode: "numeric",
                                        pattern: "[0-9]*",
                                        style: { textAlign: "center", padding: 0 }
                                    }}
                                    sx={{ width: "3ch" }}
                                    margin="none"
                                    variant="standard"
                                    size="small"
                                    disabled={disabled}
                                    value={value ?? 1}
                                    onChange={handleChangeInput}
                                />
                            </Grid2>
                        </Tooltip>
                        <Grid2>
                            <IconButton size="small" disabled={disabled} onClick={handleClickPlus}>
                                <Icon className="fa-solid fa-plus" fontSize="small" />
                            </IconButton>
                        </Grid2>
                    </Grid2>
                </Grid2>
            </Grid2>
            <Popper sx={{ paddingTop: 1 }} open={value !== requiredInstances} anchorEl={anchorEl} transition>
                {({ TransitionProps }) => (
                    <Fade {...TransitionProps} timeout={250}>
                        <Button variant="contained" disabled={disabled} onClick={handleClickApply}>
                            Применить
                        </Button>
                    </Fade>
                )}
            </Popper>
        </>
    );
};

export default InstancesSelector;
