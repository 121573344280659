import { Link as MuiLink } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";

const InternalLink = ({ children, ...params }) => (
    <MuiLink component={RouterLink} {...params}>
        {children}
    </MuiLink>
);

export default InternalLink;
