import { useState, useEffect, useCallback, useMemo } from "react";
import { useAuth } from "react-oidc-context";
import { Grid, Typography, Button, Box, CircularProgress, IconButton } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import FolderIcon from "@mui/icons-material/Folder";
import DescriptionIcon from "@mui/icons-material/Description";
import DeleteIcon from "@mui/icons-material/Delete";
import { useConfig } from "../../../Configuration/hooks";
import HttpService from "../../../Services/HttpService";
import "./style.css";

const FileExplorer = ({ selectedDir, project, setPath, session = 0 }) => {
    const [folderStack, setFolderStack] = useState([]);
    const [currentFolder, setCurrentFolder] = useState([]);
    const [loading, setLoading] = useState(false);

    const config = useConfig();
    const auth = useAuth();
    const http = useMemo(() => new HttpService(auth, config.api), [auth, config]);

    const getDirType = dir => {
        switch (dir) {
            case "Data":
                return "DATA";
            case "Artifacts":
                return "ARTIFACTS";
            default:
                return "CODE";
        }
    };

    const fetchData = useCallback((dir, folderPath) => {
        const directoryType = getDirType(dir);

        setLoading(true);
        const body = {
            directory: directoryType,
            folderPath: folderPath
        };
        http.post(`/file-storage/${project.slug}/files`, body)
            .then(response => (response.ok ? response.json() : Promise.reject()))
            .then(data => {
                setCurrentFolder(data);
                setLoading(false);
            })
            .catch(error => {
                setLoading(false);
                console.error("Error fetching files:", error);
            });
    });

    useEffect(() => {
        setCurrentFolder({});
        setFolderStack([]);
        fetchData(selectedDir, "/");
    }, [selectedDir, session]);

    const handleFolderClick = folderNode => {
        if (folderNode.isFolder) {
            setFolderStack([...folderStack, currentFolder]);
            fetchData(selectedDir, folderNode.path);
            setPath(folderNode.path);
        }
    };

    const handleGoBack = () => {
        if (folderStack.length > 0) {
            const previousFolder = folderStack.pop();

            const parts = previousFolder[0]?.path.split("/");
            parts.pop();
            const prevPath = parts.length ? parts.join("/") : "/";

            setPath(prevPath);
            setCurrentFolder(previousFolder);
            setFolderStack([...folderStack]);
        }
    };

    const handleDeleteButtonClick = (folderNode, index) => {
        const directoryType = getDirType(selectedDir);
        const body = {
            directory: directoryType,
            filePaths: [folderNode.path]
        };
        setLoading(true);
        http.patch(`/file-storage/userdata/${project.slug}`, body)
            .then(() => {
                setLoading(false);
                setCurrentFolder(currentFolder.filter((item, ind) => ind !== index));
            })
            .catch(error => {
                setLoading(false);
                console.error("Error fetching files:", error);
            });
    };

    return (
        <Grid item xs={12} sm={6} md={4} className="git-file-explorer-container" mb={2}>
            {folderStack.length > 0 && (
                <Button
                    startIcon={<ArrowBackIcon />}
                    onClick={handleGoBack}
                    variant="outlined"
                    color="secondary"
                    fullWidth
                    className="git-go-back-button"
                >
                    Go Back
                </Button>
            )}
            {loading && (
                <div className="circular-progress-container">
                    <CircularProgress />
                </div>
            )}
            {currentFolder && currentFolder.length > 0 ? (
                <Box className="git-tree-container">
                    {currentFolder.map((node, index) => (
                        <Box key={index} className="git-file-box">
                            <div style={{ position: "relative" }}>
                                <Button
                                    variant="text"
                                    fullWidth
                                    className={`git-file-button ${
                                        node.type === "folder" ? "git-folder-button" : "git-file-button"
                                    }`}
                                    onClick={() => handleFolderClick(node)}
                                >
                                    {node.isFolder ? (
                                        <>
                                            <FolderIcon className="git-folder-icon" /> {node.name}
                                        </>
                                    ) : (
                                        <>
                                            <DescriptionIcon className="git-file-icon" /> {node.name}
                                        </>
                                    )}
                                </Button>
                                <IconButton
                                    onClick={() => handleDeleteButtonClick(node, index)} // Implement handleDeleteButtonClick
                                    className="delete-button"
                                    size="small"
                                >
                                    <DeleteIcon />
                                </IconButton>
                            </div>
                        </Box>
                    ))}
                </Box>
            ) : (
                <div className="center-contents">
                    <Typography className="git-no-data-message">Пустая папка</Typography>
                </div>
            )}
        </Grid>
    );
};

export default FileExplorer;
