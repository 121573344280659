import { memo } from "react";
import { Skeleton, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import useGetServiceResources from "../../hooks/useGetServiceResources";
import { StatusCircles } from "../../../../shared/ResourceStatus";

export const ResourceStatus = memo(({ slug }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.up("md"));
    const [{ status }, statusError, isLoading] = useGetServiceResources(slug);

    const statusInfo = {
        NONE: { info: "Неактивный" },
        NO_DATA: { info: "Расчет потребления" },
        RED: { info: "Потребление выше 85%" },
        YELLOW: { info: "Высокое потребление" },
        GREEN: { info: "Потребление в норме" }
    };

    if (isLoading) return <Skeleton width={87} height={30} />;

    return (
        <Stack direction="row" alignItems="center" justifyContent="start" spacing={2}>
            <StatusCircles status={status} />
            {isMobile && (
                <Typography variant="caption" minWidth={200}>
                    {statusInfo[status]?.info}
                </Typography>
            )}
        </Stack>
    );
});
