import { useParams } from "react-router-dom";
import { useCallback } from "react";

import { useMySQLDetailsContext } from "../../SingleDbPage/context/MySQLDetailsContext";

import useIsDisabled from "../hooks/useIsDisabled";
import { BackupTab } from "../../SingleDbPage/components";
import { useServices } from "../../../../DI/hooks";

export function MySQLBackupTab() {
    const { details, setDetails } = useMySQLDetailsContext();
    const { slug } = useParams();
    const { isDisabled, backupStatus } = useIsDisabled();
    const { api } = useServices();

    const executeGetBackups = useCallback(() => api.mysql.getBackups(slug), [api.mysql, slug]);
    const executeChangeScheduledBackup = useCallback(
        enabled => api.mysql.changeScheduledBackup(slug, enabled),
        [api.mysql, slug]
    );
    const executeAddBackup = useCallback(data => api.mysql.addBackup(data), [api.mysql]);
    const executeDeleteBackup = useCallback(backupName => api.mysql.deleteBackup(backupName), [api.mysql]);
    const executeRestoreBackup = useCallback(
        backupName => api.mysql.restoreBackup(slug, backupName),
        [api.mysql, slug]
    );

    return (
        <BackupTab
            isDisabled={isDisabled}
            isScheduledBackupEnabled={details?.isScheduledBackupEnabled}
            backupStatus={backupStatus}
            getBackups={executeGetBackups}
            changeScheduledBackup={executeChangeScheduledBackup}
            addBackup={executeAddBackup}
            deleteBackup={executeDeleteBackup}
            restoreBackup={executeRestoreBackup}
            setDetails={setDetails}
            backupDoc="https://docs.amvera.ru/databases/mysql-beta.html#id4"
            restoreBackupDoc="https://docs.amvera.ru/databases/mysql-beta.html#id7"
            scheduledBackupsDoc="https://docs.amvera.ru/databases/mysql-beta.html#id5"
            onDemandBackupsDoc="https://docs.amvera.ru/databases/mysql-beta.html#id6"
        />
    );
}
