import { hasText, paramsToQueryString } from "../../utils";
import Endpoint from "./Endpoint";

class TariffEndpoint extends Endpoint {
    getTariffs(currency = "RUB") {
        const params = {
            currency: hasText(currency) ? currency.trim().toUpperCase() : "RUB"
        };
        return this.http.get(`${this.address}${paramsToQueryString(params)}`);
    }
}

export default TariffEndpoint;
