import { CircularProgress, Stack } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/material/styles";
import useCreated from "../hooks/useCreated";

const TransitionIcon = styled("div")(() => ({
    "display": "flex",
    "justify-content": "center",
    "align-items": "center",
    "transition": "opacity 0.5s ease-in-out, visibility 0.5s ease-in-out",
    "opacity": 1,
    "visibility": "visible",
    "&.hidden": {
        opacity: 0,
        visibility: "hidden"
    }
}));

export default function StatusDisplay({ status }) {
    const { showSuccess } = useCreated(status);

    return (
        <>
            {status === "CREATING" ? (
                <Stack justifyContent="center" alignItems="center" width={24} height={30}>
                    <CircularProgress size={16} />
                </Stack>
            ) : status === "CREATED" ? (
                <TransitionIcon className={!showSuccess ? "hidden" : ""}>
                    <CheckIcon color="success" />
                </TransitionIcon>
            ) : (
                <TransitionIcon>
                    <CloseIcon color="error" />
                </TransitionIcon>
            )}
        </>
    );
}
