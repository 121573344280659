import LoadingButton from "@mui/lab/LoadingButton";
import {
    Box,
    Breadcrumbs,
    Button,
    Divider,
    Grid,
    IconButton,
    InputAdornment,
    Modal,
    Stack,
    TextField,
    Typography,
    Checkbox,
    FormControlLabel,
    Paper,
    Switch
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { DataGrid } from "@mui/x-data-grid";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { useCallback, useEffect, useState } from "react";
import { v4 as uuidV4 } from "uuid";
import { useServices } from "../../../DI/hooks";
import { formatDate, hasText, isEmpty, formatPaymentType } from "../../../utils";
import InternalLink from "../../Elements/InternalLink";
import BillingLayout from "../../Elements/Billing/BillingLayout";

const columnsBilling = [
    {
        field: "serviceTypeName",
        headerName: "Тип сервиса",
        flex: 1,
        valueFormatter: params => params?.value ?? "Пополнение"
    },
    {
        field: "serviceName",
        headerName: "Сервис",
        flex: 1,
        valueFormatter: params => params?.value ?? "-"
    },
    {
        field: "tariffName",
        headerName: "Тариф",
        flex: 1,
        valueFormatter: params => params?.value ?? "-"
    },
    {
        field: "amount",
        headerName: "Кол-во ед.",
        flex: 1,
        valueFormatter: params => params?.value ?? "-"
    },
    {
        field: "price",
        headerName: "Цена за ед.",
        flex: 1,
        valueFormatter: params => params?.value ?? "-"
    },
    {
        field: "cost",
        headerName: "Стоимость",
        flex: 1,
        valueFormatter: params => params?.value ?? "-"
    },
    {
        field: "periodStart",
        headerName: "От",
        flex: 1,
        minWidth: 175,
        valueFormatter: params => formatDate((params?.value ?? undefined) * 1000) ?? "-"
    },
    {
        field: "periodEnd",
        headerName: "До",
        flex: 1,
        minWidth: 175,
        valueFormatter: params => formatDate((params?.value ?? undefined) * 1000) ?? "-"
    }
];

const columnsPayment = [
    {
        field: "amount",
        headerName: "Сумма",
        flex: 1,
        valueFormatter: params => params?.value ?? "-"
    },
    {
        field: "type",
        headerName: "Тип оплаты",
        flex: 1,
        valueFormatter: params => formatPaymentType(params?.value) ?? "-"
    },
    {
        field: "currency",
        headerName: "Валюта",
        flex: 1,
        valueFormatter: params => params?.value ?? "-"
    },
    {
        field: "timestamp",
        headerName: "Дата",
        flex: 1,
        minWidth: 175,
        valueFormatter: params => formatDate((params?.value ?? undefined) * 1000) ?? "-"
    }
];

const BalancePage = () => {
    const { api } = useServices();
    const [periodStart, setPeriodStart] = useState(dayjs().subtract(1, "day"));
    const [periodEnd, setPeriodEnd] = useState(dayjs());
    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(50);
    const [details, setDetails] = useState([]);
    const [topupAmount, setTopupAmount] = useState(1000);
    const [maxMonthPayments, setMaxMonthPayments] = useState(3);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const [monthPaymentsError, setMonthPaymentsError] = useState("");
    const [promo, setPromo] = useState("");
    const [promoLoading, setPromoLoading] = useState(false);
    const [promoError, setPromoError] = useState("");
    const [modalOpened, setModalOpened] = useState(false);
    const [modalMessage, setModalMessage] = useState("");
    const [usedPromo, setUsedPromo] = useState([]);
    const [isAutopayEnabled, setIsAutopayEnabled] = useState(true);
    const [currentAutopayment, setCurrentAutopayment] = useState(null);
    const [switchState, setSwitchState] = useState(false);
    const [columns, setColumn] = useState(columnsBilling);

    const fetchBalanceDetails = useCallback(() => {
        setColumn(columnsBilling);
        api.balance
            .getUserBalanceDetails(periodStart, periodEnd, page, pageSize)
            .then(response => (response.ok ? response.json() : Promise.reject()))
            .then(data => setDetails(data))
            .catch(e => {
                console.error(e);
                setError("Сервер не отвечает, повторите, пожалуйста, попытку позже");
            });
    }, [api, periodStart, periodEnd, page, pageSize]);

    const fetchPayments = useCallback(() => {
        setColumn(columnsPayment);
        api.payment
            .listPayments(periodStart, periodEnd, page, pageSize)
            .then(response => (response.ok ? response.json() : Promise.reject()))
            .then(data => setDetails(data))
            .catch(e => {
                console.error(e);
                setError("Сервер не отвечает, повторите, пожалуйста, попытку позже");
            });
    }, [api, periodStart, periodEnd, page, pageSize]);

    useEffect(() => {
        fetchBalanceDetails();
        setSwitchState(false);
    }, [api, periodStart, periodEnd, page, pageSize]);

    useEffect(() => {
        api.promo
            .getUsed()
            .then(response => response.json())
            .then(data => {
                setUsedPromo(data);
            })
            .catch(err => console.error(err));
    }, [api]);

    const fetchRecurrentPaymnets = useCallback(() => {
        api.payment
            .getRecurrentPayment()
            .then(response => response.json())
            .then(data => {
                if (data.status >= 400) throw new Error();
                setCurrentAutopayment(data);
            })
            .catch(err => setCurrentAutopayment(null));
    }, [api]);

    useEffect(() => {
        fetchRecurrentPaymnets();
    }, [api]);

    // set month payments to 0 if disabled
    useEffect(() => {
        if (!isAutopayEnabled) setMaxMonthPayments(0);
    }, [isAutopayEnabled]);

    const handleChangePeriodStart = useCallback(value => {
        setPeriodStart(value);
    }, []);

    const handleChangePeriodEnd = useCallback(value => {
        setPeriodEnd(value);
    }, []);

    const handleChangePage = useCallback(value => {
        setPage(value);
    }, []);

    const handleChangePageSize = useCallback(value => {
        setPageSize(value);
    }, []);

    const handleTopupEditChange = useCallback(event => {
        if (event.target.value === "") {
            setTopupAmount(0);
            return;
        }
        const value = parseInt(event.target.value);
        if (Number.isNaN(value)) return;
        setTopupAmount(value);
        setError("");
    }, []);

    const handleTopupClick = useCallback(() => {
        if (topupAmount < 100 || (maxMonthPayments < 1 && isAutopayEnabled)) {
            if (topupAmount < 100) setError("Введите, пожалуйста, сумму больше 100 руб");
            if (maxMonthPayments < 1) setMonthPaymentsError("Минимальное кол-во платежей в месяц - 1");
            return;
        }

        setLoading(true);

        api.balance
            .topUp(topupAmount, maxMonthPayments)
            .then(response => {
                if (response.status === 400) {
                    setError("Недопустимое значение суммы пополнения");
                    setLoading(false);
                } else if (response.status >= 500) {
                    setError("Ошибка сервера, повторите, пожалуйста, попытку позже");
                    setLoading(false);
                } else {
                    response
                        .json()
                        .then(data => {
                            setLoading(false);
                            console.log("redirectUrldata: ", data.redirectUrl);
                            // window.location.href = data.redirectUrl;
                            fetch(data.redirectUrl)
                                .then(response => response.text())
                                .then(redirectString => {
                                    // Extract URL from the redirect string
                                    const redirectUrl = redirectString.replace("redirect:", "").trim();
                                    // Redirect to the extracted URL
                                    window.location.href = redirectUrl;
                                })
                                .catch(error => {
                                    console.error("Error following redirect URL:", error);
                                });
                        })
                        .catch(() => {
                            setError("Сервер вернул недопустимое значение; повторите, пожалуйста, попытку позже");
                            setLoading(false);
                        });
                }
            })
            .catch(() => {
                setError("Сервер не отвечает, повторите, пожалуйста, попытку позже");
                setLoading(false);
            });
    }, [topupAmount, maxMonthPayments, api, isAutopayEnabled]);

    const handlePromoEditChange = useCallback(event => {
        setPromo(event.target.value);
        setPromoError("");
    }, []);

    const handleApplyPromoClick = useCallback(() => {
        if (!hasText(promo)) {
            setPromoError("Промокод не может быть пустой строкой");
            return;
        }

        setPromoLoading(true);

        api.promo
            .create({ code: promo })
            .then(response => {
                if (response.status === 404) {
                    setPromoError("Недействительный промокод");
                    setPromoLoading(false);
                } else if (response.status === 409) {
                    setPromoError("Промокод уже использован");
                    setPromoLoading(false);
                } else if (response.status === 406) {
                    setPromoError("Срок действия промокода истек");
                    setPromoLoading(false);
                } else if (response.status === 501) {
                    setPromoError("Промокод еще не реализован");
                    setPromoLoading(false);
                } else if (response.status === 500) {
                    setPromoError("Ошибка сервера, повторите, пожалуйста, попытку позже");
                    setPromoLoading(false);
                } else {
                    response
                        .json()
                        .then(data => {
                            setPromoLoading(false);
                            setPromo("");
                            setModalMessage(`Применен промокод: "${data.description}"`);
                            setModalOpened(true);
                        })
                        .catch(() => {
                            setPromoError("Сервер вернул недопустимое значение; повторите, пожалуйста, попытку позже");
                            setPromoLoading(false);
                        });
                }
            })
            .catch(() => {
                setError("Сервер не отвечает, повторите, пожалуйста, попытку позже");
                setLoading(false);
            });
    }, [api, promo]);

    const handleModalClose = useCallback(() => {
        setModalOpened(false);
    }, []);

    const handleAutopayChange = useCallback(event => {
        setIsAutopayEnabled(event.target.checked);
        setMonthPaymentsError("");
        if (event.target.checked) {
            setMaxMonthPayments(3); // Set to 3 if checkbox is checked
        } else {
            setMaxMonthPayments(0); // Optionally reset to 0 if unchecked
        }
    });

    const handleMaxPaymentsChange = useCallback(event => {
        if (event.target.value === "") {
            setMaxMonthPayments(0);
            return;
        }
        const value = parseInt(event.target.value);
        if (Number.isNaN(value)) return;
        setMaxMonthPayments(value);
        setMonthPaymentsError("");
    });

    const handleDeleteAutopayment = useCallback(() => {
        api.payment
            .deleteRecurrentPayment()
            .then(response => (response.ok ? fetchRecurrentPaymnets() : Promise.reject(response)))
            .catch(err => console.error(err));
    });

    const handleSwitchChange = event => {
        setSwitchState(event.target.checked);
        if (event.target.checked) fetchPayments();
        else fetchBalanceDetails();
    };

    return (
        <BillingLayout>
            <Stack spacing={2}>
                <Breadcrumbs>
                    <InternalLink underline="hover" color="inherit" to="/">
                        Главная
                    </InternalLink>
                    <Typography color="text.primary">Баланс</Typography>
                </Breadcrumbs>
                <Typography variant="h5">
                    Пополнение баланса
                    <IconButton
                        size="small"
                        color="inherit"
                        href="https://docs.amvera.ru/general/topup.html"
                        target="_blank"
                        rel="noreferrer"
                    >
                        <i className="fa-regular fa-circle-question" />
                    </IconButton>
                </Typography>
                <Grid container spacing={1}>
                    <Grid item pr={1}>
                        <Stack direction="column" spacing={1}>
                            <TextField
                                id="amvera-balance-edit"
                                label="Сумма пополнения"
                                value={topupAmount}
                                onChange={handleTopupEditChange}
                                autoFocus
                                disabled={loading}
                                required
                                sx={{ width: "35ch" }}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">₽</InputAdornment>
                                }}
                                inputProps={{
                                    inputMode: "numeric",
                                    pattern: "[0-9]*",
                                    style: { textAlign: "end" }
                                }}
                                error={error !== ""}
                                helperText={error}
                            />
                            <FormControlLabel
                                control={<Checkbox checked={isAutopayEnabled} onChange={handleAutopayChange} />}
                                label="Подключить автоплатеж"
                            />
                        </Stack>
                    </Grid>

                    {isAutopayEnabled && (
                        <Grid item>
                            <TextField
                                id="max-payments-edit"
                                label="Ограничение автоплатежей в месяц"
                                value={maxMonthPayments}
                                onChange={handleMaxPaymentsChange}
                                sx={{ width: "35ch" }}
                                inputProps={{
                                    inputMode: "numeric",
                                    pattern: "[0-9]*",
                                    style: { textAlign: "end" }
                                }}
                                autoComplete="off"
                                error={monthPaymentsError !== ""}
                                helperText={monthPaymentsError}
                            />
                        </Grid>
                    )}
                    <Grid item alignItems="stretch" style={{ display: "flex" }}>
                        <LoadingButton
                            size="large"
                            variant="contained"
                            startIcon={<i className="fa-regular fa-credit-card" />}
                            loading={loading}
                            loadingPosition="start"
                            sx={{ height: "56px", width: "100%" }}
                            onClick={handleTopupClick}
                        >
                            Пополнить
                        </LoadingButton>
                    </Grid>
                    {currentAutopayment?.amount && (
                        <Grid item xs={12}>
                            <Paper
                                elevation={2}
                                sx={{
                                    padding: 2,
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center"
                                }}
                            >
                                <Typography variant="body1">
                                    Подключенный автоплатеж: {currentAutopayment.amount} ₽
                                </Typography>
                                <Typography variant="body1">
                                    Максимальное число платежей в месяц: {currentAutopayment.monthPaymentLimit}
                                </Typography>

                                <Button
                                    variant="outlined"
                                    color="error"
                                    startIcon={<DeleteIcon />}
                                    onClick={handleDeleteAutopayment}
                                >
                                    Удалить
                                </Button>
                            </Paper>
                        </Grid>
                    )}
                </Grid>
                {!isEmpty(usedPromo) && (
                    <>
                        <Typography>Применяемые промокоды:</Typography>
                        <ul>
                            {usedPromo.map(description => (
                                <li>{description}</li>
                            ))}
                        </ul>
                    </>
                )}
                <Divider />
                <Typography variant="h5">Погашение промокодов</Typography>
                <Grid container>
                    <Grid item pr={1}>
                        <TextField
                            id="amvera-promocode-edit"
                            label="Промокод"
                            value={promo}
                            onChange={handlePromoEditChange}
                            disabled={promoLoading}
                            required
                            sx={{ width: "30ch" }}
                            inputProps={{
                                style: { textAlign: "end" }
                            }}
                            error={promoError !== ""}
                            helperText={promoError}
                        />
                    </Grid>
                    <Grid item alignItems="stretch" style={{ display: "flex" }}>
                        <LoadingButton
                            size="large"
                            variant="contained"
                            startIcon={<i className="fa-solid fa-gift" />}
                            loading={promoLoading}
                            loadingPosition="start"
                            sx={{ height: "56px" }}
                            onClick={handleApplyPromoClick}
                        >
                            Применить
                        </LoadingButton>
                    </Grid>
                </Grid>
                <Divider />
                <Typography variant="h5">Детализация баланса</Typography>
                <Grid
                    container
                    direction="row"
                    alignContent="flex-start"
                    alignItems="flex-start"
                    justifyContent="left"
                    justifyItems="left"
                >
                    <Grid item pr={2}>
                        <DatePicker
                            label="От"
                            ampm={false}
                            inputFormat="DD/MM/YYYY HH:MM"
                            value={periodStart}
                            onChange={handleChangePeriodStart}
                            renderInput={params => <TextField {...params} />}
                        />
                    </Grid>
                    <Grid item style={{ marginRight: "16px" }}>
                        <DatePicker
                            label="До"
                            ampm={false}
                            inputFormat="DD/MM/YYYY HH:MM"
                            value={periodEnd}
                            onChange={handleChangePeriodEnd}
                            renderInput={params => <TextField {...params} />}
                        />
                    </Grid>

                    <Grid item container xs alignItems="center" justifyContent="flex-start">
                        <Typography variant="body1">Списания</Typography>
                        <Switch checked={switchState} onChange={handleSwitchChange} />
                        <Typography variant="body1">Зачисления</Typography>
                    </Grid>
                </Grid>
                <Box sx={{ height: 400, width: "100%" }}>
                    <DataGrid
                        sx={{ overflowX: "scroll" }}
                        columns={columns}
                        rows={details}
                        getRowId={() => uuidV4()}
                        rowsPerPageOptions={[50, 75, 100]}
                        page={page}
                        pageSize={pageSize}
                        disableSelectionOnClick
                        onPageChange={handleChangePage}
                        onPageSizeChange={handleChangePageSize}
                        paginationMode="server"
                        rowCount={pageSize * (page + 2)}
                    />
                </Box>

                <Modal open={modalOpened} onClose={handleModalClose}>
                    <Box
                        sx={{
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                            width: 400,
                            bgcolor: "background.paper",
                            boxShadow: 24,
                            p: 4
                        }}
                    >
                        <Typography sx={{ m: 2 }}>{modalMessage}</Typography>
                        <Grid container justifyContent="flex-end">
                            <Button onClick={handleModalClose}>OK</Button>
                        </Grid>
                    </Box>
                </Modal>
            </Stack>
        </BillingLayout>
    );
};

export default BalancePage;
