import { Button } from "@mui/material";
import { useCallback, useMemo, useState } from "react";
import AddDialog from "./AddDialog";
import useCreateUser from "../api/useCreateUser";
import useIsDisabled from "../../SingleMySQLPage/hooks/useIsDisabled";

export default function AddUserButton({ setDetails, slug, dbName, status }) {
    const [isDialog, setIsDialog] = useState(false);
    const [userLoading, userCreate] = useCreateUser(slug, setDetails, dbName);

    const { isDisabled } = useIsDisabled();

    const handleOpenDialog = useCallback(() => {
        setIsDialog(true);
    }, []);

    const handleCloseDialog = useCallback(() => {
        setIsDialog(false);
    }, []);

    const handleAddUser = useCallback(
        user => {
            const { username, password } = user;
            userCreate(username, password).then(handleCloseDialog);
        },
        [handleCloseDialog, userCreate]
    );

    const isAddDisabled = useMemo(
        () => status === "CREATING" || status === "ERROR" || isDisabled,
        [status, isDisabled]
    );

    return (
        <>
            <Button
                disabled={isAddDisabled}
                size="small"
                sx={{ maxWidth: "fit-content" }}
                variant="contained"
                onClick={handleOpenDialog}
            >
                Добавить пользователя
            </Button>
            <AddDialog
                open={isDialog}
                onClose={handleCloseDialog}
                type="user"
                onClick={handleAddUser}
                loading={userLoading}
            />
        </>
    );
}
