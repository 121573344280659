import { useSnackbar } from "notistack";
import { useCallback, useEffect } from "react";
import { v4 as uuidV4 } from "uuid";

const notificationLocalStorageKey = "notifications";
export const activeMessageLocalStorageKey = "active-message";

export const usePersistentSnackbar = () => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const removeNotification = useCallback(id => {
        let notifications = JSON.parse(localStorage.getItem(notificationLocalStorageKey)) || [];
        notifications = notifications.filter(notification => notification.id !== id);
        localStorage.setItem(notificationLocalStorageKey, JSON.stringify(notifications));
    }, []);

    const showNotification = useCallback(
        (id, contentInfo, serviceName, stage, date) => {
            enqueueSnackbar("", {
                anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "right"
                },
                autoHideDuration: 120_000,
                variant: "errorLog",
                key: id,
                onClose: (event, reason, key) => {
                    if (reason === "clickaway") return;
                    removeNotification(key);
                },
                contentInfo,
                serviceName,
                stage,
                date
            });
        },
        [enqueueSnackbar, removeNotification]
    );

    useEffect(() => {
        const storedNotifications = JSON.parse(localStorage.getItem(notificationLocalStorageKey)) || [];

        storedNotifications.forEach(({ id, contentInfo, serviceName, stage, date }) => {
            showNotification(id, contentInfo, serviceName, stage, date);
        });
    }, [showNotification]);

    const addNotification = useCallback(
        (stage, serviceName, contentInfo) => {
            const notifications = JSON.parse(localStorage.getItem(notificationLocalStorageKey)) || [];
            const id = uuidV4();
            const date = new Date().toTimeString().split(" ")[0];
            const newNotification = { id, contentInfo, serviceName, stage, date };

            notifications.push(newNotification);

            if (notifications.length > 3) notifications.shift();
            localStorage.setItem(notificationLocalStorageKey, JSON.stringify(notifications));
            showNotification(id, contentInfo, serviceName, stage, date);
        },
        [showNotification]
    );

    const removeActiveMessage = useCallback(
        id => {
            localStorage.removeItem(activeMessageLocalStorageKey);
            if (id) {
                closeSnackbar(id);
            }
        },
        [closeSnackbar]
    );

    const addActiveMessage = useCallback(
        ({ id }) => {
            const onClose = () => {
                const storedActiveMessage = JSON.parse(localStorage.getItem(activeMessageLocalStorageKey)) || {};
                if (Object.keys(storedActiveMessage).length > 0) {
                    localStorage.setItem(
                        activeMessageLocalStorageKey,
                        JSON.stringify({ ...storedActiveMessage, closed: true })
                    );
                }
            };

            enqueueSnackbar("", {
                anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "right"
                },
                variant: "message",
                key: id,
                persist: true,
                preventDuplicate: true,
                onClose
            });
        },
        [enqueueSnackbar]
    );

    return { addNotification, addActiveMessage, removeActiveMessage };
};
