import BalanceEndpoint from "./HTTP/BalanceEndpoint";
import ProjectsEndpoint from "./HTTP/ProjectsEndpoint";
import TariffEndpoint from "./HTTP/TariffEndpoint";
import PromoEndpoint from "./HTTP/PromoEndpoint";
import EnvEndpoint from "./HTTP/EnvEndpoint";
import DomainEndpoint from "./HTTP/DomainEndpoint";
import FileStorageEndpoint from "./HTTP/FileStorageEndpoint";
import PaymentEndpoint from "./HTTP/PaymentEndpoint";
import PostgresEndpoint from "./HTTP/PostgresEndpoint";
import ConfigurationsEndPoint from "./HTTP/ConfigurationsEndPoint";
import GitControllerEndPoint from "./HTTP/GitControllerEndPoint";
import MySQLEndpoint from "./HTTP/MySQLEndpoint";
import BillingUlEndpoint from "./HTTP/BillingUlEndpoint";

export default class API {
    constructor(http) {
        this.billingUl = new BillingUlEndpoint(http, "/v1/billing/ul").configure().enableListAll().done();
        this.balance = new BalanceEndpoint(http, "/balance").configure().enableListAll().done();
        this.projects = new ProjectsEndpoint(http, "/projects")
            .configure()
            .enableListAll()
            .enableGetById()
            .enableCreate()
            .enableBranch()
            .enableDeleteById()
            .done();
        this.tariff = new TariffEndpoint(http, "/tariff").configure().enableListAll().done();
        this.promo = new PromoEndpoint(http, "/promo").configure().enableCreate().done();
        this.env = new EnvEndpoint(http, "/env");
        this.domain = new DomainEndpoint(http, "/domain");
        this.fileStorage = new FileStorageEndpoint(http, "/file-storage");
        this.payment = new PaymentEndpoint(http, "/payment");
        this.postgres = new PostgresEndpoint(http, "/postgresql")
            .configure()
            .enableCreate()
            .enableDeleteById()
            .enableListAll()
            .done();
        this.configurations = new ConfigurationsEndPoint(http, "/configurations").configure().enableListAll().done();
        this.gitController = new GitControllerEndPoint(http, "/git");
        this.mysql = new MySQLEndpoint(http, "/mysql")
            .configure()
            .enableCreate()
            .enableDeleteById()
            .enableListAll()
            .done();
    }
}
