import { useEffect, useState } from "react";
import { useServices } from "../../../../DI/hooks";
import useStatusApi from "../../../../Hooks/useStatusApi";

const useGetBackups = endpoint => {
    const { api } = useServices();
    const [_, error] = useStatusApi();
    const [backups, setBackups] = useState([]);
    const [getBackupsLoading, setGetBackupsLoading] = useState(false);

    useEffect(() => {
        const handleGetBackups = async () => {
            setGetBackupsLoading(true);
            try {
                const res = await endpoint();
                if (!res.ok) throw new Error(res.status);

                const data = await res.json();
                setBackups(data);
            } catch (e) {
                error(e.message, "Ошибка получения данных.");
            } finally {
                setGetBackupsLoading(false);
            }
        };

        handleGetBackups();
    }, [api.mysql, error, endpoint]);

    return { backups, setBackups, getBackupsLoading };
};

export default useGetBackups;
