import BillingDrawer from "../BillingDrawer";

const BillingLayout = ({ children }) => (
    <div>
        <BillingDrawer />
        <div>{children}</div>
    </div>
);

export default BillingLayout;
