import { Stack, Button, Link, DialogContentText } from "@mui/material";
import CodeBlock from "./CodeBlock";

const InfoTab = ({ project, config }) => (
    <div className="project-details-git">
        <Stack direction="row" spacing={2}>
            <Button
                variant="outlined"
                color="info"
                startIcon={<i className="fa-solid fa-circle-info" />}
                href="https://docs.amvera.ru/general/examples.html"
                target="_blank"
                rel="noreferrer"
            >
                Примеры развертывания
            </Button>
            <Button
                variant="outlined"
                color="tertiary"
                startIcon={<i className="fa-solid fa-circle-info" />}
                href="https://docs.amvera.ru/applications/configuration/heroku-migration.html"
                target="_blank"
                rel="noreferrer"
            >
                Миграция с Heroku
            </Button>
        </Stack>

        <div className="info-tab__block">
            <h3 className="info-tab__block_title">Конфигурационный файл</h3>

            <p className="info-tab__block_content">
                Для запуска проекта необходим конфигурационный файл{" "}
                <Link
                    href="https://docs.amvera.ru/applications/configuration/config-file.html"
                    target="_blank"
                    rel="nofollow"
                >
                    amvera.yml
                </Link>
                {", "}
                который можно написать вручную, сгенерировать через{" "}
                <Link href="https://manifest.amvera.ru" target="_blank" rel="nofollow">
                    специальный сервис
                </Link>{" "}
                или задать в разделе Конфигурация. Также поддерживается использование{" "}
                <Link
                    href="https://docs.amvera.ru/applications/configuration/docker.html"
                    target="_blank"
                    rel="nofollow"
                >
                    Dockerfile
                </Link>
                .
            </p>
        </div>

        <div className="info-tab__block">
            <h3 className="info-tab__block_title">Развертывание проекта</h3>
            <div className="info-tab__block_content">
                <p>
                    {`Проект использует для хранения кода Git-репозиторий, содержимое и данные для доступа в который
                доступны в разделе "Репозиторий".`}
                </p>
                <DialogContentText> Три основных способа разворачивания проекта: </DialogContentText>
                <ol className="info-tab__block_list">
                    <li>
                        Через push в Git-репозиторий с конфигурационным файлом. Необходимо склонировать или привязать
                        репозиторий проекта, добавить{" "}
                        <Link
                            href="https://docs.amvera.ru/applications/configuration/config-file.html"
                            target="_blank"
                            rel="nofollow"
                        >
                            файл конфигурации
                        </Link>{" "}
                        в его корень и сделать git push. Сборка проекта начнется автоматически.
                    </li>
                    <li>
                        {`Через push в Git-репозиторий и конфигурацией в интерфейсе. В разделе "Конфигурация" указать необходимые параметры и нажать "Применить", 
                    склонировать себе репозиторий, который теперь будет содержать конфигурационный файл, перенести необходимый код в этот репозиторий и сделать push.
                    Сборка проекта начнется автоматически.`}
                    </li>
                    <li>
                        {`Воспользоваться загрузчиком на странице "Репозиторий". Когда все данные будут загружены, заполнить раздел
                Конфигурация (если не загрузили конфигурационный файл) и нажать кнопки "Применить" и "Собрать".`}
                    </li>
                </ol>
            </div>
        </div>

        <div className="info-tab__block">
            <h3 className="info-tab__block_title">Домены и внутренние имена</h3>
            <div className="info-tab__block_content">
                <div className="info-tab__block_list">
                    <p>{`Подключить бесплатное, или привязать свое доменное имя можно в разделе "Настройки".`}</p>
                    <p>Внутреннее доменное имя (можно обратиться из другого проекта, должно слушать порт 80):</p>
                    <CodeBlock>
                        amvera-{project.ownerName}-run-{project.slug}
                    </CodeBlock>
                </div>
            </div>
        </div>

        <div className="info-tab__block">
            <h3 className="info-tab__block_title">Постоянное хранилище</h3>
            <div className="info-tab__block_content">
                <div className="info-tab__block_list">
                    <p>Папка постоянного хранилища:</p>
                    <CodeBlock>/data</CodeBlock>
                </div>
            </div>
        </div>
    </div>
);

export default InfoTab;
