import { useState, useEffect } from "react";
import {
    Select,
    Typography,
    FormControlLabel,
    Checkbox,
    TextField,
    MenuItem,
    Box,
    IconButton,
    FormControl,
    InputLabel,
    Grid,
    Stack
} from "@mui/material";

import { useServices } from "../../DI/hooks";
import TooltipTextField from "./TooltipTextField";
import tooltips from "../../constants/ConfigurationTab/configTooltipText.json";
import InfoToolTip from "./Tooltips/InfoTooltip";

const ConfigurationTab = ({ project, formData, setFormData, isNew = false }) => {
    const [environments, setEnvironments] = useState([]);
    const [selectedEnvironment, setSelectedEnvironment] = useState(null);
    const [configs, setConfigs] = useState([]);
    const [toolchains, setToolchains] = useState({});
    const [selectedToolchain, setSelectedToolchain] = useState(null);

    const [envVariables, setEnvVariables] = useState({});

    const { api } = useServices();

    useEffect(() => {
        let localConfigs = null;
        const userData = {};

        api.configurations
            .listAll()
            .then(response => (response.ok ? response.json() : Promise.reject()))
            .then(data => {
                if (isNew) delete data?.availableParameters?.db;
                setEnvironments(Object.keys(data.availableParameters));
                localConfigs = data.availableParameters;
                setConfigs(localConfigs);

                api.projects
                    .getConfig(project.slug)
                    .then(response => (response.ok ? response.json() : Promise.reject()))
                    .then(confData => {
                        const { environment } = confData.meta;
                        const toolchain = confData.meta.toolchain.name;
                        if (environment in data.availableParameters) {
                            setSelectedEnvironment(environment);
                            setToolchains(localConfigs[environment]);
                            if (toolchain in localConfigs[environment]) {
                                setSelectedToolchain(toolchain);

                                userData.meta = {
                                    version: confData.meta.toolchain.version,
                                    toolchain: confData.meta.toolchain.name,
                                    environment: environment
                                };
                                setFormData({ ...userData, build: { ...confData.build }, run: { ...confData.run } });
                                // removes container port
                                setFormData(p => {
                                    if (p.run?.servicePort) delete p.run.servicePort;
                                    return p;
                                });
                            }
                        }
                    })
                    .catch(error => {
                        console.error("Error fetching user config:", error);
                    });
            })
            .catch(error => {
                console.error("Error fetching toolchains:", error);
            });
    }, [project.slug]);

    const getEnvvars = selectedToolchain =>
        new Promise((resolve, reject) => {
            const hasEnvVar = envVariables
                ? Object.keys(envVariables).some(envvar => envvar === selectedToolchain)
                : "";

            if (isNew && selectedToolchain !== null && !hasEnvVar) {
                api.configurations
                    .getEnvvars(selectedEnvironment, selectedToolchain)
                    .then(response => {
                        if (response.headers.get("content-type")) {
                            return response.json();
                        }
                        return null;
                    })
                    .then(data => {
                        if (data) setEnvVariables(prev => ({ ...prev, [selectedToolchain]: { ...data } }));
                        else setEnvVariables(prev => ({ ...prev, [selectedToolchain]: null }));
                        resolve(data);
                    })
                    .catch(error => {
                        console.log(error);
                        reject();
                    });
            } else resolve(envVariables[selectedToolchain]);
        });

    const handleEnvironmentChange = event => {
        setSelectedToolchain(null);
        setFormData({});
        setSelectedEnvironment(event.target.value);
        setToolchains(configs[event.target.value]);
    };

    // Handle toolchain selection
    const handleToolchainChange = event => {
        const selected = event.target.value;

        // Populate formData with default values from the selected toolchain
        const defaultFormData = {};
        const toolchainData = toolchains[selected];
        if (toolchainData) {
            for (const sectionName in toolchainData) {
                if (sectionName) {
                    const section = toolchainData[sectionName];
                    defaultFormData[sectionName] = {};
                    for (const fieldName in section) {
                        if (fieldName) {
                            const fieldData = section[fieldName];
                            if ("default" in fieldData && fieldData.default) {
                                defaultFormData[sectionName][fieldName] =
                                    fieldData.type === "Bool"
                                        ? JSON.parse(fieldData.default)
                                        : fieldData.type === "Map"
                                        ? {
                                              [fieldData.default.split(": ")[0]]: fieldData.default.split(": ")[1]
                                          }
                                        : fieldData.type === "Integer"
                                        ? Number(fieldData.default)
                                        : fieldData.default;
                            }
                        }
                    }
                }
            }
        }
        defaultFormData.meta.environment = selectedEnvironment;
        defaultFormData.meta.toolchain = selected;

        getEnvvars(selected)
            .then(data => {
                if (isNew && data) {
                    defaultFormData.envvar = Object.entries(data).reduce((acc, [key, value]) => {
                        if (value?.default) acc[key] = { ...value, text: value?.default };
                        return acc;
                    }, {});
                }
                setSelectedToolchain(selected);
                setFormData(defaultFormData);
            })
            .catch(error => console.log(error));
    };

    const handleFieldChange = (section, fieldName, value) => {
        if (value) {
            if (section === "envvar")
                setFormData(prevData => ({
                    ...prevData,
                    [section]: {
                        ...prevData[section],
                        [fieldName]: { text: value, ...envVariables[selectedToolchain][fieldName] }
                    }
                }));
            else
                setFormData(prevData => ({
                    ...prevData,
                    [section]: {
                        ...prevData[section],
                        [fieldName]: value
                    }
                }));
        } else {
            const copyOfSection = formData[section];
            delete copyOfSection[fieldName];
            setFormData(prevData => ({
                ...prevData,
                [section]: copyOfSection
            }));
        }
    };

    const handleKeyValueChange = (section, fieldName, key, value, index) => {
        const updatedFormData = Object.entries(formData[section][fieldName] || { "": "" });
        updatedFormData[index] = [key, value];
        if (updatedFormData.filter(item => item[0] !== "").length === 0) {
            const copyOfForm = formData[section];
            delete copyOfForm[fieldName];
            setFormData(prevData => ({
                ...prevData,
                [section]: {
                    ...copyOfForm
                }
            }));
        } else
            setFormData(prevData => ({
                ...prevData,
                [section]: {
                    ...prevData[section],
                    [fieldName]: Object.fromEntries(updatedFormData)
                }
            }));
    };

    // Generate form fields based on the selected toolchain
    const generateFormFields = () => {
        const toolchainData =
            isNew && envVariables[selectedToolchain]
                ? { ...toolchains[selectedToolchain], envvar: { ...envVariables[selectedToolchain] } }
                : toolchains[selectedToolchain];

        return (
            <Grid container spacing={2}>
                {Object.keys(toolchainData).map(section => (
                    <>
                        {toolchainData[section] && (
                            <Grid item xs={12} sm={6} key={section}>
                                <Box mb={4}>
                                    <Typography variant="h6" gutterBottom>
                                        {section}
                                    </Typography>
                                    {toolchainData[section] &&
                                        Object.entries(toolchainData[section]).map(([fieldName, fieldData]) => (
                                            <Box key={fieldName} mb={2}>
                                                {fieldData.type === "Bool" ? (
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                id={`${section}.${fieldName}`}
                                                                checked={formData[section]?.[fieldName] || false}
                                                                onChange={e =>
                                                                    handleFieldChange(
                                                                        section,
                                                                        fieldName,
                                                                        e.target.checked
                                                                    )
                                                                }
                                                            />
                                                        }
                                                        label={fieldName}
                                                    />
                                                ) : fieldData.type === "Map" ? (
                                                    <Box>
                                                        {Object.entries(
                                                            formData[section]?.[fieldName] || { "": "" }
                                                        ).map(([key, value], index) => (
                                                            <Box key={index} mb={2}>
                                                                <Stack direction="row" alignItems="center" mb={2}>
                                                                    <Typography variant="body1">{fieldName}</Typography>
                                                                    <InfoToolTip
                                                                        title={
                                                                            tooltips?.[selectedEnvironment]?.[
                                                                                selectedToolchain
                                                                            ]?.[section]?.[fieldName] ??
                                                                            tooltips?.[selectedEnvironment]?.[
                                                                                section
                                                                            ]?.[fieldName]
                                                                        }
                                                                    />
                                                                </Stack>
                                                                <Box>
                                                                    <TextField
                                                                        sx={{ width: "80%", mb: "16px" }}
                                                                        type="text"
                                                                        value={key}
                                                                        label={`${fieldName} key`}
                                                                        onChange={e =>
                                                                            handleKeyValueChange(
                                                                                section,
                                                                                fieldName,
                                                                                e.target.value,
                                                                                value,
                                                                                index
                                                                            )
                                                                        }
                                                                    />
                                                                    <TextField
                                                                        sx={{ width: "80%" }}
                                                                        type="text"
                                                                        value={value}
                                                                        label={`${fieldName} value`}
                                                                        disabled={!key}
                                                                        onChange={e =>
                                                                            handleKeyValueChange(
                                                                                section,
                                                                                fieldName,
                                                                                key,
                                                                                e.target.value,
                                                                                index
                                                                            )
                                                                        }
                                                                    />
                                                                </Box>
                                                            </Box>
                                                        ))}
                                                    </Box>
                                                ) : (
                                                    <TooltipTextField
                                                        helperText={
                                                            tooltips?.[selectedEnvironment]?.[selectedToolchain]?.[
                                                                section
                                                            ]?.[fieldName]?.helperText ??
                                                            tooltips?.[selectedEnvironment]?.[section]?.[fieldName]
                                                                ?.helperText
                                                        }
                                                        sx={{
                                                            width: "80%",
                                                            display: fieldName === "servicePort" ? "none" : null
                                                        }}
                                                        type={fieldData.type === "Integer" ? "number" : "text"}
                                                        id={`${section}.${fieldName}`}
                                                        value={
                                                            formData[section]?.[fieldName]?.text ||
                                                            formData[section]?.[fieldName] ||
                                                            ""
                                                        }
                                                        onChange={e =>
                                                            handleFieldChange(section, fieldName, e.target.value)
                                                        }
                                                        disabled={
                                                            fieldData.blockedBy &&
                                                            formData[section]?.[fieldData.blockedBy || false]
                                                        }
                                                        label={fieldName}
                                                        variant="outlined"
                                                    >
                                                        <InfoToolTip
                                                            title={
                                                                tooltips?.[selectedEnvironment]?.[selectedToolchain]?.[
                                                                    section
                                                                ]?.[fieldName]?.info ??
                                                                tooltips?.[selectedEnvironment]?.[selectedToolchain]?.[
                                                                    section
                                                                ]?.[fieldName] ??
                                                                tooltips?.[selectedEnvironment]?.[section]?.[fieldName]
                                                                    ?.info ??
                                                                tooltips?.[selectedEnvironment]?.[section]?.[fieldName]
                                                            }
                                                        />
                                                    </TooltipTextField>
                                                )}
                                            </Box>
                                        ))}
                                </Box>
                            </Grid>
                        )}
                    </>
                ))}
            </Grid>
        );
    };

    return (
        <>
            <Typography variant="h6" mb={3}>
                Конфигурационный файл
                <IconButton
                    size="small"
                    color="inherit"
                    href="https://docs.amvera.ru/applications/configuration/config-file.html"
                    target="_blank"
                    rel="noreferrer"
                >
                    <i className="fa-regular fa-circle-question" />
                </IconButton>
            </Typography>

            <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                    <FormControl variant="outlined" style={{ width: "80%" }}>
                        <InputLabel htmlFor="environmentSelect">Выберите окружение</InputLabel>
                        <Select
                            label="SelectTheEnvironment"
                            id="environmentSelect"
                            value={selectedEnvironment || ""}
                            onChange={handleEnvironmentChange}
                        >
                            {environments.map(environment => (
                                <MenuItem key={environment} value={environment}>
                                    {environment}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControl variant="outlined" style={{ width: "80%" }}>
                        <InputLabel htmlFor="toolchainSelect">Выберите инструмент</InputLabel>
                        <Select
                            label="Select from Toolchains"
                            id="toolchainSelect"
                            value={selectedToolchain || ""}
                            onChange={handleToolchainChange}
                            disabled={selectedEnvironment === null}
                        >
                            {Object.keys(toolchains).map(toolchain => (
                                <MenuItem key={toolchain} value={toolchain}>
                                    {toolchain}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>

            <Box mt={2}>{selectedToolchain ? generateFormFields() : null}</Box>
        </>
    );
};

export default ConfigurationTab;
