import { Stack, Typography } from "@mui/material";
import { useCallback } from "react";
import { DeleteBackupButton } from "./DeleteBackupButton";
import { RestoreBackupButton } from "./RestoreBackupButton";
import StatusDisplay from "../StatusDisplay";

export default function BackupController({ backupInfo, setBackups, deleteBackup, restoreBackup, isDisabled }) {
    const handleRemoveBackup = useCallback(
        backupName => {
            setBackups(prev => prev.filter(({ name }) => name !== backupName));
        },
        [setBackups]
    );

    return (
        <Stack padding="0 16px 8px">
            <Stack direction="row" justifyContent="space-between" alignItems="center">
                <Stack direction="row" spacing={1} alignItems="center">
                    <Typography>{backupInfo.name}</Typography>
                    <StatusDisplay status={backupInfo.status} />
                </Stack>
                <Stack direction="row">
                    <RestoreBackupButton
                        status={backupInfo?.status}
                        backupName={backupInfo.name}
                        restoreBackup={restoreBackup}
                        isDisabled={isDisabled}
                    />
                    <DeleteBackupButton
                        backupName={backupInfo.name}
                        removeBackup={handleRemoveBackup}
                        deleteBackup={deleteBackup}
                        isDisabled={isDisabled}
                    />
                </Stack>
            </Stack>
            {!backupInfo?.isScheduled && (
                <Typography marginLeft={2} color="secondary.text" variant="caption">
                    {backupInfo.description}
                </Typography>
            )}
        </Stack>
    );
}
