import Endpoint from "./Endpoint";

class GitControllerEndPoint extends Endpoint {
    getEvent(serviceId) {
        return this.http.get(`${this.address}/event/${serviceId}`);
    }

    addEvent(event) {
        return this.http.post(`${this.address}/event`, event);
    }

    editEvent(event) {
        return this.http.put(`${this.address}/event`, event);
    }

    deleteEvent(serviceId) {
        return this.http.delete(`${this.address}/event/${serviceId}`);
    }
}

export default GitControllerEndPoint;
