import { useCallback, useState } from "react";

const useAddNewBackup = (setBackups, endpoint) => {
    const [addError, setAddError] = useState("");
    const [addLoading, setAddLoading] = useState(false);

    const handleNewBackup = useCallback(
        newBackup => {
            setBackups(prev => [...prev, newBackup]);
        },
        [setBackups]
    );

    const handleAddBackup = useCallback(
        async backupData => {
            setAddLoading(true);
            try {
                const res = await endpoint(backupData);
                if (!res.ok) throw Error(res.status);

                const data = await res.json();

                handleNewBackup(data);
            } catch (e) {
                setAddError("Произошла ошибка в создании бэкапа.");
            } finally {
                setAddLoading(false);
            }
        },
        [endpoint, handleNewBackup]
    );

    return { addError, addLoading, handleAddBackup };
};

export default useAddNewBackup;
