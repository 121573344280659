import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";

const SimpleDialog = ({ open, onSave, onClose, title, content, confirmBtnText = "Подтвердить" }) => (
    <Dialog open={open} onClose={onClose}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent dividers>{content}</DialogContent>
        <DialogActions sx={{ justifyContent: "space-around", my: "5px" }}>
            <Button variant="outlined" onClick={onClose} size="small" sx={{ width: "200px" }}>
                Отмена
            </Button>
            <Button variant="contained" onClick={onSave} size="small" sx={{ width: "200px" }}>
                {confirmBtnText}
            </Button>
        </DialogActions>
    </Dialog>
);

export default SimpleDialog;
