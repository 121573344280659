import { useEffect, useState } from "react";
import { useServices } from "../../../DI/hooks";

const useGetServiceResources = slug => {
    const { api } = useServices();
    const [data, setData] = useState({});
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        async function getServiceResources() {
            setIsLoading(true);
            try {
                const response = await api.projects.getServiceResources(slug);
                const resData = await response.json();

                setData(resData);
            } catch (e) {
                setError(e.message);
            } finally {
                setIsLoading(false);
            }
        }
        getServiceResources();

        return () => {};
    }, [api.projects, slug]);

    return [data, error, isLoading];
};

export default useGetServiceResources;
