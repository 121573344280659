import Endpoint from "./Endpoint";

class MySQLEndpoint extends Endpoint {
    pauseDb(params) {
        return this.http.post(`${this.address}/${params.serviceSlug}/pause`);
    }

    resumeDb(params) {
        return this.http.post(`${this.address}/${params.serviceSlug}/resume`);
    }

    getTariff(slug) {
        return this.http.get(`${this.address}/${slug}/tariff`);
    }

    updateTariff(slug, tariff) {
        this.http.post(`${this.address}/${slug}/tariff`, {
            ...tariff
        });
    }

    createUser(user) {
        return this.http.post(`${this.address}/user`, user);
    }

    createDb(db) {
        return this.http.post(`${this.address}/db`, db);
    }

    scaleBySlug(slug, instances) {
        return this.http.post(`${this.address}/${slug}/scale`, {
            instances: instances
        });
    }

    getDetails(slug) {
        return this.http.get(`${this.address}/details/${slug}`);
    }

    deleteDb(slug, dbName) {
        return this.http.delete(`${this.address}/db/${slug}/${dbName}`);
    }

    deleteUser(slug, database, userName) {
        return this.http.delete(`${this.address}/user/${slug}/${database}/${userName}`);
    }

    getBackups(serviceSlug) {
        return this.http.get(`${this.address}/backup/${serviceSlug}`);
    }

    addBackup(data) {
        return this.http.post(`${this.address}/backup`, data);
    }

    changeScheduledBackup(serviceSlug, isScheduledBackupEnabled) {
        return this.http.put(`${this.address}`, {
            serviceSlug,
            isScheduledBackupEnabled
        });
    }

    deleteBackup(backupName) {
        return this.http.delete(`${this.address}/backup/${backupName}`);
    }

    restoreBackup(serviceSlug, backupName) {
        return this.http.post(`${this.address}/backup/restore`, {
            serviceSlug,
            backupName
        });
    }
}

export default MySQLEndpoint;
