import { Navigate } from "react-router-dom";
import { useServices } from "../../DI/hooks";

const AuthenticatedRedirectPage = () => {
    const { auth } = useServices();

    const redirectUrl =
        auth.activeNavigator === "signoutRedirect" ? "/" : window.sessionStorage.getItem("next-url") || "/";

    window.sessionStorage.removeItem("next-url");

    return <Navigate to={redirectUrl} />;
};

export default AuthenticatedRedirectPage;
