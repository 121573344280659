import Endpoint from "./Endpoint";

class ProjectsEndpoint extends Endpoint {
    getConfig(slug) {
        return this.http.get(`${this.address}/${slug}/config`, {});
    }

    getService(id) {
        return this.http.get(`${this.address}/${id}/service`);
    }

    getTariff(slug) {
        return this.http.get(`${this.address}/${slug}/tariff`);
    }

    getAlerts(slug) {
        return this.http.get(`${this.address}/${slug}/notification_settings`);
    }

    setAlerts(slug, body) {
        return this.http.post(`${this.address}/${slug}/notification_settings`, body);
    }

    scaleById(id, instances) {
        return this.http.post(`${this.address}/${id}/scale`, {
            instances: instances
        });
    }

    rebuildById(id) {
        return this.http.post(`${this.address}/${id}/rebuild`, {});
    }

    restartById(id) {
        return this.http.post(`${this.address}/${id}/restart`, {});
    }

    updateTariffById(id, tariffId) {
        return this.http.post(`${this.address}/${id}/tariff`, tariffId);
    }

    freeze(slug) {
        return this.http.put(`${this.address}/${slug}/freeze`);
    }

    getServiceResources(slug) {
        return this.http.get(`${this.address}/${slug}/service_resources`);
    }

    getAutoScaling(slug) {
        return this.http.get(`${this.address}/${slug}/autoscaling`);
    }

    changeAutoScaling(slug, scaling) {
        return this.http.post(`${this.address}/${slug}/autoscaling`, scaling);
    }

    stopBuild(slug) {
        return this.http.post(`${this.address}/${slug}/stop-build`);
    }
}

export default ProjectsEndpoint;
