import { Delete, Edit } from "@mui/icons-material";
import { Paper, useMediaQuery, useTheme } from "@mui/material";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import { useSnackbar } from "notistack";
import { useCallback } from "react";
import { SNACKBAR_TYPE } from "../../../constants";
import { hasText } from "../../../utils";

const EnvironmentVariableTable = ({ variables, onEdit, onDelete }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.up("sm"));
    const { enqueueSnackbar } = useSnackbar();

    const columns = [
        {
            type: "string",
            field: "name",
            headerName: "Название",
            headerAlign: "center",
            asd: "asd",
            flex: 1,
            valueFormatter: params => params?.value ?? "-"
        },
        {
            type: "string",
            field: "value",
            headerName: "Значение",
            headerAlign: "center",
            flex: 1,
            valueFormatter: params => params?.value ?? "******"
        },
        {
            type: "boolean",
            field: "isSecret",
            headerName: "Это секрет",
            headerAlign: "center",
            flex: "0.4 0 auto"
        },
        {
            type: "actions",
            field: "actions",
            headerName: "Действия",
            headerAlign: "center",
            flex: "0.4 0 auto",
            getActions: ({ row }) => [
                <GridActionsCellItem label="Edit" icon={<Edit onClick={() => onEdit(row)} />} />,
                <GridActionsCellItem label="Delete" icon={<Delete onClick={() => onDelete(row)} />} />
            ]
        }
    ];

    const handleCellClick = useCallback(
        ({ field, value }) => {
            if (field !== "name" && field !== "value") return;
            if (!hasText(value)) return;
            navigator.clipboard.writeText(value).then(() => enqueueSnackbar("Скопировано в буфер", SNACKBAR_TYPE.INFO));
        },
        [enqueueSnackbar]
    );

    return (
        <Paper sx={{ height: 400, width: "100%" }}>
            <DataGrid
                disableColumnMenu
                disableColumnSelector
                disableDensitySelector
                hideFooterSelectedRowCount
                columns={columns}
                rows={variables}
                onCellClick={handleCellClick}
                columnVisibilityModel={{ isSecret: isMobile }}
            />
        </Paper>
    );
};

export default EnvironmentVariableTable;
