import { TableCell, TableSortLabel, Box } from "@mui/material";
import { visuallyHidden } from "@mui/utils";

function TableCellWithSort({ children, direction, onClick, width, active }) {
    return (
        <TableCell sortDirection={active ? direction : false} width={width}>
            <TableSortLabel active={active} direction={active ? direction : "asc"} onClick={onClick}>
                {children}
                {active ? (
                    <Box component="span" sx={visuallyHidden}>
                        {direction === "asc" ? "sorted ascending" : "sorted descending"}
                    </Box>
                ) : null}
            </TableSortLabel>
        </TableCell>
    );
}

export default TableCellWithSort;
