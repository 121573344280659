import { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import { useServices } from "../../../DI/hooks";
import { SNACKBAR_TYPE } from "../../../constants";

const useGetAutoScaling = slug => {
    const { enqueueSnackbar } = useSnackbar();
    const { api } = useServices();
    const [data, setData] = useState({});
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        async function getAutoScaling() {
            setIsLoading(true);
            try {
                const response = await api.projects.getAutoScaling(slug);
                if (!response.ok) {
                    throw Error(response.status);
                }

                const resData = await response.json();

                setData(resData);
            } catch (e) {
                setError(e.message);
                enqueueSnackbar(`${e.message}: Произошла ошибка`, SNACKBAR_TYPE.ERROR);
            } finally {
                setIsLoading(false);
            }
        }
        getAutoScaling();

        return () => {};
    }, [enqueueSnackbar, slug, api]);

    return [data, error, isLoading];
};

export default useGetAutoScaling;
