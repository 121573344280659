import { styled } from "@mui/material/styles";
import { CircularProgress, Switch } from "@mui/material";
import { useCallback } from "react";

const LoadingDiv = styled("div")(({ theme, isDisabled }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "20px",
    height: "20px",
    borderRadius: "50%",
    backgroundColor: isDisabled ? theme.palette.action.disabled : theme.palette.primary.contrastText
}));

const Icon = ({ loading, isDisabled }) => (
    <LoadingDiv isDisabled={isDisabled}>
        {loading && <CircularProgress size={14} color="secondary" thickness={6} />}
    </LoadingDiv>
);

const LoadingSwitch = ({ loading, defaultChecked, onChange, isDisabled = false }) => {
    const handleOnSwitch = useCallback(
        e => {
            const enabled = e.target.checked;
            onChange(enabled);
        },
        [onChange]
    );

    return (
        <Switch
            checkedIcon={<Icon isDisabled={isDisabled} loading={loading} />}
            icon={<Icon isDisabled={isDisabled} loading={loading} />}
            onChange={handleOnSwitch}
            defaultChecked={defaultChecked}
            disabled={isDisabled}
        />
    );
};

export default LoadingSwitch;
