import { Box, Breadcrumbs, Skeleton, Stack } from "@mui/material";
import InternalLink from "../../Elements/InternalLink";

const SingleProjectPageSkeleton = () => (
    <Stack spacing={2}>
        <Breadcrumbs>
            <InternalLink underline="hover" color="inherit" to="/">
                Главная
            </InternalLink>
            <InternalLink underline="hover" color="inherit" to="/projects">
                Проекты
            </InternalLink>
            <Skeleton animation="wave" variant="text" width="20ch" />
        </Breadcrumbs>
        <Stack direction="row" spacing={2} sx={{ alignItems: "center" }}>
            <Skeleton animation="wave" variant="rounded" width="25ch" />
            <Skeleton animation="wave" variant="rounded" width="15ch" />
        </Stack>
        <Skeleton animation="wave" variant="rectangular" width="20ch" height="3em" />
        <Box sx={{ width: "100%" }}>
            <Stack spacing={2}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <Stack direction="row" spacing={2}>
                        <Skeleton animation="wave" variant="rounded" width="10ch" />
                        <Skeleton animation="wave" variant="rounded" width="10ch" />
                        <Skeleton animation="wave" variant="rounded" width="10ch" />
                        <Skeleton animation="wave" variant="rounded" width="10ch" />
                    </Stack>
                </Box>
                <Skeleton animation="wave" variant="text" width="50%" />
                <Skeleton animation="wave" variant="rounded" width="100%" height="3em" />
                <Skeleton animation="wave" variant="text" width="50%" />
                <Skeleton animation="wave" variant="rounded" width="100%" height="3em" />
            </Stack>
        </Box>
    </Stack>
);
export default SingleProjectPageSkeleton;
