import { createSelector } from "@reduxjs/toolkit";

const selectProjectsState = state => state.projects;

const selectProjects = createSelector([selectProjectsState], projectsState => projectsState.projects);

export const selectProjectByName = createSelector(
    [selectProjects, (state, projectName) => projectName],
    (projects, projectName) => projects.find(project => project.name === projectName)
);
