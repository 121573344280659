import { useSnackbar } from "notistack";
import { useCallback } from "react";
import { SNACKBAR_TYPE } from "../constants";
import { isNotExists } from "../utils";

const useHandleError = () => {
    const { enqueueSnackbar } = useSnackbar();

    const handleApiError = useCallback(
        e => {
            if (isNotExists(e)) return;
            if (!("status" in e)) return;
            switch (e.status) {
                case 429:
                    enqueueSnackbar("Превышен лимит запросов", SNACKBAR_TYPE.ERROR);
                    break;
                default:
                    console.error(e);
                    enqueueSnackbar("Произошла ошибка", SNACKBAR_TYPE.ERROR);
            }
        },
        [enqueueSnackbar]
    );

    return {
        handleApiError
    };
};

export default useHandleError;
