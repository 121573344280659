import { Box, Card, CardActionArea, CardContent, CircularProgress, Skeleton, Stack, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { useServices } from "../../../DI/hooks";
import { ResourceStatus } from "../../../features/projects";

const ProjectItem = ({ project }) => {
    const { decodeBuildStatus } = useServices();

    const linkTo =
        project?.serviceType === "compute"
            ? `/projects/${project.serviceType}/${project.slug}`
            : `/projects/db/${project.serviceType}/${project.slug}`;
    return (
        <Card sx={{ overflow: "visible" }}>
            <CardActionArea component={Link} to={linkTo}>
                <CardContent>
                    <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        flexWrap="wrap"
                        useFlexGap
                        gap={2}
                    >
                        <Stack spacing={2}>
                            <Stack direction="row" useFlexGap gap={2} minWidth={360} alignItems="center">
                                <Typography variant="h6">{project.name}</Typography>
                                {(project.status === "CREATING" ||
                                    project.status === "BUILD_STARTED" ||
                                    project.status === "DEPLOYING" ||
                                    project.status === "STARTING") && <CircularProgress size="1em" color="inherit" />}
                                {decodeBuildStatus(project.status)}
                            </Stack>
                            <Stack direction="row" spacing={2} sx={{ alignItems: "center" }}>
                                <Typography variant="p">
                                    Инстансов: {project?.instances ?? 0}/{project?.requiredInstances ?? 1}
                                </Typography>
                            </Stack>
                        </Stack>
                        {project?.serviceType !== "cnpg" && (
                            <Box>
                                <ResourceStatus slug={project.slug} />
                            </Box>
                        )}
                    </Stack>
                </CardContent>
            </CardActionArea>
        </Card>
    );
};

export const ProjectItemSkeleton = () => (
    <Card>
        <CardContent>
            <Stack spacing={2}>
                <Stack direction="row" spacing={2} sx={{ alignItems: "center" }}>
                    <Skeleton animation="wave" variant="rounded" width="25ch" />
                    <Skeleton animation="wave" variant="rounded" width="15ch" />
                </Stack>
                <Stack direction="row" spacing={2} sx={{ alignItems: "center" }}>
                    <Skeleton animation="wave" variant="text" width="14ch" />
                    <Skeleton animation="wave" variant="text" width="8ch" />
                    <Skeleton animation="wave" variant="text" width="10ch" />
                    <Skeleton animation="wave" variant="text" width="2ch" />
                </Stack>
            </Stack>
        </CardContent>
    </Card>
);

export default ProjectItem;
