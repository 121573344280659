import { useCallback, useEffect, useMemo, useState } from "react";
import { useAuth } from "react-oidc-context";
import { useDispatch, useSelector } from "react-redux";
import { useConfig } from "../../Configuration/hooks";
import useHandleError from "../../Hooks/useHandleError";
import HttpService from "../../Services/HttpService";
import { getFromSessionStorage, hasText, isNotArray, convertLogs, paramsToQueryString } from "../../utils";
import Terminal from "./Terminal";
import { setBuildLogsLoading } from "../../Store/CurrentProjectSlice";

const BuildOutputTab = ({ project }) => {
    const sessionKey = `${project.name}-build-logs`;

    const auth = useAuth();
    const config = useConfig();
    const dispatch = useDispatch();
    const currentProject = useSelector(state => state?.currentProject ?? null);

    const http = useMemo(() => new HttpService(auth, config.notificationsApi), [auth, config]);

    const { handleApiError } = useHandleError();

    const [eventSource, setEventSource] = useState(null);
    const [resumeToken, setResumeToken] = useState(null);
    const [content, setContent] = useState(getFromSessionStorage(sessionKey));
    const [historyLoading, setHistoryLoading] = useState(false);
    const [historyOffsetTimestamp, setHistoryOffsetTimestamp] = useState(null);

    const addLineToStart = useCallback(
        line => {
            setContent(previousState => `${line}\n${previousState}`);
            try {
                sessionStorage.setItem(sessionKey, `${line}\n${getFromSessionStorage(sessionKey)}`);
            } catch {
                sessionStorage.setItem(sessionKey, line);
            }
        },
        [sessionKey]
    );

    const addLineToEnd = useCallback(
        line => {
            setContent(previousState => `${previousState}\n${line}`);
            try {
                sessionStorage.setItem(sessionKey, `${getFromSessionStorage(sessionKey)}\n${line}`);
            } catch {
                sessionStorage.setItem(sessionKey, line);
            }
        },
        [sessionKey]
    );

    const handleMessage = useCallback(
        event => {
            const data = JSON.parse(event.data);
            setResumeToken(data?.resumeToken ?? null);
            if (hasText(data?.content)) addLineToEnd(convertLogs(data));
        },
        [addLineToEnd]
    );

    const connectToEventSource = useCallback(() => {
        http.post("/events", {})
            .then(response => (response.ok ? response.json() : Promise.reject()))
            .then(data => {
                const params = {
                    session: data.sessionId,
                    project: project.slug,
                    resumeToken
                };
                const newEventSource = new EventSource(
                    `${config.notificationsApi}/events/logs/build${paramsToQueryString(params)}`
                );
                newEventSource.onmessage = handleMessage;
                newEventSource.onerror = connectToEventSource;

                setEventSource(newEventSource);

                console.log("Connected to log stream");
            })
            .catch(error => handleApiError(error));
    }, [resumeToken, project, config, http, handleMessage, handleApiError]);

    const clearContent = useCallback(() => {
        setContent("");
        setHistoryOffsetTimestamp(null);
        sessionStorage.setItem(sessionKey, "");
    }, [sessionKey]);

    const loadHistory = useCallback(
        linesToLoad => {
            console.log("History: ", currentProject.buildLogsLoading);

            if (true) {
                setHistoryLoading(true);
                dispatch(setBuildLogsLoading(true));
                console.log("History3: ", currentProject.buildLogsLoading);
                const params = {
                    username: auth?.user?.profile?.preferred_username,
                    serviceName: project.slug,
                    timestamp: historyOffsetTimestamp,
                    limit: linesToLoad
                };
                http.get(`/logs/build/history${paramsToQueryString(params)}`)
                    .then(response => (response.ok ? response.json() : Promise.reject()))
                    .then(data => {
                        if (data.length) clearContent();
                        if (isNotArray(data)) return;
                        data.map(log => convertLogs(log)).forEach(addLineToStart);
                        setHistoryLoading(false);
                        dispatch(setBuildLogsLoading(false));
                        setHistoryOffsetTimestamp(data[data.length - 1].timestamp);
                    })
                    .catch(error => {
                        setHistoryLoading(false);
                        dispatch(setBuildLogsLoading(false));
                        handleApiError(error);
                    });
            }
        },
        [historyOffsetTimestamp, project, auth, http, addLineToStart, handleApiError, clearContent]
    );

    useEffect(() => {
        if (eventSource == null) connectToEventSource();
    }, [eventSource, connectToEventSource]);

    useEffect(
        () => () => {
            eventSource?.close();
        },
        [eventSource]
    );

    return (
        <Terminal
            content={content}
            historyLoading={historyLoading}
            onClickLoadHistory={loadHistory}
            onClickClearContent={clearContent}
        />
    );
};

export default BuildOutputTab;
