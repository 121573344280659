import { Backdrop, CircularProgress, Typography } from "@mui/material";
import { useServices } from "../DI/hooks";
import InitialRequester from "./InitialRequester";
import UnauthenticatedRouter from "./UnauthenticatedRouter";

const EntryPoint = () => {
    const { auth } = useServices();

    void auth.clearStaleState();

    // eslint-disable-next-line default-case
    switch (auth.activeNavigator) {
        // case "signinSilent":
        //     return (
        //         <Backdrop open>
        //             <Typography>Входим...</Typography>
        //             <CircularProgress color="inherit" />
        //         </Backdrop>
        //     );
        case "signoutRedirect":
            return (
                <Backdrop open>
                    <Typography>Выходим...</Typography>
                    <CircularProgress color="inherit" />
                </Backdrop>
            );
    }

    if (auth.isLoading)
        return (
            <Backdrop open>
                <CircularProgress color="inherit" />
            </Backdrop>
        );

    if (auth.error) {
        console.error(auth.error.message);
        window.location.replace("/projects");
        return (
            <Backdrop open>
                <CircularProgress color="inherit" />
            </Backdrop>
        );
    }

    if (auth.isAuthenticated) return <InitialRequester />;

    return <UnauthenticatedRouter />;
};

export default EntryPoint;
