import { useCallback, useState } from "react";
import useStatusApi from "../../../../Hooks/useStatusApi";

const usePutBackup = endpoint => {
    const [success, error] = useStatusApi();
    const [backupLoading, setBackupLoading] = useState(false);

    const handlePutBackup = useCallback(
        async enabled => {
            setBackupLoading(true);
            try {
                const res = await endpoint(enabled);

                if (!res.ok) throw Error(res.status);

                success(`Ежедневные бэкапы ${enabled ? "включены" : "выключены"}.`);
            } catch (e) {
                error(e.message, "Ошибка изменения ежедневынх бэкапов.");
            } finally {
                setBackupLoading(false);
            }
        },
        [error, success, endpoint]
    );

    return { handlePutBackup, backupLoading };
};

export default usePutBackup;
