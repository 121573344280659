import Endpoint from "./Endpoint";

export default class DomainEndpoint extends Endpoint {
    getAll(projectSlug) {
        return this.http.get(`${this.address}/${projectSlug}`);
    }

    activateDefault(slug, ingressPorts) {
        return this.http.post(`${this.address}/${slug}`, { domainName: null, requireTLS: true, ingressPorts });
    }

    getById(projectSlug, id) {
        return this.http.get(`${this.address}/${projectSlug}/${id}`);
    }

    add(projectSlug, domain) {
        return this.http.post(`${this.address}/${projectSlug}`, domain);
    }

    delete(projectSlug, id) {
        return this.http.delete(`${this.address}/${projectSlug}/${id}`);
    }

    update(projectSlug, id, domain) {
        return this.http.put(`${this.address}/${projectSlug}/${id}`, domain);
    }
}
