import { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { useAuth } from "react-oidc-context";
import HttpService from "../../../../Services/HttpService";
import { useConfig } from "../../../../Configuration/hooks";
import { useMySQLDetailsContext } from "./MySQLDetailsContext";

const MySQLServiceStatusContext = createContext(null);

export const MySQLServiceStatusProvider = ({ children }) => {
    const auth = useAuth();
    const config = useConfig();
    const http = useMemo(() => new HttpService(auth, config.notificationsApi), [auth, config.notificationsApi]);
    const {
        project: { status }
    } = useSelector(state => state.currentProject, shallowEqual);

    const { setDetails } = useMySQLDetailsContext();
    const [backupStatus, setBackupStatus] = useState(null);

    const handleDbStatus = useCallback(
        (databaseName, status) => {
            setDetails(prev => {
                const updatedDbs = prev.databases.map(db => (db.database === databaseName ? { ...db, status } : db));

                return {
                    ...prev,
                    databases: updatedDbs
                };
            });
        },
        [setDetails]
    );

    const handleUserStatus = useCallback(
        (databaseName, username, status) => {
            setDetails(prev => {
                const updatedDbs = prev.databases.map(db => {
                    if (db.database === databaseName) {
                        const updatedUsers = db.users.map(user =>
                            user.username === username ? { ...user, status } : user
                        );
                        return { ...db, users: updatedUsers };
                    }
                    return db;
                });

                return {
                    ...prev,
                    databases: updatedDbs
                };
            });
        },
        [setDetails]
    );

    const handleEvent = useCallback(
        e => {
            const data = JSON.parse(e.data);
            const { type, databaseName, username, status, backupName, ...rest } = data;
            switch (data.type) {
                case "DATABASE":
                    handleDbStatus(databaseName, status);
                    break;
                case "USER":
                    handleUserStatus(databaseName, username, status);
                    break;
                case "BACKUP":
                    setBackupStatus({ backupName, status });
                    break;
                default:
                    console.error("No such type found in eventstream");
            }
        },
        [handleDbStatus, handleUserStatus]
    );

    useEffect(() => {
        let mysqlEventStream;
        const statusEventStream = async () => {
            try {
                const res = await http.post("/events", {});

                if (!res.ok) throw Error(res.status);

                const data = await res.json();
                console.log("Connected to mysql eventstream");
                mysqlEventStream = new EventSource(`${config.notificationsApi}/events/mysql?session=${data.sessionId}`);
                mysqlEventStream.onmessage = handleEvent;
            } catch (e) {
                console.error(e.message);
            }
        };

        statusEventStream();

        return () => {
            if (mysqlEventStream) {
                mysqlEventStream.close();
                console.log("Disconnected from mysql eventstream");
            }
        };
    }, [config.notificationsApi, handleEvent, http]);

    const statusValues = useMemo(() => ({ status, backupStatus }), [status, backupStatus]);

    return <MySQLServiceStatusContext.Provider value={statusValues}>{children}</MySQLServiceStatusContext.Provider>;
};

export const useMySQLServiceStatus = () => {
    const context = useContext(MySQLServiceStatusContext);
    if (context === undefined) {
        throw new Error("useStatus must be used within a StatusProvider");
    }
    return context;
};
