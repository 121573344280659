import { Box, Breadcrumbs, Stack, Tab, Tabs, Typography } from "@mui/material";
import { useState } from "react";
import BillingLayout from "../../Elements/Billing/BillingLayout";
import InternalLink from "../../Elements/InternalLink";
import AccountDetails from "../../Elements/Billing/DocumentsForms/AccountDetails";
import ClosingDocuments from "../../Elements/Billing/DocumentsForms/ClosingDocuments";
import CreateBill from "../../Elements/Billing/DocumentsForms/CreateBill";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`tabpanel-${index}`}
            aria-labelledby={`tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
        </div>
    );
}

function a11yProps(index) {
    return {
        "id": `tab-${index}`,
        "aria-controls": `tabpanel-${index}`
    };
}

const DocumentsPage = () => {
    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <BillingLayout>
            <Stack spacing={2}>
                <Breadcrumbs>
                    <InternalLink underline="hover" color="inherit" to="/">
                        Главная
                    </InternalLink>
                    <Typography color="text.primary">Юридическим лицам (beta)</Typography>
                </Breadcrumbs>
                <Typography variant="h5">Юридическим лицам (beta)</Typography>
                <Tabs value={value} onChange={handleChange} aria-label="tabs" variant="scrollable">
                    <Tab label="Сведения и реквизиты" {...a11yProps(0)} />
                    <Tab label="Закрывающие документы" {...a11yProps(1)} />
                    <Tab label="Создать счет" {...a11yProps(2)} />
                </Tabs>
                <TabPanel value={value} index={0}>
                    <AccountDetails />
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <ClosingDocuments />
                </TabPanel>
                <TabPanel value={value} index={2}>
                    <CreateBill />
                </TabPanel>
            </Stack>
        </BillingLayout>
    );
};

export default DocumentsPage;
