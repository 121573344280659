import { Controller } from "react-hook-form";
import TextField from "@mui/material/TextField";

export const FormInputText = ({
    name,
    control,
    label,
    required,
    type,
    pattern,
    validationErrorText,
    placeholder,
    InputLabelProps,
    inputProps,
    InputProps
}) => (
    <Controller
        name={name}
        control={control}
        rules={{ required, pattern }}
        render={({ field, fieldState: { error, invalid } }) => {
            const getHelperText = () => {
                if (invalid && validationErrorText) {
                    return validationErrorText;
                }
                if (error) {
                    return error.message;
                }
                return null;
            };

            return (
                <TextField
                    {...field}
                    required={!!required}
                    helperText={getHelperText()}
                    error={!!error || !!invalid}
                    label={label}
                    type={type}
                    placeholder={placeholder}
                    InputLabelProps={InputLabelProps}
                    inputProps={inputProps}
                    InputProps={InputProps}
                />
            );
        }}
    />
);
