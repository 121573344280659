import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useServices } from "../DI/hooks";
import { setError, setLoading, setTariffs } from "../Store/TariffsSlice";
import AuthenticatedRouter from "./AuthenticatedRouter";

const InitialRequester = () => {
    const { api } = useServices();

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setLoading());
        api.tariff
            .getTariffs()
            .then(response => (response.ok ? response.json() : Promise.reject(response)))
            .then(data => dispatch(setTariffs(data.tariffs)))
            .catch(e => {
                console.error(e);
                dispatch(setError(e));
            });
    }, [api, dispatch]);

    return <AuthenticatedRouter />;
};

export default InitialRequester;
