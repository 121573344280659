import SettingsBackupRestoreIcon from "@mui/icons-material/SettingsBackupRestore";
import { FormControl, FormHelperText, IconButton, TextField } from "@mui/material";
import { useCallback, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useFormContext } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import ConfirmationDialog from "../../../../Elements/ConfirmationDialog";
import useRestoreBackup from "../../api/useRestoreBackup";
import FormDialog from "../../../../Elements/FormDialog";
import { getCurrentPathWithoutSlug } from "../../../../../utils";

const NewServiceNameField = ({ ownerName }) => {
    const {
        register,
        formState: { errors }
    } = useFormContext();

    return (
        <FormControl fullWidth>
            <TextField
                fullWidth
                {...register("newServiceName", {
                    pattern: {
                        value: /^[\sa-z0-9а-яА-я-]+$/i,
                        message: "Имя сервиса может содержать маленькие и заглавные буквы, цифры и знаки дефиса."
                    },
                    maxLength: {
                        value: 27,
                        message: `Максимальная длина имени сервиса -  ${27 - ownerName.length}`
                    }
                })}
            />
            <FormHelperText error={errors?.newServiceName}>
                {errors?.newServiceName ? errors?.newServiceName?.message : " "}
            </FormHelperText>
        </FormControl>
    );
};

export function RestoreBackupButton({ status, backupName, restoreBackup, isDisabled }) {
    const [dialogOpen, setDialogOpen] = useState(false);
    const navigate = useNavigate();

    const { restoreLoading, setRestoreLoading, handleRestoreBackup } = useRestoreBackup(restoreBackup);
    const {
        project: { serviceType, ownerName }
    } = useSelector(state => state.currentProject);

    const currentUrlPath = useMemo(() => getCurrentPathWithoutSlug(), [getCurrentPathWithoutSlug]);

    const handleDialogClose = useCallback(() => {
        setDialogOpen(false);
    }, [setDialogOpen]);

    const handleDialogOpen = useCallback(() => {
        setDialogOpen(true);
    }, [setDialogOpen]);

    const onConfirm = useCallback(
        async (extra = null) => {
            if (extra) {
                const extraProps = Object.values(extra);
                const { serviceSlug } = await handleRestoreBackup(backupName, ...extraProps);

                setRestoreLoading(true);
                await new Promise(r => {
                    setTimeout(r, 3000);
                });
                setRestoreLoading(false);
                navigate(`${currentUrlPath}/${serviceSlug}`);
            } else {
                await handleRestoreBackup(backupName);
            }
            handleDialogClose();
        },
        [handleRestoreBackup, handleDialogClose, backupName]
    );

    const isRestoreDisabled = useMemo(
        () => status === "CREATING" || status === "ERROR" || isDisabled,
        [status, isDisabled]
    );

    return (
        <>
            <IconButton disabled={isRestoreDisabled} onClick={handleDialogOpen}>
                <SettingsBackupRestoreIcon color="primary" />
            </IconButton>
            {serviceType === "cnpg" ? (
                <FormDialog
                    loading={restoreLoading}
                    open={dialogOpen}
                    onClose={handleDialogClose}
                    onClickConfirm={onConfirm}
                    title="Вы действительно хотите восстановить базу данных из бэкапа?"
                    description={
                        <>
                            <p>
                                <strong>При восстановлении бэкапа будет создан новый сервис.</strong>
                            </p>
                            <p style={{ margin: 0 }}>Укажите название нового сервиса:</p>
                        </>
                    }
                >
                    <NewServiceNameField ownerName={ownerName} />
                </FormDialog>
            ) : (
                <ConfirmationDialog
                    loading={restoreLoading}
                    open={dialogOpen}
                    onClose={handleDialogClose}
                    title="Вы действительно хотите восстановить базу данных из бэкапа?"
                    description="Это необратимо и может привести к потере данных."
                    onClickConfirm={onConfirm}
                />
            )}
        </>
    );
}
