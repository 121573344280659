import { IconButton, Tooltip, Typography } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";

const InfoToolTip = ({ title }) => {
    return (
        <>
            {title && (
                <Tooltip
                    title={<Typography fontSize={14}>{title}</Typography>}
                    enterTouchDelay={0}
                    leaveTouchDelay={5000}
                    placement="right"
                    arrow
                    sx={{ typography: "body1", fontSize: "2rem" }}
                    PopperProps={{
                        modifiers: [
                            {
                                name: "offset",
                                options: {
                                    offset: [0, -15]
                                }
                            },
                            {
                                name: "flip",
                                enabled: false
                            },
                            {
                                name: "preventOverflow",
                                options: {
                                    mainAxis: true,
                                    altAxis: true
                                }
                            }
                        ],
                        sx: { whiteSpace: "pre-line", maxWidth: "230px" }
                    }}
                >
                    <IconButton>
                        <InfoIcon />
                    </IconButton>
                </Tooltip>
            )}
        </>
    );
};

export default InfoToolTip;
