import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    project: {},
    error: null,
    notFound: false,
    loading: true,
    buildLogsLoading: false,
    runLogsLoading: false
};

export const currentProjectSlice = createSlice({
    name: "currentProject",
    initialState,
    reducers: {
        setCurrentProject: (state, action) => {
            state.project = action.payload;
            state.error = null;
            state.notFound = false;
            state.loading = false;
            state.buildLogsLoading = false;
            state.runLogsLoading = false;
        },
        setError: (state, action) => {
            state.project = {};
            state.error = action.payload;
            state.notFound = false;
            state.loading = false;
            state.buildLogsLoading = false;
            state.runLogsLoading = false;
        },
        setNotFound: state => {
            state.project = {};
            state.error = null;
            state.notFound = true;
            state.loading = false;
            state.buildLogsLoading = false;
            state.runLogsLoading = false;
        },
        setLoading: state => {
            state.project = {};
            state.error = null;
            state.notFound = false;
            state.loading = true;
            state.buildLogsLoading = false;
            state.runLogsLoading = false;
        },
        setInstances: (state, action) => {
            const event = action.payload;
            if (state.project.name === event.serviceName) state.project.instances = event.instances;
        },
        setRequiredInstances: (state, action) => {
            state.project.requiredInstances = action.payload;
        },
        setBuildLogsLoading: (state, action) => {
            state.buildLogsLoading = action.payload;
        },
        setRunLogsLoading: (state, action) => {
            state.runLogsLoading = action.payload;
        },
        applyCurrentRepositoryEvent: (state, action) => {
            const event = action.payload;
            if (event.action !== "CREATE" || !event.success) return;

            if (state.project.slug === event.repository) {
                state.project.status = "EMPTY";
            }
        },
        applyCurrentBuildStartedEvent: (state, action) => {
            const event = action.payload;

            if (state.project.slug === event.project) {
                state.project.status = "BUILD_STARTED";
                state.project.statusMessage = "";
            }
        },
        applyCurrentTaskStatusEvent: (state, action) => {
            const event = action.payload;

            if (state.project.slug === event.project) {
                if (event.stage === "BUILD") {
                    if (event.success) {
                        state.project.status = "DEPLOYING";
                    } else {
                        state.project.status = "BUILD_FAILED";
                    }
                } else if (event.stage === "RUN") {
                    if (event.success) {
                        state.project.status = "STARTING";
                    } else {
                        state.project.status = "DEPLOY_FAILED";
                    }
                }
                state.project.statusMessage = event.message;
            }
        },
        applyCurrentRunStatusEvent: (state, action) => {
            const event = action.payload;
            if (state.project.slug === event.service) {
                state.project.status = event.message;
                state.project.statusMessage = event.message;
            }
        }
    }
});

export const {
    setCurrentProject,
    setError,
    setNotFound,
    setLoading,
    setInstances,
    setRequiredInstances,
    setBuildLogsLoading,
    setRunLogsLoading,
    applyCurrentRepositoryEvent,
    applyCurrentBuildStartedEvent,
    applyCurrentTaskStatusEvent,
    applyCurrentRunStatusEvent
} = currentProjectSlice.actions;

export default currentProjectSlice.reducer;
