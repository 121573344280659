import {
    Button,
    Typography,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Stack,
    TablePagination,
    Divider,
    InputAdornment,
    Box
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import MoreMenu from "./MoreMenu";
import { useServices } from "../../../../../DI/hooks";
import { setDocuments, setError, setLoading } from "../../../../../Store/BillingUlSlice";
import StatusChip from "./StatusChip";
import { FormInputText } from "../../../FormInputText/FormInputText";
import TableCellWithSort from "../../TableCellWithSort";
import SkeletonTable from "../../SkeletonTable";

function CreateBill() {
    const { api } = useServices();
    const billing = useSelector(state => state.billingUl);
    const {
        documents: { list, pages },
        loading
    } = billing;
    const dispatch = useDispatch();

    const { handleSubmit, reset, control } = useForm({
        defaultValues: {
            amount: 1000
        }
    });

    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(5);
    const [sort, setSort] = useState("desc");
    const [sortBy, setSortBy] = useState("date");

    const handleChangePage = (_, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = event => {
        setPageSize(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleChangeSort = property => {
        setSortBy(property);
        setSort(prev => (prev === "desc" ? "asc" : "desc"));
    };

    const bill = ({ amount }) => {
        if (amount && Number(amount) > 0) {
            api.billingUl.bill({ amount }).catch(err => dispatch(setError(err)));
        }
    };

    const handleReset = () => {
        reset({
            amount: ""
        });
    };

    useEffect(() => {
        dispatch(setLoading());
        api.billingUl
            .getUlDocuments({
                page: page,
                size: pageSize,
                sort: [{ property: sortBy, direction: sort }]
            })
            .then(data => data.json())
            .then(data => dispatch(setDocuments(data)))
            .catch(err => dispatch(setError(err)));
    }, [api, dispatch, page, pageSize, sort, sortBy]);

    return (
        <Stack spacing={2}>
            <Box component="form" onSubmit={handleSubmit(bill)} onReset={handleReset}>
                <Stack sx={{ width: { xs: "auto", sm: "50%" } }} spacing={2}>
                    <div>
                        <Typography variant="h5">Пополнение баланса</Typography>
                        <Typography variant="p" color="secondary">
                            Перевод может занять от 3 до 5 рабочих дней
                        </Typography>
                    </div>
                    <FormInputText
                        autoFocus
                        label="Сумма"
                        name="amount"
                        type="number"
                        control={control}
                        pattern="[0-9]*"
                        InputProps={{
                            endAdornment: <InputAdornment position="end">₽</InputAdornment>,
                            sx: {
                                "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
                                    display: "none"
                                },
                                "& input[type=number]": {
                                    MozAppearance: "textfield"
                                }
                            }
                        }}
                        inputProps={{
                            inputMode: "numeric",
                            pattern: "[0-9]*",
                            style: { textAlign: "end" }
                        }}
                    />
                    <Stack
                        spacing={1}
                        direction={{ xs: "column-reverse", sm: "row" }}
                        alignSelf={{ xs: "stretch", sm: "flex-end" }}
                    >
                        <Button variant="outlined" size="large" type="reset">
                            Отменить
                        </Button>
                        <Button variant="contained" size="large" type="submit">
                            Выписать счёт
                        </Button>
                    </Stack>
                </Stack>
            </Box>

            <Stack spacing={2}>
                <Typography variant="h5">Созданные счета</Typography>
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCellWithSort
                                    direction={sort}
                                    onClick={() => handleChangeSort("date")}
                                    width="30%"
                                    active={sortBy === "date"}
                                >
                                    Дата счета
                                </TableCellWithSort>
                                <TableCellWithSort
                                    direction={sort}
                                    onClick={() => handleChangeSort("status")}
                                    width="30%"
                                    active={sortBy === "status"}
                                >
                                    Статус
                                </TableCellWithSort>
                                <TableCellWithSort
                                    direction={sort}
                                    onClick={() => handleChangeSort("amount")}
                                    width="30%"
                                    active={sortBy === "amount"}
                                >
                                    Сумма
                                </TableCellWithSort>
                                <TableCell width="10%" />
                            </TableRow>
                        </TableHead>
                        {loading ? (
                            <SkeletonTable />
                        ) : (
                            <TableBody>
                                {list?.length > 0 ? (
                                    list.map(document => (
                                        <TableRow
                                            key={document.id}
                                            sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                                        >
                                            <TableCell>{document.date}</TableCell>
                                            <TableCell>
                                                <StatusChip status={document.status} />
                                            </TableCell>
                                            <TableCell>{`${document.amount} ₽`}</TableCell>
                                            <TableCell align="right">
                                                <MoreMenu documentId={document.id} name={document.name} />
                                            </TableCell>
                                        </TableRow>
                                    ))
                                ) : (
                                    <TableRow>
                                        <TableCell colSpan={4} align="center">
                                            Документы не найдены
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        )}
                    </Table>
                    <Divider />
                    <TablePagination
                        component="div"
                        count={pages?.filter?.total || -1}
                        page={page}
                        onPageChange={handleChangePage}
                        rowsPerPage={pageSize}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        rowsPerPageOptions={[5, 10, 25, 50, 100]}
                    />
                </TableContainer>
            </Stack>
        </Stack>
    );
}

export default CreateBill;
