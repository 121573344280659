import { Route, Routes } from "react-router-dom";
import LandingPage from "./Pages/LandingPage";
import UnauthenticatedRedirectPage from "./Pages/UnauthenticatedRedirectPage";
import AuthenticatedRedirectPage from "./Pages/AuthenticatedRedirectPage";

const UnauthenticatedRouter = () => (
    <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/auth/callback" element={<AuthenticatedRedirectPage />} />
        <Route path="*" element={<UnauthenticatedRedirectPage />} />
    </Routes>
);

export default UnauthenticatedRouter;
