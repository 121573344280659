const ListAllMixin = {
    listAll() {
        return this.http.get(this.address);
    }
};

const GetByIdMixin = {
    getById(id) {
        return this.http.get(`${this.address}/${id}`);
    }
};

const CreateMixin = {
    create(object) {
        return this.http.post(this.address, object);
    }
};

const BranchMixin = {
    branch() {
        return this.http.post(`${this.address}/branch`, {});
    }
};

const ReplaceMixin = {
    replace(id, object) {
        return this.http.put(`${this.address}/${id}`, object);
    }
};

const PatchMixin = {
    patch(id, patch) {
        return this.http.patch(`${this.address}/${id}`, patch);
    }
};

const DeleteByIdMixin = {
    deleteById(id) {
        return this.http.delete(`${this.address}/${id}`);
    }
};

const DeleteAllMixin = {
    deleteAll() {
        return this.http.delete(this.address);
    }
};

class EndpointConfigurator {
    constructor(endpoint) {
        this.endpoint = endpoint;
    }

    _enableFeature(feature) {
        Object.assign(this.endpoint, feature);
        return this;
    }

    enableListAll() {
        return this._enableFeature(ListAllMixin);
    }

    enableGetById() {
        return this._enableFeature(GetByIdMixin);
    }

    enableCreate() {
        return this._enableFeature(CreateMixin);
    }

    enableBranch() {
        return this._enableFeature(BranchMixin);
    }

    enableReplace() {
        return this._enableFeature(ReplaceMixin);
    }

    enablePatch() {
        return this._enableFeature(PatchMixin);
    }

    enableDeleteById() {
        return this._enableFeature(DeleteByIdMixin);
    }

    enableDeleteAll() {
        return this._enableFeature(DeleteAllMixin);
    }

    done() {
        return this.endpoint;
    }
}

export default class Endpoint {
    constructor(http, address) {
        this.http = http;
        this.address = address;
    }

    configure() {
        return new EndpointConfigurator(this);
    }
}
