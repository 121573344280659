import { Icon, IconButton, Tooltip } from "@mui/material";

const ProjectRestartButton = ({ removed = false, disabled = false, onClick }) => {
    if (removed) return null;

    return (
        <Tooltip arrow disableInteractive title="Перезапустить проект">
            <IconButton size="medium" disabled={disabled} onClick={onClick}>
                <Icon className="fa-solid fa-rotate-right" />
            </IconButton>
        </Tooltip>
    );
};

export default ProjectRestartButton;
