import { useParams } from "react-router-dom";
import { useCallback } from "react";

import { BackupTab } from "../../SingleDbPage/components";
import { useServices } from "../../../../DI/hooks";
import usePostgresEventStream from "../hooks/usePostgresEventStream";

export function PostgresBackupTab({ scheduledBackupEnabled, setDetails }) {
    const { slug } = useParams();
    const { api } = useServices();
    const { backupStatus, isPostgresDisabled } = usePostgresEventStream();

    const executeGetBackups = useCallback(() => api.postgres.getBackups(slug), [slug, api.postgres]);
    const executeChangeScheduledBackup = useCallback(
        enabled => api.postgres.changeScheduledBackup(slug, enabled),
        [api.postgres, slug]
    );
    const executeAddBackup = useCallback(data => api.postgres.addBackup(data), [api.postgres]);
    const executeDeleteBackup = useCallback(backupName => api.postgres.deleteBackup(backupName), [api.postgres]);
    const executeRestoreBackup = useCallback(
        (backupName, newServiceName) => api.postgres.restoreBackup(slug, backupName, newServiceName),
        [api.postgres, slug]
    );

    return (
        <BackupTab
            isDisabled={isPostgresDisabled}
            isScheduledBackupEnabled={scheduledBackupEnabled}
            backupStatus={backupStatus}
            getBackups={executeGetBackups}
            changeScheduledBackup={executeChangeScheduledBackup}
            addBackup={executeAddBackup}
            deleteBackup={executeDeleteBackup}
            restoreBackup={executeRestoreBackup}
            setDetails={setDetails}
            backupDoc="https://docs.amvera.ru/databases/postgreSQL.html#id6"
            restoreBackupDoc="https://docs.amvera.ru/databases/postgreSQL.html#id9"
            scheduledBackupsDoc="https://docs.amvera.ru/databases/postgreSQL.html#id7"
            onDemandBackupsDoc="https://docs.amvera.ru/databases/postgreSQL.html#id8"
        />
    );
}
