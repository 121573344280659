import { useCallback } from "react";
import { FormControlLabel, FormGroup, Skeleton, Switch } from "@mui/material";
import useGetAutoScaling from "../hooks/useGetAutoScaling";
import usePostAutoScaling from "../hooks/usePostAutoScaling";

export const AutoScaling = ({ slug }) => {
    const [autoScaling, autoScalingError, autoScalingLoading] = useGetAutoScaling(slug);
    const [changeAutoScaling, changedAS, changedASError, changedASLoading] = usePostAutoScaling(slug);

    const handleChange = useCallback(
        async e => {
            await changeAutoScaling(e.target.checked);
        },
        [changeAutoScaling]
    );

    return (
        <div>
            {!autoScalingLoading ? (
                <FormGroup>
                    <FormControlLabel
                        control={<Switch onChange={handleChange} />}
                        label="Autoscaling (демо)"
                        checked={changedAS?.on ?? autoScaling?.on}
                    />
                </FormGroup>
            ) : (
                <Skeleton width={155} height={38} />
            )}
        </div>
    );
};
