import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Divider,
    Stack,
    Typography
} from "@mui/material";
import { useForm } from "react-hook-form";
import { useAuth } from "react-oidc-context";
import { FormInputText } from "../../../../FormInputText/FormInputText";
import { useServices } from "../../../../../../DI/hooks";

function SendByEmailDialog({ isOpen, handleClose, documentId }) {
    const { user } = useAuth();
    const { email: defaultEmail } = user.profile;
    const { api } = useServices();

    const { handleSubmit, reset, control } = useForm({
        defaultValues: {
            email: ""
        }
    });

    const sendByEmail = data => {
        api.billingUl.sendByEmail({ documentId, email: data.email ? data.email : defaultEmail });
        handleClose();
    };

    const handleCancel = () => {
        reset();
        handleClose();
    };

    return (
        <Dialog
            open={isOpen}
            onClose={handleClose}
            aria-labelledby="send-by-email-dialog-title"
            aria-describedby="send-by-email-dialog-description"
            fullWidth
            component="form"
            onSubmit={handleSubmit(sendByEmail)}
        >
            <DialogTitle id="send-by-email-dialog-title">Отправить на почту</DialogTitle>
            <DialogContent>
                <Stack spacing={2}>
                    <DialogContentText id="send-by-email-dialog-description">
                        Счет будет отправлен на электронный адрес, указанный при регистрации:{" "}
                        <Typography variant="p" color="secondary">
                            {defaultEmail}
                        </Typography>
                    </DialogContentText>
                    <DialogContentText id="send-by-email-dialog-description">Или введите другой:</DialogContentText>
                    <FormInputText
                        name="email"
                        control={control}
                        type="email"
                        placeholder={defaultEmail}
                        pattern="[^@\s]+@[^@\s]+"
                        validationErrorText="Введите корректный e-mail."
                        label="E-mail"
                        InputLabelProps={{ shrink: true }}
                    />
                </Stack>
            </DialogContent>
            <Divider />
            <DialogActions>
                <Button onClick={handleCancel} variant="outlined" size="large">
                    Отмена
                </Button>
                <Button autoFocus variant="contained" size="large" type="submit">
                    Отправить
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default SendByEmailDialog;
