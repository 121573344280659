import { memo, useCallback } from "react";
import { Box, Stack, Typography } from "@mui/material";

import DeleteDbButton from "./DeleteDbButton";
import UserController from "./UserController";
import AddUserButton from "./AddUserButton";
import StatusDisplay from "../../SingleMySQLPage/components/StatusDisplay";

const DbController = memo(({ name, users, slug, status, setDetails }) => {
    const handleRemoveDb = useCallback(
        name => {
            setDetails(prev => ({ ...prev, databases: prev.databases.filter(({ database }) => database !== name) }));
        },
        [setDetails]
    );

    return (
        <Box>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
                <Stack direction="row" spacing={1} alignItems="center">
                    <Typography>{name}</Typography>
                    <StatusDisplay status={status} />
                </Stack>
                <DeleteDbButton status={status} name={name} slug={slug} deleteDetails={handleRemoveDb} />
            </Stack>
            <Stack bgcolor="background.dark" borderRadius={4} paddingY={2} paddingX={4} alignItems="center">
                <Stack spacing={1} sx={{ width: "100%" }}>
                    {users.map(({ id, username, status }) => (
                        <UserController
                            key={id}
                            username={username}
                            status={status}
                            dbName={name}
                            slug={slug}
                            setDetails={setDetails}
                        />
                    ))}
                </Stack>
                <AddUserButton status={status} slug={slug} setDetails={setDetails} dbName={name} />
            </Stack>
        </Box>
    );
});

export default DbController;
