import Endpoint from "./Endpoint";

export default class EnvEndpoint extends Endpoint {
    getAll(projectSlug) {
        return this.http.get(`${this.address}/${projectSlug}`);
    }

    getById(projectSlug, id) {
        return this.http.get(`${this.address}/${projectSlug}/${id}`);
    }

    add(projectSlug, variable) {
        return this.http.post(`${this.address}/${projectSlug}`, variable);
    }

    update(projectSlug, variable) {
        return this.http.put(`${this.address}/${projectSlug}/${variable.id}`, variable);
    }

    delete(projectSlug, id) {
        return this.http.delete(`${this.address}/${projectSlug}/${id}`);
    }
}
