import {
    Box,
    FormControl,
    FormHelperText,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    Skeleton,
    Stack,
    Typography
} from "@mui/material";
import { useSelector } from "react-redux";

const SelectTariff = ({ required, disabled, tariffId, onChange, children }) => {
    const tariffs = useSelector(state => state?.tariffs ?? []);

    if (tariffs?.loading ?? false)
        return (
            <Skeleton width="100%">
                <Select />
            </Skeleton>
        );

    return (
        <Stack>
            <Stack direction="row" spacing={2}>
                <FormControl
                    fullWidth
                    required={required}
                    disabled={(tariffs?.error ?? false) || disabled}
                    error={tariffs?.error ?? false}
                    sx={{ flex: "1" }}
                >
                    <InputLabel id="project-tariff-select-label">Тарифный план</InputLabel>
                    <Select
                        id="project-tariff-select"
                        labelId="project-tariff-select-label"
                        label="Тарифный план"
                        value={tariffId}
                        onChange={onChange}
                    >
                        <MenuItem disabled value={-1}>
                            -
                        </MenuItem>
                        {tariffs?.data?.map(tariff => (
                            <MenuItem key={tariff.id} value={tariff.id}>
                                {tariff.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                {children}
            </Stack>

            <Typography pt={2} variant="body2" color="darkgray">
                {tariffs?.data?.find(tariff => tariff.id === tariffId)?.description ?? null}
            </Typography>
            {tariffs?.error && <FormHelperText>Не удалось получить тарифные планы</FormHelperText>}
        </Stack>
    );
};

SelectTariff.defaultProps = {
    required: false,
    disabled: false,
    tariffId: -1,
    onChange: () => {}
};

export default SelectTariff;
