import Endpoint from "./Endpoint";

class PostgresEndpoint extends Endpoint {
    getService(slug) {
        return this.http.get(`${this.address}/${slug}`);
    }

    getTariff(slug) {
        return this.http.get(`${this.address}/${slug}/tariff`);
    }

    scaleBySlug(slug, instances) {
        return this.http.post(`${this.address}/${slug}/scale`, {
            instances: instances
        });
    }

    updateTariffBySlug(slug, tariffId) {
        return this.http.post(`${this.address}/${slug}/tariff`, tariffId);
    }

    getDetails(slug) {
        return this.http.get(`${this.address}/${slug}/details`);
    }

    getBackups(serviceSlug) {
        return this.http.get(`${this.address}/backup/${serviceSlug}`);
    }

    addBackup(data) {
        return this.http.post(`${this.address}/backup`, { serviceSlug: data.slug, description: data.description });
    }

    deleteBackup(backupName) {
        return this.http.delete(`${this.address}/backup/${backupName}`);
    }

    restoreBackup(serviceSlug, backupName, newServiceName) {
        return this.http.post(`${this.address}/restore`, {
            oldServiceSlug: serviceSlug,
            backupName,
            newServiceName
        });
    }

    changeScheduledBackup(serviceSlug, isScheduledBackupEnabled) {
        return this.http.put(`${this.address}`, { serviceSlug, isScheduledBackupEnabled });
    }
}

export default PostgresEndpoint;
