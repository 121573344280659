import { useParams } from "react-router-dom";
import { FormControl, FormLabel, MenuItem, Select, Stack, Typography } from "@mui/material";
import SingleProjectPageSkeleton from "../../SingleProjectPageSkeleton";
import DbController from "../../SingleDbPage/components/DbController";

import AddDbButton from "../../SingleDbPage/components/AddDbButton";
import { useMySQLDetailsContext } from "../../SingleDbPage/context/MySQLDetailsContext";

export function MySQLConfigurationTab() {
    const { slug } = useParams();
    const { details, detailsLoading, setDetails } = useMySQLDetailsContext();

    if (detailsLoading) return <SingleProjectPageSkeleton />;

    return (
        <Stack spacing={2} maxWidth={600}>
            <FormControl>
                <FormLabel>Версия MySQL:</FormLabel>
                <Select disabled defaultValue={details.version}>
                    <MenuItem value="5.0">version 5.0</MenuItem>
                    <MenuItem value="8.0">version 8.0</MenuItem>
                </Select>
            </FormControl>
            <Stack>
                <Typography variant="h6">Базы данных и пользователи:</Typography>
                <Stack spacing={3}>
                    <Stack spacing={1}>
                        {details?.databases?.map(db => (
                            <DbController
                                key={db.id}
                                name={db.database}
                                users={db.users}
                                slug={db.slug}
                                status={db.status}
                                setDetails={setDetails}
                            />
                        ))}
                    </Stack>
                    <AddDbButton setDetails={setDetails} slug={slug} />
                </Stack>
            </Stack>
        </Stack>
    );
}
