import { Icon, IconButton, Tooltip } from "@mui/material";
import { useCallback, useState, useEffect, useMemo } from "react";

const ProjectStartStopButton = ({ disabled = false, requiredInstances, status, onClick }) => {
    const [value, setValue] = useState(requiredInstances);

    useEffect(() => {
        setValue(requiredInstances);
    }, [requiredInstances]);

    const handleClickStart = useCallback(() => {
        setValue(1);
        onClick(1);
    }, [onClick]);

    const handleClickStop = useCallback(() => {
        setValue(0);
        onClick(0);
    }, [onClick]);

    const isPaused = useMemo(() => {
        return status === "STOPPED";
    }, [status]);

    const isPauseForBuildStarted = useMemo(() => status === "BUILD_STARTED" || !isPaused, [status, isPaused]);
    console.log(isPauseForBuildStarted);
    console.log(status);
    console.log(value);

    return (
        <Tooltip
            arrow
            disableInteractive
            title={status !== "BUILD_STARTED" ? "Включить/Выключить проект" : "Остановить сборку"}
        >
            {value > 0 ? (
                isPaused ? (
                    <IconButton size="medium" disabled={disabled} onClick={handleClickStart}>
                        <Icon className="fa-solid fa-play" />
                    </IconButton>
                ) : (
                    <IconButton size="medium" disabled={disabled} onClick={handleClickStop}>
                        <Icon className="fa-solid fa-pause" />
                    </IconButton>
                )
            ) : status === "BUILD_STARTED" ? (
                <IconButton size="medium" disabled={disabled} onClick={handleClickStop}>
                    <Icon className="fa-solid fa-pause" />
                </IconButton>
            ) : (
                <IconButton size="medium" disabled={disabled} onClick={handleClickStart}>
                    <Icon className="fa-solid fa-play" />
                </IconButton>
            )}
        </Tooltip>
    );
};

export default ProjectStartStopButton;
