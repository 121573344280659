import { Alert, Button, Icon, Stack, Typography, IconButton } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useServices } from "../../../DI/hooks";
import useHandleError from "../../../Hooks/useHandleError";
import { resetVariable, setIsSecret, setVariable } from "../../../Store/EditableVariableSlice";
import { isEmpty, isExists } from "../../../utils";
import ConfirmationDialog from "../ConfirmationDialog";
import EnvironmentVariableDialog from "../EnvironmentVariableDialog";
import EnvironmentVariableTable from "../EnvironmentVariableTable";
import Nothing, { NothingContainer } from "../Nothing";

const EnvironmentVariablesTab = ({ project }) => {
    const dispatch = useDispatch();

    const { api } = useServices();

    const editableVariable = useSelector(state => state?.editableVariable ?? null);

    const [editDialogOpen, setEditDialogOpen] = useState(false);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [variables, setVariables] = useState([]);

    const { handleApiError } = useHandleError();

    const handleOpenEditDialog = useCallback(() => setEditDialogOpen(true), []);

    const handleCloseEditDialog = useCallback(() => setEditDialogOpen(false), []);

    const handleOpenDeleteDialog = useCallback(() => setDeleteDialogOpen(true), []);

    const handleCloseDeleteDialog = useCallback(() => setDeleteDialogOpen(false), []);

    const fetchVariables = useCallback(() => {
        api.env
            .getAll(project.slug)
            .then(response => (response.ok ? response.json() : Promise.reject(response)))
            .then(data => setVariables(data))
            .catch(handleApiError);
    }, [api, project, handleApiError]);

    const handleClickCreateVariable = useCallback(() => {
        dispatch(resetVariable());
        handleOpenEditDialog();
    }, [dispatch, handleOpenEditDialog]);

    const handleClickCreateSecretVariable = useCallback(() => {
        dispatch(resetVariable());
        dispatch(setIsSecret(true));
        handleOpenEditDialog();
    }, [dispatch, handleOpenEditDialog]);

    const handleClickEdit = useCallback(
        variable => {
            dispatch(resetVariable());
            dispatch(setVariable(variable));
            handleOpenEditDialog();
        },
        [dispatch, handleOpenEditDialog]
    );

    const handleClickDelete = useCallback(
        variable => {
            dispatch(resetVariable());
            dispatch(setVariable(variable));
            handleOpenDeleteDialog();
        },
        [dispatch, handleOpenDeleteDialog]
    );

    const handleClickSave = useCallback(() => {
        const responsePromise = isExists(editableVariable?.id)
            ? api.env.update(project.slug, editableVariable)
            : api.env.add(project.slug, editableVariable);
        responsePromise
            .then(response => (response.ok ? fetchVariables() : Promise.reject(response)))
            .then(() => handleCloseEditDialog())
            .catch(handleApiError);
    }, [api, editableVariable, project, fetchVariables, handleCloseEditDialog, handleApiError]);

    const handleClickConfirmDelete = useCallback(() => {
        api.env
            .delete(project.slug, editableVariable.id)
            .then(response => (response.ok ? fetchVariables() : Promise.reject(response)))
            .then(() => handleCloseDeleteDialog())
            .catch(handleApiError);
    }, [api, editableVariable, project, fetchVariables, handleCloseDeleteDialog, handleApiError]);

    useEffect(() => fetchVariables(), [fetchVariables]);

    return (
        <>
            <Stack spacing={1.5} direction="column">
                <Stack direction={{ xs: "column", sm: "row" }} alignItems="center" justifyContent="space-between">
                    <Typography variant="h6" sx={{ mb: { xs: 2, sm: 0 } }}>
                        Переменные окружения
                        <IconButton
                            size="small"
                            color="inherit"
                            href="https://docs.amvera.ru/applications/configuration/variables.html"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <i className="fa-regular fa-circle-question" />
                        </IconButton>
                    </Typography>
                    <Stack spacing={2} direction="row" alignItems="center">
                        <Button
                            variant="contained"
                            color="tertiary"
                            size="small"
                            startIcon={<Icon className="fa-solid fa-plus" />}
                            onClick={handleClickCreateSecretVariable}
                            sx={{ flexGrow: 1 }}
                        >
                            Создать секрет
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            startIcon={<Icon className="fa-solid fa-plus" />}
                            onClick={handleClickCreateVariable}
                            sx={{ flexGrow: 1 }}
                        >
                            Создать переменную
                        </Button>
                    </Stack>
                </Stack>
                <Alert severity="info">Для применения изменений необходимо перезапустить контейнеры</Alert>
                {isEmpty(variables) ? (
                    <NothingContainer>
                        <Nothing>
                            <Nothing.Item>
                                <Typography variant="h1" color="darkgrey" sx={{ textAlign: "center" }}>
                                    <Icon className="fa-solid fa-sheet-plastic" />
                                </Typography>
                                <div>Переменных окружения пока нет</div>
                            </Nothing.Item>
                        </Nothing>
                    </NothingContainer>
                ) : (
                    <EnvironmentVariableTable
                        variables={variables}
                        onEdit={handleClickEdit}
                        onDelete={handleClickDelete}
                    />
                )}
            </Stack>

            <EnvironmentVariableDialog open={editDialogOpen} onSave={handleClickSave} onClose={handleCloseEditDialog} />
            <ConfirmationDialog
                title="Удаление"
                description={`Удалить переменную '${editableVariable?.name ?? "-"}'?`}
                open={deleteDialogOpen}
                onClickConfirm={handleClickConfirmDelete}
                onClose={handleCloseDeleteDialog}
            />
        </>
    );
};

export default EnvironmentVariablesTab;
