import {
    Button,
    Paper,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    TablePagination,
    Divider
} from "@mui/material";

import { DatePicker } from "@mui/x-date-pickers";
import { useCallback, useEffect, useState } from "react";
import SummarizeIcon from "@mui/icons-material/Summarize";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import ReconciliationReportDialog from "./ReconciliationReportDialog";
import { useServices } from "../../../../../DI/hooks";
import { setDocuments, setError, setLoading } from "../../../../../Store/BillingUlSlice";
import { DOCUMENT_TYPE } from "../../../../../constants";
import { getQuarterFromDate } from "../../../../../utils";
import TableCellWithSort from "../../TableCellWithSort";
import SkeletonTable from "../../SkeletonTable";

function ClosingDocuments() {
    const { api } = useServices();
    const billing = useSelector(state => state.billingUl);
    const {
        documents: { list, pages },
        loading
    } = billing;
    const dispatch = useDispatch();

    const [filterFromDate, setFilterFromDate] = useState(dayjs(new Date()).subtract(1, "year"));
    const [filterToDate, setFilterToDate] = useState(dayjs(new Date()));

    const [dialogOpen, setDialogOpen] = useState(false);

    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(5);
    const [dateSort, setDateSort] = useState("desc");

    const handleChangePage = (_, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = event => {
        setPageSize(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleChangeDateSort = () => {
        setDateSort(prev => (prev === "desc" ? "asc" : "desc"));
    };

    const handleOpenDialog = () => {
        setDialogOpen(true);
    };

    const handleCloseDialog = () => {
        setDialogOpen(false);
    };

    const handleDownloadDocument = ({ id, name }) => {
        api.billingUl
            .downloadUlDocument({ id })
            .then(data => data.blob())
            .then(data => {
                const blob = new Blob([data], {
                    type: "application/pdf"
                });

                const element = document.createElement("a");
                element.href = URL.createObjectURL(blob);
                element.download = `${name}.pdf`;

                document.body.appendChild(element);
                element.click();
            })
            .catch(err => {
                dispatch(setError(err));
                console.log("Download error", err);
            });
    };

    const getClosingDocuments = useCallback(() => {
        dispatch(setLoading());
        api.billingUl
            .getUlDocuments({
                from: dayjs(filterFromDate).format("DD.MM.YYYY"),
                to: dayjs(filterToDate).format("DD.MM.YYYY"),
                page: page,
                size: pageSize,
                sort: [{ property: "date", direction: dateSort }]
            })
            .then(data => data.json())
            .then(data => dispatch(setDocuments(data)))
            .catch(err => dispatch(setError(err)));
    }, [api, dispatch, filterFromDate, filterToDate, page, pageSize, dateSort]);

    const handleSubmit = () => {
        setTimeout(getClosingDocuments, 500);
    };

    useEffect(() => {
        getClosingDocuments();
    }, [getClosingDocuments]);

    if (billing.error) {
        console.log("error", billing.error);
    }

    return (
        <Stack spacing={2} direction="column">
            <Stack
                alignItems={{ sm: "space-between" }}
                direction={{ xs: "column", sm: "row" }}
                spacing={{ xs: 2, sm: "auto" }}
            >
                <Stack spacing={{ xs: 2, sm: 1 }} direction={{ xs: "column", sm: "row" }}>
                    <DatePicker
                        label="От"
                        value={filterFromDate}
                        onChange={value => {
                            setFilterFromDate(value);
                        }}
                        renderInput={params => <TextField {...params} />}
                        disableFuture
                        maxDate={filterToDate}
                        inputFormat="DD/MM/YYYY"
                    />
                    <DatePicker
                        label="До"
                        value={filterToDate}
                        onChange={value => {
                            setFilterToDate(value);
                        }}
                        renderInput={params => <TextField {...params} />}
                        disableFuture
                        minDate={filterFromDate}
                        inputFormat="DD/MM/YYYY"
                    />
                </Stack>
                <Button
                    variant="contained"
                    size="large"
                    sx={{ ml: "auto" }}
                    startIcon={<SummarizeIcon />}
                    onClick={handleOpenDialog}
                >
                    Заказать акт сверки
                </Button>
                <ReconciliationReportDialog
                    isOpen={dialogOpen}
                    handleClose={handleCloseDialog}
                    defaultFromDate={filterFromDate}
                    defaultToDate={filterToDate}
                    handleSubmit={handleSubmit}
                />
            </Stack>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell width="30%">Тип документа</TableCell>
                            <TableCellWithSort width="30%" direction={dateSort} onClick={handleChangeDateSort} active>
                                Дата создания
                            </TableCellWithSort>
                            <TableCell width="25%">Период</TableCell>
                            <TableCell width="15%" />
                        </TableRow>
                    </TableHead>
                    {loading ? (
                        <SkeletonTable />
                    ) : (
                        <TableBody>
                            {list?.length > 0 ? (
                                list.map(document => (
                                    <TableRow
                                        key={document.id}
                                        sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                                    >
                                        <TableCell>{DOCUMENT_TYPE[document.type]}</TableCell>
                                        <TableCell>{document.date}</TableCell>
                                        <TableCell>{getQuarterFromDate(document.date)}</TableCell>
                                        <TableCell align="right">
                                            <Button
                                                variant="outlined"
                                                onClick={() =>
                                                    handleDownloadDocument({ id: document.id, name: document.name })
                                                }
                                            >
                                                Скачать
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                ))
                            ) : (
                                <TableRow>
                                    <TableCell colSpan={4} align="center">
                                        Документы не найдены
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    )}
                </Table>
                <Divider />
                <TablePagination
                    component="div"
                    count={pages?.filter?.total || -1}
                    page={page}
                    onPageChange={handleChangePage}
                    rowsPerPage={pageSize}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    rowsPerPageOptions={[5, 10, 25, 50, 100]}
                />
            </TableContainer>
        </Stack>
    );
}

export default ClosingDocuments;
