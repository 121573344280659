import { useState, useCallback } from "react";
import {
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Stack,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    IconButton,
    Link,
    Tooltip
} from "@mui/material";
import CodeBlock from "./CodeBlock";
import FileExplorer from "./FileExplorer";
import { useConfig } from "../../Configuration/hooks";
import UploadFiles from "./UploadFiles";
import DownloadDialog from "./DownloadDialog";
import { useServices } from "../../DI/hooks";
import GitController from "./GitController";

const GeneralTab = ({ project }) => {
    const [selectedRepository, setSelectedOption] = useState("Code");
    const [isUploadDialogOpen, setIsUploadDialogOpen] = useState(false);
    const [downloadDialogOpen, setDownloadDialogOpen] = useState(false);
    const [currentPath, setCurrentPath] = useState("/");
    const [isUploaded, setIsUploaded] = useState(false);
    const [session, setSession] = useState(0);

    const handleOpenDownloadDialog = useCallback(() => setDownloadDialogOpen(true), []);

    const handleCloseDownloadDialog = useCallback(() => setDownloadDialogOpen(false), []);

    const config = useConfig();
    const { fileService } = useServices();

    const handleOptionChange = event => {
        setSelectedOption(event.target.value);
    };

    const handleClickDownloadData = useCallback(
        selectedItems => {
            fileService.download(
                `/file-storage/userdata/${project.slug}/download_token`,
                "/file-storage/userdata/download",
                selectedItems
            );
            handleCloseDownloadDialog();
        },
        [fileService, project.slug, handleCloseDownloadDialog]
    );

    // const handleClickDownloadConfig = useCallback(() => {
    //     fileService.downloadFile(
    //         `/file-storage/userdata/${project.slug}/download_token`,
    //         "/file-storage/userdata/download/file",
    //         "CODE",
    //         "amvera.yml"
    //     );
    // }, [fileService, project.slug]);

    const openUploadDialog = () => {
        setIsUploadDialogOpen(true);
    };

    const closeUploadDialog = () => {
        setIsUploadDialogOpen(false);
        setSession(session + 1);
        setCurrentPath("/");
    };

    return (
        <div>
            <Dialog open={isUploadDialogOpen} onClose={closeUploadDialog}>
                <DialogTitle>Загрузка файлов:</DialogTitle>
                <DialogContent>
                    <UploadFiles
                        projectSlug={project.slug}
                        directory={selectedRepository}
                        path={currentPath}
                        setIsUploaded={setIsUploaded}
                    />
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" onClick={closeUploadDialog}>
                        Готово
                    </Button>
                </DialogActions>
            </Dialog>
            <DownloadDialog
                isOpen={downloadDialogOpen}
                onClose={handleCloseDownloadDialog}
                onDownload={handleClickDownloadData}
            />
            <Stack direction={{ xs: "column", md: "row" }} justifyContent="space-between" spacing={1} sx={{ mb: 2 }}>
                <Stack direction="row" alignItems="center" justifyContent="start" spacing={0.5}>
                    <InputLabel htmlFor="environmentSelect">Выберите папку:</InputLabel>
                    <FormControl variant="outlined" sx={{ flex: 1 }}>
                        <Select value={selectedRepository} onChange={handleOptionChange}>
                            <MenuItem value="Code">Code</MenuItem>
                            <MenuItem value="Data">Data</MenuItem>
                            <MenuItem value="Artifacts">Artifacts</MenuItem>
                        </Select>
                    </FormControl>
                </Stack>

                <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={1}>
                    <Tooltip
                        title="Для загрузки в эту папку необходимо предварительно развернуть проект."
                        arrow
                        disableHoverListener={!(selectedRepository === "Data" && project.status === "EMPTY")}
                    >
                        <span>
                            <Button
                                variant="contained"
                                color="primary"
                                size="medium"
                                onClick={openUploadDialog}
                                disabled={
                                    selectedRepository === "Artifacts" ||
                                    (selectedRepository === "Data" && project.status === "EMPTY")
                                }
                                sx={{ flex: "auto" }}
                            >
                                Загрузить данные
                            </Button>
                        </span>
                    </Tooltip>
                    <Button
                        variant="contained"
                        color="primary"
                        size="medium"
                        onClick={handleOpenDownloadDialog}
                        sx={{ flex: "auto" }}
                    >
                        Скачать данные
                    </Button>
                </Stack>
            </Stack>
            <FileExplorer
                project={project}
                selectedDir={selectedRepository}
                setPath={setCurrentPath}
                session={session}
            />

            <GitController />

            <p>
                Использовать как основной репозиторий
                <IconButton
                    size="small"
                    color="inherit"
                    href="https://docs.amvera.ru/applications/git/main-origin.html"
                    target="_blank"
                    rel="noreferrer"
                >
                    <i className="fa-regular fa-circle-question" />
                </IconButton>
                :
            </p>
            <CodeBlock>
                $ git clone {config.gitBaseUri}/{project.ownerName}/{project.slug}
            </CodeBlock>
            <p>
                Или подключить к существующему репозиторию
                <IconButton
                    size="small"
                    color="inherit"
                    href="https://docs.amvera.ru/applications/git/secondary-origin.html"
                    target="_blank"
                    rel="noreferrer"
                >
                    <i className="fa-regular fa-circle-question" />
                </IconButton>
                :
            </p>
            <CodeBlock>
                $ git remote add amvera {config.gitBaseUri}/{project.ownerName}/{project.slug}
            </CodeBlock>
            <p>Отправить изменения (сборка начнется автоматически) :</p>
            <CodeBlock>$ git push amvera master</CodeBlock>
            <p>Или, если используете как основной репозиторий:</p>
            <CodeBlock>$ git push</CodeBlock>
            <p>
                <Link href="https://docs.amvera.ru/applications/git/freq-errors.html" target="_blank" rel="noreferrer">
                    Способы разрешения возможных ошибок.
                </Link>
            </p>
        </div>
    );
};

export default GeneralTab;
