import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { Button, Typography } from "@mui/material";
import _ from "lodash";
import { useServices } from "../../DI/hooks";
import { setError, setLoading, setProjects } from "../../Store/ProjectsSlice";
import ProjectItem, { ProjectItemSkeleton } from "./ProjectItem";
import Nothing, { NothingContainer } from "./Nothing";

const ProjectList = ({ onCreateClick }) => {
    const { api } = useServices();
    const projects = useSelector(
        state => state.projects,
        (prevValue, newValue) =>
            newValue.projects.length && prevValue.projects.length && _.isEqual(prevValue.projects, newValue.projects)
    );
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setLoading());
        api.projects
            .listAll()
            .then(response => (response.ok ? response.json() : Promise.reject(response)))
            .then(data => dispatch(setProjects(data.services)))
            .catch(error => dispatch(setError(error)));
    }, [api, dispatch]);

    if (projects.loading)
        return (
            <>
                <ProjectItemSkeleton />
                <ProjectItemSkeleton />
                <ProjectItemSkeleton />
            </>
        );

    if (projects.error !== null)
        return (
            <NothingContainer>
                <Nothing>
                    <Nothing.Item>
                        <Typography variant="h1" color="darkgrey" sx={{ textAlign: "center", pb: 2 }}>
                            <i className="fa-solid fa-plug-circle-xmark" />
                        </Typography>
                        Ошибка загрузки. Повторите, пожалуйста, попытку позже
                    </Nothing.Item>
                </Nothing>
            </NothingContainer>
        );

    if (Array.isArray(projects.projects) && projects.projects.length === 0)
        return (
            <NothingContainer>
                <Nothing>
                    <Nothing.Item>
                        <Typography variant="h1" color="darkgrey" sx={{ textAlign: "center" }}>
                            <i className="fa-solid fa-sheet-plastic" />
                        </Typography>
                        <div style={{ marginTop: "1em", textAlign: "center" }}>Проектов пока нет.</div>
                        <div style={{ marginTop: "1em", textAlign: "center" }}>
                            <Button
                                size="large"
                                sx={{ textAlign: "center" }}
                                startIcon={<i className="fa-solid fa-plus-circle" />}
                                onClick={onCreateClick}
                            >
                                Создайте первый!
                            </Button>
                        </div>

                        <div style={{ color: "darkgrey", marginTop: "1em", textAlign: "center" }}>
                            <div>Создание проекта бесплатно: списания производятся только за работающие</div>
                            <div>контейнеры в статусах “Приложение запущено” или “Приложение работает с ошибкой”</div>
                        </div>
                    </Nothing.Item>
                </Nothing>
            </NothingContainer>
        );

    return (
        <>
            {projects.projects.map(project => (
                <ProjectItem project={project} key={project.slug} />
            ))}
        </>
    );
};

export default ProjectList;
