import { useCallback, useState } from "react";
import useStatusApi from "../../../../Hooks/useStatusApi";

const useRestoreBackup = endpoint => {
    const [success, error] = useStatusApi();
    const [restoreLoading, setRestoreLoading] = useState(false);

    const handleRestoreBackup = useCallback(
        async (backupName, ...args) => {
            setRestoreLoading(true);
            try {
                const res = await endpoint(backupName, ...args);
                if (!res.ok) throw Error(res.status);

                success("Бэкап успешно восстановлен");

                return await res.json();
            } catch (e) {
                error(e.message, "Произошла ошибка в восстановлении бэкапа.");
            } finally {
                setRestoreLoading(false);
            }
        },
        [endpoint, error, success]
    );

    return { restoreLoading, setRestoreLoading, handleRestoreBackup };
};

export default useRestoreBackup;
