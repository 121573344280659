import { configureStore } from "@reduxjs/toolkit";
import billingUlReducer from "./BillingUlSlice";
import balanceReducer from "./BalanceSlice";
import currentProjectReducer from "./CurrentProjectSlice";
import editableVariableReducer from "./EditableVariableSlice";
import projectsReducer from "./ProjectsSlice";
import tariffsReducer from "./TariffsSlice";
import domainReducer from "./DomainSlice";
import dbEventStreamReducer from "./DbEventStreamSlice";
import dataBackupStreamReducer from "./DataBackupStreamSlice";

const store = configureStore({
    reducer: {
        billingUl: billingUlReducer,
        balance: balanceReducer,
        currentProject: currentProjectReducer,
        editableVariable: editableVariableReducer,
        projects: projectsReducer,
        tariffs: tariffsReducer,
        domain: domainReducer,
        dbEventStream: dbEventStreamReducer,
        dataBackupStream: dataBackupStreamReducer
    }
});

export default store;
