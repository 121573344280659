import { useCallback, useState } from "react";
import { useSnackbar } from "notistack";
import { useServices } from "../../../../DI/hooks";
import { SNACKBAR_TYPE } from "../../../../constants";

const useDeleteDb = (slug, deleteDetails) => {
    const { enqueueSnackbar } = useSnackbar();
    const { api } = useServices();
    const [loading, setLoading] = useState(false);

    const handleDeleteDb = useCallback(
        async name => {
            setLoading(true);

            try {
                const res = await api.mysql.deleteDb(slug, name);
                if (!res.ok) {
                    throw Error(res.status);
                }
                enqueueSnackbar(`База данных успешно удалилась`, SNACKBAR_TYPE.SUCCESS);
                deleteDetails(name);
            } catch (e) {
                enqueueSnackbar(`${e.message}: Ошибка создания базы данных`, SNACKBAR_TYPE.ERROR);
            } finally {
                setLoading(false);
            }
        },
        [deleteDetails, slug, enqueueSnackbar, api.mysql.deleteDb]
    );

    return [loading, handleDeleteDb];
};

export default useDeleteDb;
