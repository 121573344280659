import _ from "lodash";

export default class HttpService {
    constructor(auth, api_base) {
        this.auth = auth;
        this.api_base = api_base;
    }

    call(method, address, body = null, headers = {}, accept = "application/json", content_type = "application/json") {
        const default_headers = {
            "Authorization": `Bearer ${this.auth.user?.access_token}`,
            "Accept": accept,
            "Content-Type": content_type
        };
        return fetch(this.api_base + address, {
            method: method,
            headers: _.merge(default_headers, headers),
            body: body != null ? JSON.stringify(body) : undefined
        });
    }

    get(address) {
        return this.call("GET", address);
    }

    post(address, body) {
        return this.call("POST", address, body);
    }

    put(address, body) {
        return this.call("PUT", address, body);
    }

    patch(address, body) {
        return this.call("PATCH", address, body);
    }

    delete(address) {
        return this.call("DELETE", address);
    }
}
