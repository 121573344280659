import { Skeleton } from "@mui/material";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useServices } from "../../../DI/hooks";
import { setBalance, setError, setLoading } from "../../../Store/BalanceSlice";

const BalanceDisplay = () => {
    const { api } = useServices();
    const balance = useSelector(state => state.balance);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setLoading());
        api.balance
            .listAll()
            .then(data => data.json())
            .then(data => dispatch(setBalance(data.balance)))
            .catch(err => dispatch(setError(err)));
    }, [api, dispatch]);

    if (balance.loading)
        return <Skeleton animation="wave" variant="rounded" width="8ch" sx={{ display: "inline-block" }} />;

    if (balance.error != null) {
        console.error(balance.error);
        return <span>Ошибка загрузки</span>;
    }

    return <>₽{balance?.balance?.toFixed(2)}</>;
};

export default BalanceDisplay;
