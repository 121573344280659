import { forwardRef, useCallback, useEffect, useState } from "react";
import { Card, CardActions, Collapse, IconButton, Paper, Typography, Stack } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import { SnackbarContent, useSnackbar } from "notistack";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { styled } from "@mui/material/styles";
import { activeMessageLocalStorageKey } from "../../../Hooks/usePersistentSnackbar";
import { useEventContext } from "../../../Context/EventContext";

const StyledSnackbarContent = styled(SnackbarContent)(({ theme }) => ({
    [theme.breakpoints.up("sm")]: {
        minWidth: "325px !important"
    }
}));

const MessageSnackbar = forwardRef((props, ref) => {
    const { closeSnackbar } = useSnackbar();
    const { activeMessage } = useEventContext();

    const local = JSON.parse(localStorage.getItem(activeMessageLocalStorageKey)) || {};
    const expandedDefault = local.expandedDefault !== undefined ? local.expandedDefault : true;
    const [expanded, setExpanded] = useState(expandedDefault);

    const handleExpandClick = () => {
        setExpanded(prevState => !prevState);
        const localActiveMessage = JSON.parse(localStorage.getItem(activeMessageLocalStorageKey)) || {};

        localStorage.setItem(
            activeMessageLocalStorageKey,
            JSON.stringify({
                ...localActiveMessage,
                expandedDefault: !localActiveMessage.expandedDefault
            })
        );
    };

    useEffect(() => {
        const localActiveMessage = JSON.parse(localStorage.getItem(activeMessageLocalStorageKey)) || {};

        if (
            localActiveMessage.id === activeMessage.id &&
            localActiveMessage.title === activeMessage.title &&
            localActiveMessage.content === activeMessage.content
        ) {
            return;
        }

        setExpanded(true);
        localStorage.setItem(
            activeMessageLocalStorageKey,
            JSON.stringify({
                ...activeMessage,
                expandedDefault: true
            })
        );
    }, [activeMessage]);

    const handleDismiss = () => {
        closeSnackbar(activeMessage.id);
    };

    return (
        <StyledSnackbarContent ref={ref}>
            <Card sx={{ width: "100%", bgcolor: "primary.main" }}>
                <CardActions sx={{ justifyContent: "space-between" }}>
                    <Typography variant="body2" fontSize={12} fontWeight={600}>
                        {activeMessage.title}
                    </Typography>
                    <Stack direction="row" justifyContent="center" alignItems="center">
                        <IconButton aria-label="Show more" size="small" onClick={handleExpandClick}>
                            {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                        </IconButton>
                        <IconButton size="small" onClick={handleDismiss}>
                            <CloseIcon fontSize="small" />
                        </IconButton>
                    </Stack>
                </CardActions>
                <Collapse in={expanded} unmountOnExit>
                    <Paper sx={{ px: 2, py: 1, borderRadius: 0 }}>
                        <Stack sx={{ maxWidth: { sm: "290px" } }} spacing={1}>
                            <Typography fontSize={12} sx={{ overflowX: "auto" }}>
                                {activeMessage.content}
                            </Typography>
                        </Stack>
                    </Paper>
                </Collapse>
            </Card>
        </StyledSnackbarContent>
    );
});

export default MessageSnackbar;
