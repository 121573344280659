import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Button,
    Checkbox,
    CircularProgress,
    Container,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormHelperText,
    FormLabel,
    IconButton,
    MenuItem,
    Select,
    Stack,
    TextField,
    Typography
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { useCallback, useState } from "react";
import { useSnackbar } from "notistack";
import { useSelector } from "react-redux";
import LoadingButton from "@mui/lab/LoadingButton";
import _ from "lodash";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useServices } from "../../../DI/hooks";
import { useConfig } from "../../../Configuration/hooks";
import { SNACKBAR_TYPE } from "../../../constants";
import ConfirmationDialog from "../ConfirmationDialog";
import TooltipTextField from "../TooltipTextField";
import GenerateSecret from "../Tooltips/GenerateSecret";
import { generateSecret } from "../Tooltips/GenerateSecret/lib/generateSecret";
import InfoToolTip from "../Tooltips/InfoTooltip";
import CodeStyled from "../../../shared/CodeStyle";

const GitController = () => {
    const { enqueueSnackbar } = useSnackbar();
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const { project: service } = useSelector(state => state.currentProject);
    const [isNew, setIsNew] = useState(true);
    const [isSecret, setIsSecret] = useState(false);
    const [isToken, setIsToken] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    const { api } = useServices();
    const { webhookGitBaseUri } = useConfig();

    const defaultFormValues = {
        serviceId: service.id,
        source: "GIT_HUB",
        pushEvent: false,
        ciEvent: false,
        remoteBranch: "",
        secret: generateSecret(),
        token: null
    };

    const sources = {
        GIT_HUB: "github",
        GIT_LAB: "gitlab",
        BITBUCKET: "bitbucket"
    };

    const {
        reset,
        control,
        handleSubmit,
        setValue,
        formState: { isSubmitting, isDirty, errors },
        watch
    } = useForm({
        defaultValues: defaultFormValues
    });

    const handleAccordianExpand = useCallback(
        async (event, expand) => {
            if (expand) {
                try {
                    const res = await api.gitController.getEvent(service.id);

                    if (!res.ok) {
                        throw new Error(res.status);
                    }
                    const { serviceId, source, pushEvent, ciEvent, remoteBranch, secret, token } = await res.json();

                    setIsNew(false);
                    setIsSecret(secret);
                    setIsToken(token);

                    reset({
                        serviceId,
                        source,
                        pushEvent,
                        ciEvent,
                        remoteBranch,
                        secret: null,
                        token: null
                    });
                } catch (error) {}

                setIsLoading(false);
            }
        },
        [service.id, api.gitController, reset]
    );

    const [source, pushEvent, ciEvent] = watch(["source", "pushEvent", "ciEvent"]);

    const onSubmit = async data => {
        try {
            const response = isNew
                ? await api.gitController.addEvent({ ...data, serviceName: service.name, serviceSlug: service.slug })
                : await api.gitController.editEvent(data);

            if (!response.ok) {
                throw new Error(response.status);
            }

            const { serviceId, source, pushEvent, ciEvent, remoteBranch, secret, token } = await response.json();

            reset({
                serviceId,
                source,
                pushEvent,
                ciEvent,
                remoteBranch,
                secret: null,
                token: null
            });

            setIsToken(token);
            setIsSecret(secret);
            await setIsNew(false);

            enqueueSnackbar("Репозиторий подключен", SNACKBAR_TYPE.SUCCESS);
        } catch (e) {
            enqueueSnackbar(`Ошибка ${e.message}`, SNACKBAR_TYPE.ERROR);
        }
    };

    const handleDeleteDialog = useCallback(() => {
        setIsDialogOpen(prev => !prev);
    }, [setIsDialogOpen]);

    const handleDelete = useCallback(() => {
        api.gitController
            .deleteEvent(service.id)
            .then(res => !res.ok && Promise.reject(res))
            .then(() => {
                reset(defaultFormValues);
                setIsToken(false);
                setIsSecret(false);
                setIsNew(true);
                handleDeleteDialog();
                enqueueSnackbar("Успешное удаление", SNACKBAR_TYPE.SUCCESS);
            })
            .catch(error => enqueueSnackbar(`Ошибка ${error}`, SNACKBAR_TYPE.ERROR));
    }, [api.gitController, enqueueSnackbar, handleDeleteDialog, service.id, reset]);

    const handleURLClick = e => {
        navigator.clipboard
            .writeText(e.target.value)
            .then(() => enqueueSnackbar("Скопировано в буфер", SNACKBAR_TYPE.INFO));
    };

    const handleSetValue = useCallback(
        (field, value) => {
            setValue(field, value, { shouldDirty: true });
        },
        [setValue]
    );

    return (
        <div>
            <Accordion square={false} disableGutters onChange={handleAccordianExpand}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Stack direction="row" spacing={0.5}>
                        <Typography variant="h6">Подключить GitHub, GitLab, Bitbucket</Typography>
                        <IconButton
                            size="small"
                            color="inherit"
                            href="https://docs.amvera.ru/applications/git/webhooks.html"
                            target="_blank"
                            rel="noreferrer"
                            onClick={e => e.stopPropagation()}
                        >
                            <i className="fa-regular fa-circle-question" />
                        </IconButton>
                    </Stack>
                </AccordionSummary>
                <AccordionDetails>
                    {isLoading ? (
                        <Stack justifyContent="center" alignItems="center" p="24px">
                            <CircularProgress disableShrink />
                        </Stack>
                    ) : (
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <Container>
                                <Stack maxWidth="400px" mb={2} useFlexGap gap={1}>
                                    <Stack
                                        direction={{ xs: "column", sm: "row" }}
                                        alignItems="start"
                                        justifyItems="center"
                                        height="100%"
                                    >
                                        <FormControl fullWidth>
                                            <FormLabel>Git service:</FormLabel>
                                            <Controller
                                                name="source"
                                                control={control}
                                                render={({ field }) => (
                                                    <Select {...field}>
                                                        <MenuItem value="GIT_HUB">GitHub</MenuItem>
                                                        <MenuItem value="GIT_LAB">GitLab</MenuItem>
                                                        <MenuItem value="BITBUCKET">Bitbucket</MenuItem>
                                                    </Select>
                                                )}
                                            />
                                        </FormControl>

                                        <FormControl id="github-checkboxes" fullWidth sx={{ mt: "23px" }}>
                                            <FormGroup
                                                sx={{ flexDirection: "row", justifyContent: "center", py: "7px" }}
                                            >
                                                <FormControlLabel
                                                    sx={{ m: 0 }}
                                                    labelPlacement="start"
                                                    label="Push"
                                                    control={
                                                        <Controller
                                                            name="pushEvent"
                                                            control={control}
                                                            rules={{ validate: value => value || ciEvent }}
                                                            render={({ field }) => (
                                                                <Checkbox {...field} checked={field.value} />
                                                            )}
                                                        />
                                                    }
                                                />
                                                <FormControlLabel
                                                    labelPlacement="start"
                                                    label="CI"
                                                    control={
                                                        <Controller
                                                            name="ciEvent"
                                                            control={control}
                                                            rules={{ validate: value => value || pushEvent }}
                                                            render={({ field }) => (
                                                                <Checkbox
                                                                    {...field}
                                                                    name="ciEvent"
                                                                    checked={field.value}
                                                                />
                                                            )}
                                                        />
                                                    }
                                                />
                                            </FormGroup>
                                            {errors.pushEvent && errors.ciEvent ? (
                                                <FormHelperText error>
                                                    Обязательное поле, хотя бы один вариант
                                                </FormHelperText>
                                            ) : null}
                                        </FormControl>
                                    </Stack>

                                    <FormControl fullWidth>
                                        <FormLabel>URL:</FormLabel>
                                        <TooltipTextField
                                            value={`${webhookGitBaseUri}/${sources[source]}/${service.id}`}
                                            onClick={handleURLClick}
                                            InputProps={{ readOnly: true }}
                                        >
                                            <InfoToolTip title="Ссылка, которую нужно вставить в настройках webhook" />
                                        </TooltipTextField>
                                    </FormControl>

                                    <Stack direction={{ xs: "column" }} spacing={1}>
                                        <FormControl fullWidth>
                                            <FormLabel>Секрет:</FormLabel>
                                            <Controller
                                                control={control}
                                                rules={{
                                                    required: isNew && "Укажите секрет",
                                                    setValueAs: v => (_.isEmpty(v) ? null : v)
                                                }}
                                                render={({ field: { onChange, onBlur, value } }) => (
                                                    <TooltipTextField
                                                        value={value || ""}
                                                        onBlur={onBlur}
                                                        onChange={onChange}
                                                        placeholder={isSecret && "*******"}
                                                    >
                                                        <GenerateSecret setValue={handleSetValue} field="secret" />
                                                        <InfoToolTip
                                                            title={`Обязательный параметр.\nПо нему осуществляется валидация запроса`}
                                                        />
                                                    </TooltipTextField>
                                                )}
                                                name="secret"
                                            />

                                            <FormHelperText error>
                                                {errors.secret && errors.secret.message}
                                            </FormHelperText>
                                        </FormControl>

                                        <FormControl fullWidth>
                                            <FormLabel>Токен:</FormLabel>
                                            <Controller
                                                control={control}
                                                rules={{
                                                    setValueAs: v => (_.isEmpty(v) ? null : v)
                                                }}
                                                render={({ field: { onChange, onBlur, value } }) => (
                                                    <TooltipTextField
                                                        value={value || ""}
                                                        onBlur={onBlur}
                                                        onChange={onChange}
                                                        placeholder={isToken && "*******"}
                                                    >
                                                        <InfoToolTip title="Необходим при привязке к приватному репозиторию. Генерируется в настройках Git-сервиса" />
                                                    </TooltipTextField>
                                                )}
                                                name="token"
                                            />
                                        </FormControl>

                                        <FormControl>
                                            <FormLabel>Ветка удаленного репозитория:</FormLabel>
                                            <Controller
                                                rules={{
                                                    required: "Обязательное поле",
                                                    pattern: /^[a-zA-Z0-9_./-]+$/
                                                }}
                                                name="remoteBranch"
                                                control={control}
                                                render={({ field }) => (
                                                    <TextField defaultValue={field.value} {...field} />
                                                )}
                                            />
                                            {errors.remoteBranch && (
                                                <FormHelperText error>{errors.remoteBranch.message}</FormHelperText>
                                            )}
                                            <FormHelperText
                                                error={errors.remoteBranch && errors.remoteBranch.type === "pattern"}
                                            >
                                                Можно использовать только строчные, заглавные латинские буквы, цифры и
                                                спец символы: <CodeStyled>_</CodeStyled>, <CodeStyled>-</CodeStyled>,{" "}
                                                <CodeStyled>/</CodeStyled> и <CodeStyled>.</CodeStyled>.
                                            </FormHelperText>
                                        </FormControl>
                                    </Stack>

                                    {isNew ? (
                                        <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
                                            Подключить
                                        </LoadingButton>
                                    ) : (
                                        <Stack direction="row" spacing={2}>
                                            <LoadingButton
                                                type="submit"
                                                variant="contained"
                                                disabled={!isDirty}
                                                loading={isSubmitting}
                                                fullWidth
                                            >
                                                Изменить
                                            </LoadingButton>
                                            <Button fullWidth color="error" onClick={handleDeleteDialog}>
                                                Удалить
                                            </Button>
                                            <ConfirmationDialog
                                                title="Удаление соединения"
                                                description="Вы действительно хотите удалить?"
                                                open={isDialogOpen}
                                                onClickConfirm={handleDelete}
                                                onClose={handleDeleteDialog}
                                            />
                                        </Stack>
                                    )}
                                </Stack>
                            </Container>
                        </form>
                    )}
                </AccordionDetails>
            </Accordion>
        </div>
    );
};

export default GitController;
