import { useCallback, useMemo, useState } from "react";
import { IconButton } from "@mui/material";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import DeleteDialog from "./DeleteDialog";
import useDeleteDb from "../api/useDeleteDb";
import useIsDisabled from "../../SingleMySQLPage/hooks/useIsDisabled";

export default function DeleteDbButton({ status, name, slug, deleteDetails }) {
    const [isDialog, setIsDialog] = useState(false);
    const [dbLoading, dbDelete] = useDeleteDb(slug, deleteDetails);

    const { isDisabled } = useIsDisabled();

    const handleOpenDialog = useCallback(() => {
        setIsDialog(true);
    }, []);
    const handleCloseDialog = useCallback(() => {
        setIsDialog(false);
    }, []);

    const handleDeleteDb = () => {
        dbDelete(name).then(() => handleCloseDialog());
        console.log(`added ${name}`);
    };

    const isDeleteDbDisabled = useMemo(() => status === "CREATING" || isDisabled, [status, isDisabled]);

    return (
        <>
            <IconButton disabled={isDeleteDbDisabled} onClick={handleOpenDialog}>
                <DeleteOutlineOutlinedIcon color="primary" />
            </IconButton>
            <DeleteDialog
                open={isDialog}
                onClose={handleCloseDialog}
                type="db"
                onClick={handleDeleteDb}
                loading={dbLoading}
            />
        </>
    );
}
