import { useMemo } from "react";
import { useMySQLServiceStatus } from "../../SingleDbPage/context/MySQLServiceStatus";

const useIsDisabled = () => {
    const { status, backupStatus } = useMySQLServiceStatus();
    const isDisabled = useMemo(() => status !== "RUNNING", [status]);

    return { isDisabled, backupStatus };
};

export default useIsDisabled;
