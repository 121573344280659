import { useParams } from "react-router-dom";
import SingleMySQLPage from "../SingleMySQLPage";
import SingleCnpgPage from "../SingleCnpgPage";

export default function SingleDbRedirect() {
    const { serviceType } = useParams();

    if (serviceType === "pxc") return <SingleMySQLPage />;

    return <SingleCnpgPage />;
}
