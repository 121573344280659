import { useCallback, useEffect, useState } from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Stack,
    TextField
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { useNavigate } from "react-router-dom";

import ProjectSettings from "../../../Elements/ProjectSettings";
import { setError } from "../../../../Store/CurrentProjectSlice";
import { useServices } from "../../../../DI/hooks";
import SingleProjectPageSkeleton from "../../SingleProjectPageSkeleton";

export function DbProjectSettingsTab({ project, slug, doScale, deleteDb }) {
    const navigate = useNavigate();
    const { api } = useServices();
    const [tariffUpdating, setTariffUpdating] = useState(false);
    const [currentTariff, setCurrentTariff] = useState(undefined);
    const [deleteProjectDialogOpened, setDeleteProjectDialogOpened] = useState(false);
    const [projectDeleting, setProjectDeleting] = useState(false);
    const [deleteProjectCheckString, setDeleteProjectCheckString] = useState("");
    const [deleteProjectError, setDeleteProjectError] = useState(null);

    const getCurrentTariff = useCallback(() => {
        api.projects
            .getTariff(slug)
            .then(response => (response.ok ? response.json() : Promise.reject()))
            .then(data => setCurrentTariff(data))
            .catch(e => {
                console.error(e);
                setError("Сервер не отвечает, повторите, пожалуйста, попытку позже");
            });
    }, [api, slug]);

    useEffect(() => {
        getCurrentTariff();
    }, [getCurrentTariff]);

    const handleUpdateTariffId = useCallback(
        tariffId => {
            setTariffUpdating(true);
            api.projects
                .updateTariffById(slug, tariffId)
                .then(response => {
                    if (!response.ok) return Promise.reject(response);
                    // getCurrentService();
                    getCurrentTariff();
                    setTariffUpdating(false);
                    return Promise.resolve();
                })
                .catch(e => {
                    console.error(e);
                    setError("Сервер не отвечает, повторите, пожалуйста, попытку позже");
                    setTariffUpdating(false);
                });
        },
        [api, getCurrentTariff, slug]
    );

    const handleOpenDeleteProjectDialogClick = useCallback(() => {
        setDeleteProjectDialogOpened(true);
    }, []);

    const handleFreezeProject = () => {
        doScale(0);
    };

    const handleDeleteProjectDialogClose = useCallback(() => {
        if (!projectDeleting) setDeleteProjectDialogOpened(false);
    }, [projectDeleting]);

    const handleDeleteProjectClick = useCallback(() => {
        if (deleteProjectCheckString.toLowerCase() !== `удалить навсегда ${project.name}`.toLowerCase()) {
            setDeleteProjectError("Проверочная строка не совпадает");
            return;
        }

        setProjectDeleting(true);

        deleteDb(project.slug)
            .then(() => {
                setProjectDeleting(false);
                navigate("/projects");
            })
            .catch(error => {
                console.error(error);
                setDeleteProjectError("Сервер не отвечает, попробуйте, пожалуйста, повторить попытку позже");
            });
    }, [project.slug, api, navigate, project, deleteProjectCheckString, deleteDb]);

    const handleDeleteProjectCheckStringChange = useCallback(event => {
        setDeleteProjectCheckString(event.target.value);
        setDeleteProjectError(null);
    }, []);

    const handleCloseDeleteProjectDialog = useCallback(() => {
        setDeleteProjectDialogOpened(false);
    }, []);

    return (
        <>
            {currentTariff ? (
                <ProjectSettings
                    tariffUpdating={tariffUpdating}
                    // tariffId={service?.tariffId}
                    tariffId={currentTariff?.id}
                    project={project}
                    handleUpdateTariffId={handleUpdateTariffId}
                    handleOpenDeleteProjectDialogClick={handleOpenDeleteProjectDialogClick}
                    handleFreezeProject={handleFreezeProject}
                />
            ) : (
                <SingleProjectPageSkeleton />
            )}
            <Dialog open={deleteProjectDialogOpened} onClose={handleDeleteProjectDialogClose}>
                <DialogTitle>Удалить проект {project.name}?</DialogTitle>
                <DialogContent dividers>
                    <Stack spacing={2}>
                        <DialogContentText>
                            Удаление проекта остановит все работающие инстансы и{" "}
                            <strong>удалит репозиторий с кодом</strong>. После того, как вы нажмете на кнопку
                            &laquo;Удалить&raquo;, никто &mdash; даже мы &mdash;
                            <strong> не сможет восстановить данные</strong>. Если вы все еще хотите удалить проект,
                            введите следующую строку в поле для ввода:
                        </DialogContentText>
                        <DialogContentText>
                            <strong>удалить навсегда {project.name}</strong>
                        </DialogContentText>
                        <TextField
                            required
                            fullWidth
                            autoFocus
                            id="amvera-delete-project-dialog"
                            value={deleteProjectCheckString}
                            onChange={handleDeleteProjectCheckStringChange}
                            error={deleteProjectError !== null}
                            helperText={deleteProjectError}
                            disabled={projectDeleting}
                            autoComplete="off"
                            onPaste={event => event.preventDefault()}
                        />
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" color="secondary" onClick={handleCloseDeleteProjectDialog}>
                        Отмена
                    </Button>
                    <LoadingButton
                        variant="contained"
                        color="error"
                        loading={projectDeleting}
                        onClick={handleDeleteProjectClick}
                    >
                        Удалить
                    </LoadingButton>
                </DialogActions>
            </Dialog>
        </>
    );
}
