import { useParams } from "react-router-dom";
import { Button, CircularProgress, Divider, IconButton, Paper, Stack, Tooltip, Typography } from "@mui/material";
import moment from "moment/moment";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import FolderZipOutlinedIcon from "@mui/icons-material/FolderZipOutlined";
import { useCallback, useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import { SNACKBAR_TYPE } from "../../constants";
import { useServices } from "../../DI/hooks";
import useDataBackupEventStream from "../Pages/SingleProjectPage/hooks/useDataBackupEventStream";

const tooltipText = status => {
    switch (status) {
        case "EMPTY":
            return "Не найден. Попробуйте подготовить позднее";
        case "READY":
            return "Подготовить заново";
        case "ERROR":
            return "Ошибка. Попробуйте подготовить заново";
        default:
            return "Подготовить для скачивания";
    }
};

const downloadButtonColor = status => {
    switch (status) {
        case "EMPTY":
            return { color: "#CF3A3A" };
        case "ERROR":
            return { color: "#CF3A3A" };
        default:
            return { color: "#BB4F11" };
    }
};

export function BackupTab({ tariffId }) {
    const { slug } = useParams();
    const { api, fileService } = useServices();
    const { enqueueSnackbar } = useSnackbar();
    const [dataBackups, setDataBackups] = useState([]);
    const { backupStatus } = useDataBackupEventStream();

    // listen sse for data backup status
    useEffect(() => {
        if (backupStatus && backupStatus.serviceSlug === slug) {
            const { status, createdAt } = backupStatus;
            setDataBackups(prev =>
                prev.map(b => {
                    if (b.createdAt === createdAt) {
                        b.status = status;
                        return b;
                    }
                    return b;
                })
            );
        }
    }, [backupStatus, setDataBackups, slug]);

    // backup fetch
    useEffect(() => {
        async function fetchBackups(serviceSlug) {
            const response = await api.fileStorage.getBackups(serviceSlug);
            const data = await response.json();
            setDataBackups(data);
            return data;
        }

        fetchBackups(slug);
    }, [slug, api, setDataBackups]);

    const isEnabledToPrepare = useCallback(status => {
        if (status === "CREATING") return false;
        return true;
    }, []);

    const isEnabledToDownload = useCallback(status => {
        if (status === "READY") return true;
        return false;
    }, []);

    const handlePrepareBackup = useCallback(
        async createdAt => {
            const response = await api.fileStorage.prepareBackup(slug, createdAt);

            if (response.status === 200) {
                const data = await response.json();
                setDataBackups(prev =>
                    prev.map(b => {
                        if (b.createdAt === data.createdAt) {
                            b.status = data.status;
                            return b;
                        }
                        return b;
                    })
                );
            } else {
                const data = await response.json();
                if (data.error) {
                    enqueueSnackbar(data.message, SNACKBAR_TYPE.ERROR);
                }
            }
        },
        [slug, api, setDataBackups, enqueueSnackbar]
    );

    const handleDownloadBackup = useCallback(
        async createdAt => {
            fileService.downloadBackup(
                `/file-storage/userdata/${slug}/download_token`,
                "/file-storage/userdata-backup/download",
                createdAt
            );
        },
        [slug, fileService]
    );

    return tariffId === 4 || tariffId === 1 ? (
        <Stack justifyContent="center" direction="row">
            <h3>{`Бэкапы доступны для проектов с тарифным планом "Начальный плюс" и выше`}</h3>
        </Stack>
    ) : (
        <Stack spacing={2}>
            <Stack spacing={2} direction="row">
                <Button
                    variant="outlined"
                    color="info"
                    startIcon={<i className="fa-solid fa-circle-info" />}
                    href="https://docs.amvera.ru/applications/backups.html#id1"
                    target="_blank"
                    rel="noreferrer"
                >
                    Бэкапы
                </Button>
            </Stack>

            <Stack>
                <Stack flexWrap direction="row" mb="5px">
                    <Typography variant="h6">
                        Бэкапы /data
                        <IconButton
                            size="small"
                            color="inherit"
                            href="https://docs.amvera.ru/applications/backups.html#data"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <i className="fa-regular fa-circle-question" />
                        </IconButton>
                    </Typography>
                </Stack>

                <Paper sx={{ padding: 2 }}>
                    <Stack divider={<Divider orientation="horizontal" flexItem />} spacing={1}>
                        {dataBackups.length ? (
                            dataBackups.map((b, i) => (
                                <Stack
                                    key={b.createdAt}
                                    paddingX="16px"
                                    paddingBottom={i === dataBackups.length - 1 ? "0px" : "8px"}
                                    paddingTop={i === dataBackups.length - 1 ? "8px" : "0px"}
                                >
                                    <Stack direction="row" justifyContent="space-between">
                                        <Stack direction="row" spacing={1} alignItems="center">
                                            <Typography>
                                                {moment.utc(b.createdAt).format("YYYY-MM-DD hh:mm:ss")}
                                            </Typography>
                                        </Stack>

                                        <Stack direction="row">
                                            {b?.status === "CREATING" ? (
                                                <Stack
                                                    justifyContent="center"
                                                    alignItems="center"
                                                    width={40}
                                                    height={40}
                                                >
                                                    <CircularProgress size={16} />
                                                </Stack>
                                            ) : (
                                                <Tooltip arrow disableInteractive title={tooltipText(b.status)}>
                                                    <IconButton
                                                        disabled={!isEnabledToPrepare(b.status)}
                                                        onClick={() => handlePrepareBackup(b.createdAt)}
                                                    >
                                                        <FolderZipOutlinedIcon style={downloadButtonColor(b.status)} />
                                                    </IconButton>
                                                </Tooltip>
                                            )}

                                            <Tooltip arrow disableInteractive title="Скачать бэкап">
                                                <span>
                                                    <IconButton
                                                        disabled={!isEnabledToDownload(b.status)}
                                                        onClick={() => handleDownloadBackup(b.createdAt)}
                                                    >
                                                        <DownloadOutlinedIcon color="primary" />
                                                    </IconButton>
                                                </span>
                                            </Tooltip>
                                        </Stack>
                                    </Stack>
                                </Stack>
                            ))
                        ) : (
                            <Stack justifyContent="center" direction="row">
                                <h3>Бэкапы отсутствуют</h3>
                            </Stack>
                        )}
                    </Stack>
                </Paper>
            </Stack>
        </Stack>
    );
}
