import { Button, Dialog, DialogActions, DialogTitle } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";

export default function DeleteDialog({ open, onClose, type, onClick, loading }) {
    const dialogVariants = {
        user: {
            title: "пользователя"
        },
        db: {
            title: "базу данных"
        }
    };

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>Вы действительно хотите удалить {dialogVariants[type].title}?</DialogTitle>
            <DialogActions>
                <Button onClick={onClose}>Отмена</Button>
                <LoadingButton loading={loading} onClick={onClick}>
                    Удалить
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
}
