import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Divider,
    Stack,
    TextField
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import dayjs from "dayjs";
import { useServices } from "../../../../../../DI/hooks";
import { setError } from "../../../../../../Store/BillingUlSlice";

function ReconciliationReportDialog({ isOpen, handleClose, defaultFromDate, defaultToDate, handleSubmit }) {
    const [fromDate, setFromDate] = useState(defaultFromDate);
    const [toDate, setToDate] = useState(defaultToDate);

    const { api } = useServices();
    const dispatch = useDispatch();

    useEffect(() => {
        setToDate(defaultToDate);
    }, [defaultToDate]);

    useEffect(() => {
        setFromDate(defaultFromDate);
    }, [defaultFromDate]);

    const handleOrderReconciliationReport = () => {
        api.billingUl
            .orderReconciliationReport({
                from: dayjs(fromDate).format("DD.MM.YYYY"),
                to: dayjs(toDate).format("DD.MM.YYYY")
            })
            .then(data => data.json())
            .catch(err => dispatch(setError(err)));
        handleSubmit();
        handleClose();
    };

    return (
        <Dialog
            open={isOpen}
            onClose={handleClose}
            aria-labelledby="order-dialog-title"
            aria-describedby="order-dialog-description"
            fullWidth
        >
            <DialogTitle id="order-dialog-title">Заказать акт сверки</DialogTitle>
            <DialogContent>
                <Stack spacing={2}>
                    <DialogContentText id="order-dialog-description">Период выборки</DialogContentText>
                    <Stack direction="row" spacing={1}>
                        <DatePicker
                            label="От"
                            value={fromDate}
                            onChange={value => setFromDate(value)}
                            renderInput={params => <TextField {...params} />}
                            disableFuture
                            inputFormat="DD/MM/YYYY"
                        />
                        <DatePicker
                            label="До"
                            value={toDate}
                            onChange={value => setToDate(value)}
                            renderInput={params => <TextField {...params} />}
                            disableFuture
                            inputFormat="DD/MM/YYYY"
                        />
                    </Stack>
                </Stack>
            </DialogContent>
            <Divider />
            <DialogActions>
                <Button onClick={handleClose} variant="outlined" size="large">
                    Отменить
                </Button>
                <Button onClick={handleOrderReconciliationReport} autoFocus variant="contained" size="large">
                    Заказать
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default ReconciliationReportDialog;
