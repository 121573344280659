import { IconButton, Menu, MenuItem } from "@mui/material";
import { useState } from "react";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useDispatch } from "react-redux";
import SendByEmailDialog from "../SendByEmailDialog";
import { useServices } from "../../../../../../DI/hooks";
import { setError } from "../../../../../../Store/BillingUlSlice";

function MoreMenu({ documentId, name }) {
    const [anchorEl, setAnchorEl] = useState(null);
    const isMenuOpen = Boolean(anchorEl);
    const [isDialogOpen, setIsDialogOpen] = useState(false);

    const { api } = useServices();
    const dispatch = useDispatch();

    const handleOpenMenu = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    const handleOpenDialog = () => {
        setIsDialogOpen(true);
    };

    const handleCloseDialog = () => {
        setIsDialogOpen(false);
        handleCloseMenu();
    };

    const handleDownloadDocument = () => {
        api.billingUl
            .downloadUlDocument({ id: documentId })
            .then(data => data.blob())
            .then(data => {
                const blob = new Blob([data], {
                    type: "application/pdf"
                });

                const element = document.createElement("a");
                element.href = URL.createObjectURL(blob);
                element.download = `${name}.pdf`;

                document.body.appendChild(element);
                element.click();
            })
            .catch(err => {
                dispatch(setError(err));
                console.log("Download error", err);
            });

        handleCloseMenu();
    };

    const handleSendByEmail = () => {
        handleOpenDialog();
    };

    return (
        <>
            <IconButton
                id="more-menu-button"
                aria-controls={isMenuOpen ? "more-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={isMenuOpen ? "true" : undefined}
                onClick={handleOpenMenu}
            >
                <MoreVertIcon />
            </IconButton>
            <Menu
                id="more-menu"
                anchorEl={anchorEl}
                open={isMenuOpen}
                onClose={handleCloseMenu}
                MenuListProps={{
                    "aria-labelledby": "more-menu-button"
                }}
            >
                <MenuItem onClick={handleDownloadDocument}>Скачать</MenuItem>
                <MenuItem onClick={handleSendByEmail}>Отправить на почту</MenuItem>
                <SendByEmailDialog isOpen={isDialogOpen} handleClose={handleCloseDialog} documentId={documentId} />
            </Menu>
        </>
    );
}

export default MoreMenu;
