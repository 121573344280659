import { withMySQL } from "../SingleDbPage/hoc/withMySQL";
import SingleDbPage from "../SingleDbPage";
import { MySQLBackupTab, MySQLConfigurationTab } from "./components";

const mySQLTabs = [
    {
        index: "2",
        tabName: "Конфигурация",
        tabPanel: <MySQLConfigurationTab />
    },
    {
        index: "3",
        tabName: "Бэкапы",
        tabPanel: <MySQLBackupTab />
    }
];

export default withMySQL(SingleDbPage, mySQLTabs);
