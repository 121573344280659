import { paramsToQueryString } from "../../utils";
import Endpoint from "./Endpoint";

class BalanceEndpoint extends Endpoint {
    getUserBalanceDetails(fromDateTime = null, toDateTime = null, page = 0, pageSize = 50) {
        const params = {
            from: fromDateTime?.format(),
            to: toDateTime?.format(),
            page: page,
            size: pageSize
        };
        return this.http.get(`${this.address}/details${paramsToQueryString(params)}`);
    }

    topUp(amount, maxMonthPayments) {
        return this.http.post(`${this.address}/topup`, {
            amount: amount,
            maxMonthPayments: maxMonthPayments
        });
    }
}

export default BalanceEndpoint;
