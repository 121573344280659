import _ from "lodash";
import commonConfig from "../config";
import devConfig from "../config.dev";
import stagingConfig from "../config.staging";
import prodConfig from "../config.prod";
import ConfigContext from "./ConfigContext";

// eslint-disable-next-line consistent-return
const customize = (target, source) => {
    if (_.isArray(target)) {
        return target.concat(source);
    }
};

const ConfigProvider = ({ children }) => {
    const envConfig =
        process.env.REACT_APP_ENV === "prod"
            ? prodConfig
            : process.env.REACT_APP_ENV === "staging"
            ? stagingConfig
            : devConfig;
    const effectiveConfig = _.mergeWith({}, commonConfig, envConfig, customize);
    return <ConfigContext.Provider value={effectiveConfig}>{children}</ConfigContext.Provider>;
};

export default ConfigProvider;
