import { useCallback } from "react";
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormHelperText,
    Stack,
    TextField
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { Controller, useForm } from "react-hook-form";

const dialogVariants = {
    user: {
        title: "имя пользователя"
    },
    db: {
        title: "название базы данных"
    }
};

export default function AddDialog({ open, onClose, type, onClick, loading }) {
    const {
        control,
        reset,
        register,
        handleSubmit,
        formState: { errors }
    } = useForm({ mode: "onBlur" });

    const handleClose = useCallback(() => {
        onClose();
        reset();
    }, [onClose, reset]);

    const handleAdd = useCallback(
        data => {
            onClick(data);
            reset();
        },
        [onClick, reset]
    );

    return (
        <Dialog open={open} onClose={handleClose} maxWidth="xs" fullWidth>
            <form onSubmit={handleSubmit(handleAdd)}>
                <DialogTitle>Введите {dialogVariants[type].title}</DialogTitle>
                <DialogContent>
                    <Box paddingY={1}>
                        {type === "user" ? (
                            <Stack spacing={2}>
                                <Box>
                                    <Controller
                                        control={control}
                                        rules={{
                                            required: "Обязательное поле",
                                            pattern: {
                                                value: /^[a-zA-Z0-9_-]+$/,
                                                message: `Можно использовать только строчные, заглавные латинские буквы, цифры и спец символы: '_' и '-'.`
                                            }
                                        }}
                                        name="username"
                                        render={({ field }) => (
                                            <TextField fullWidth label="Имя пользователя" {...field} />
                                        )}
                                    />
                                    {errors.username && (
                                        <FormHelperText error>{errors.username.message}</FormHelperText>
                                    )}
                                </Box>

                                <TextField
                                    label="Пароль"
                                    placeholder="******"
                                    {...register("password", { required: true })}
                                />
                            </Stack>
                        ) : (
                            <div>
                                <TextField
                                    label="Имя базы данных"
                                    fullWidth
                                    {...register("database", {
                                        required: "Обязательное поле",
                                        pattern: {
                                            value: /^[a-zA-Z0-9_]+$/,
                                            message: `Можно использовать только строчные, заглавные латинские буквы, цифры и спец символ '_'.`
                                        }
                                    })}
                                />
                                {errors.database && <FormHelperText error>{errors.database.message}</FormHelperText>}
                            </div>
                        )}
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Отмена</Button>
                    <LoadingButton type="submit" loading={loading}>
                        Добавить
                    </LoadingButton>
                </DialogActions>
            </form>
        </Dialog>
    );
}
