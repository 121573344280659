import { Box, Checkbox, Collapse, FormControlLabel, Stack, TextField, Typography, useTheme } from "@mui/material";
import { useCallback, useState } from "react";
import { styled } from "@mui/material/styles";

const AdvancedContainer = styled(Stack)(({ theme }) => ({
    justifyContent: "space-between",
    borderRadius: "1rem",
    transition: "all 300ms ease-in-out"
}));

const TooltipTextField = ({ advanced = false, children, ...textFieldProps }) => {
    const [show, setShow] = useState(!advanced);
    const theme = useTheme();

    const handleChange = useCallback(() => {
        setShow(!show);
    }, [show]);

    if (advanced) {
        return (
            <AdvancedContainer
                sx={{
                    background: advanced && show ? theme.palette.background.dark : null,
                    padding: !advanced ? 0 : show ? "0.5rem 1rem 1rem" : "0 1rem"
                }}
            >
                <FormControlLabel
                    label={<Typography variant="body1">Advanced</Typography>}
                    control={<Checkbox onChange={handleChange} />}
                />
                <Collapse in={show} timeout={300} unmountOnExit>
                    <Box mt={1}>
                        <TextField
                            {...textFieldProps}
                            fullWidth
                            InputProps={{
                                endAdornment: children
                            }}
                        />
                    </Box>
                </Collapse>
            </AdvancedContainer>
        );
    }
    return (
        <TextField
            {...textFieldProps}
            InputProps={{
                endAdornment: children
            }}
        />
    );
};

export default TooltipTextField;
