import { Box, Button, IconButton, Skeleton, Stack, Typography } from "@mui/material";
import { useParams } from "react-router-dom";
import { useCallback, useEffect, useMemo } from "react";
import LoadingSwitch from "../../../Elements/LoadingSwitch";
import { BackupsContainer } from "./BackupsContainer";
import { AddBackupButton } from "../../SingleMySQLPage/components/BackupController";
import usePutBackup from "../../SingleMySQLPage/api/usePutBackup";
import useGetBackups from "../../SingleMySQLPage/api/useGetBackups";

export function BackupTab({
    backupStatus,
    isDisabled,
    isScheduledBackupEnabled,
    getBackups,
    changeScheduledBackup,
    addBackup,
    deleteBackup,
    restoreBackup,
    setDetails,
    backupDoc,
    restoreBackupDoc,
    scheduledBackupsDoc,
    onDemandBackupsDoc
}) {
    const { slug } = useParams();
    const { handlePutBackup, backupLoading } = usePutBackup(changeScheduledBackup);
    const { backups, setBackups, getBackupsLoading } = useGetBackups(getBackups);

    const handleSwitchBackup = useCallback(
        enabled => {
            handlePutBackup(enabled);
            setDetails(prev => ({ ...prev, isScheduledBackupEnabled: enabled }));
        },
        [handlePutBackup]
    );

    useEffect(() => {
        if (backupStatus) {
            const { backupName, status } = backupStatus;
            setBackups(prev => prev.map(backup => (backup.name === backupName ? { ...backup, status } : backup)));
        }
    }, [setBackups, backupStatus]);

    const scheduledBackups = useMemo(() => backups.filter(({ isScheduled }) => isScheduled === true), [backups]);
    const userBackups = useMemo(() => backups.filter(({ isScheduled }) => isScheduled === false), [backups]);

    const isAddDisabled = useMemo(() => !(userBackups.length < 3) || isDisabled, [userBackups, isDisabled]);

    return (
        <Stack spacing={2}>
            <Stack spacing={2} direction="row">
                <Button
                    variant="outlined"
                    color="info"
                    startIcon={<i className="fa-solid fa-circle-info" />}
                    href={backupDoc}
                    target="_blank"
                    rel="noreferrer"
                >
                    Бэкапы
                </Button>
                <Button
                    variant="outlined"
                    color="info"
                    startIcon={<i className="fa-solid fa-circle-info" />}
                    href={restoreBackupDoc}
                    target="_blank"
                    rel="noreferrer"
                >
                    Восстановление бэкапов
                </Button>
            </Stack>

            <Stack>
                <Stack flexWrap direction="row">
                    <Typography variant="h6">
                        Активировать ежедневные бэкапы{" "}
                        <IconButton
                            size="small"
                            color="inherit"
                            href={scheduledBackupsDoc}
                            target="_blank"
                            rel="noreferrer"
                        >
                            <i className="fa-regular fa-circle-question" />
                        </IconButton>
                    </Typography>
                    <LoadingSwitch
                        loading={backupLoading}
                        isDisabled={isDisabled}
                        onChange={handleSwitchBackup}
                        defaultChecked={isScheduledBackupEnabled}
                    />
                </Stack>
                <BackupsContainer
                    backups={scheduledBackups}
                    setBackups={setBackups}
                    deleteBackup={deleteBackup}
                    restoreBackup={restoreBackup}
                    isDisabled={isDisabled}
                />
            </Stack>

            <Box>
                <Typography gutterBottom variant="h6">
                    Моментальные бэкапы{" "}
                    <IconButton size="small" color="inherit" href={onDemandBackupsDoc} target="_blank" rel="noreferrer">
                        <i className="fa-regular fa-circle-question" />
                    </IconButton>
                </Typography>
                <Stack spacing={1}>
                    {!getBackupsLoading ? (
                        <AddBackupButton
                            setBackups={setBackups}
                            slug={slug}
                            disabled={isAddDisabled}
                            addBackup={addBackup}
                        />
                    ) : (
                        <Skeleton width="122px" height="30px" />
                    )}

                    <BackupsContainer
                        backups={userBackups}
                        setBackups={setBackups}
                        deleteBackup={deleteBackup}
                        restoreBackup={restoreBackup}
                        isDisabled={isDisabled}
                    />
                </Stack>
            </Box>
        </Stack>
    );
}
