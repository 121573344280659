export const SNACKBAR_TYPE = Object.freeze({
    INFO: { variant: "info" },
    SUCCESS: { variant: "success" },
    WARNING: { variant: "warning" },
    ERROR: { variant: "error" }
});

export const DOCUMENT_TYPE = Object.freeze({
    BILL: "Счёт",
    INVOICE: "Инвойс",
    RECONCILIATION_REPORT: "Акт сверки"
});

export const DOCUMENT_STATUS = Object.freeze({
    PAID: "Оплачено",
    UNPAID: "Не оплачено",
    COMPLETE: "Завершено"
});
