import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    balance: 0,
    error: null,
    loading: true
};

export const balanceSlice = createSlice({
    name: "balance",
    initialState,
    reducers: {
        setBalance: (state, action) => {
            state.balance = action.payload;
            state.error = null;
            state.loading = false;
        },
        setError: (state, action) => {
            state.error = action.payload;
            state.loading = false;
        },
        setLoading: state => {
            state.loading = true;
        }
    }
});

export const { setBalance, setError, setLoading } = balanceSlice.actions;

export default balanceSlice.reducer;
