import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { hasText } from "../../../utils";

const ConfirmationDialog = ({ title, description, open, onClickConfirm, onClose, loading = false }) => (
    <Dialog maxWidth="xs" open={open} onClose={onClose}>
        <DialogTitle>{title ?? "Подтверждение"}</DialogTitle>
        {hasText(description) && (
            <DialogContent>
                <DialogContentText>{description}</DialogContentText>
            </DialogContent>
        )}
        <DialogActions>
            <Button variant="outlined" onClick={onClose}>
                Нет
            </Button>
            <LoadingButton loading={loading} variant="contained" onClick={onClickConfirm}>
                Да
            </LoadingButton>
        </DialogActions>
    </Dialog>
);

export default ConfirmationDialog;
