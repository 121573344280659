import { omitBy } from "lodash";
import { escape } from "he";
import moment from "moment";
import Convert from "ansi-to-html";
import dayjs from "dayjs";

export const isExists = (value = undefined) => value !== undefined && value !== null;

export const isNotExists = (value = undefined) => !isExists(value);

export const getOrNull = (value = undefined) => (isExists(value) ? value : null);

export const isNumber = value => !Number.isNaN(value);

export const isArray = value => isExists(value) && Array.isArray(value);

export const isNotArray = value => !isArray(value);

export const isString = value => isExists(value) && typeof value === "string";

export const hasText = value => isString(value) && value.trim().length > 0;

export const isEmpty = value => {
    if (!isExists(value)) return false;
    if (isArray(value)) return value.length === 0;
    if (hasText(value)) return false;
    return false;
};

export const isNotEmpty = value => !isEmpty(value);

export const formatDate = (value, locale = "ru-RU") => {
    if (!isExists(value) || (!isNumber(value) && !isString(value))) return undefined;
    return new Date(value).toLocaleString(locale);
};

export const paramsToQueryString = (params = {}) => {
    if (!isExists(params)) return "";
    return `?${new URLSearchParams({ ...omitBy(params, isNotExists) }).toString()}`;
};

export const getFromSessionStorage = (key = undefined) => {
    const value = sessionStorage.getItem(key) ?? "";
    if (value === "null") return "";
    return value;
};

export const formatPaymentType = paymentType => {
    if (!isExists(paymentType) || !isString(paymentType)) return undefined;
    if (paymentType === "recurring") return "Автоплатеж";
    return "Платеж";
};

const timeZoneTimestamp = new Date().getTimezoneOffset() * 60000;
// eslint-disable-next-line prettier/prettier

const ansiToHtml = input => {
    const convert = new Convert();
    return convert.toHtml(input);
};

export const convertLogs = data =>
    `<p class="log-time">${moment(Date.parse(data.timestamp) - timeZoneTimestamp).format(
        "DD/MM/YY HH:mm:ss"
    )}</p><p class="log-msg">${ansiToHtml(escape(data.content))}</p>`;

export const getCurrentPathWithoutSlug = () =>
    window.location.pathname.slice(0, window.location.pathname.lastIndexOf("/"));

export const getQuarterFromDate = date => {
    const month = dayjs(date, "DD.MM.YYYY").month();
    return `Q${Math.floor(month / 3) + 1}`;
};
